import React from 'react';
import { Link } from '@backstage/core-components';
import { getTechDocsLink } from 'sg-utils-frontend';

export type Props = {
  text: string;
  language: string | null;
  target?: '_blank' | '_self';
};

export const ContactUsLink = ({ text, target, language }: Props) => {
  const href = getTechDocsLink('contact-us', language || 'en');

  return (
    <Link to={href} target={target}>
      {text}
    </Link>
  );
};
