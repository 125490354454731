import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(2, 5),
    minHeight: 'calc(100vh - 310px)',
  },
  iconColor: {
    color: theme.palette.usgColors.primary.blue,
  },
  color: {
    color: theme.palette.usgColors?.primary.blue,
    height: '23px',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  dialog: {
    position: 'absolute',
    left: 10,
    top: 50,
  },
  resouceText: {
    textTransform: 'uppercase',
  },
  lowercaseChip: {
    '& div > div > div > span': {
      textTransform: 'lowercase',
    },
  },
  resourceBox: {
    paddingBottom: '20px',
    width: '185px',
  },
  resourceGrid: {
    display: 'contents',
  },
  resourceName: {
    padding: '25px 9px 25px 0px',
    fontSize: 15,
    fontWeight: 800,
  },
  resourceLink: {
    '&:hover': {
      color: theme.palette.usgColors?.primary.blue,
      textDecoration: 'underline',
    },
  },
  ownerLable: {
    margin: '13px 0px 0px',
  },
  selfServiceDiv: {
    width: '50%',
    marginTop: '20px',
  },
  inputLabel: {
    marginTop: '20px',
  },
  editBtn: {
    paddingTop: '2%',
    '& button': {
      marginLeft: '100px',
    },
  },
  link: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    color: theme.palette.usgColors.others.mix.blue[96],
    marginBottom: '28px',
  },
  buttons: {
    marginTop: '30px',
    '& button': {
      marginRight: '10px',
    },
  },
  actionButton: {
    padding: '0',
    color: theme.palette.usgColors.primary.blue60,
    fontSize: '12px',
    fontWeight: 600,
    '&:hover, &:focus': {
      backgroundColor: 'rgba(230, 230, 230, 0)',
    },
    textTransform: 'none',
  },
  header: {
    margin: '1.5em 0',
  },
  search: {
    margin: '0 0 2em 0',
    width: '100%',
    '& .MuiFormHelperText-contained ': {
      paddingBottom: '0 !important',
    },
  },
  loadingButton: {
    '& .MuiCircularProgress-indeterminate': {
      height: '30px !important',
      width: '30px !important',
    },
  },
  hyperlink: {
    color: theme.palette.usgColors.others.mix.blue[200],
    textDecoration: 'underline',
  },
  tooltip: {
    color: theme.palette.textContrast,
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
  },
  tooltipArrow: {
    color: theme.palette.usgColors.secondary.silverGray,
  },
  disableManagementType: {
    opacity: 0.5,
    cursor: 'not-allowed',
    marginTop: '0.5px',
    '& div': {
      pointerEvents: 'none',
    },
  },
}));
