import React, { ReactElement } from 'react';
import { useLocation } from 'react-router';

import { ContentHeader, Progress } from '@backstage/core-components';
import {
  PageLayout,
  Content,
  BinaryFeatureFlagged,
  PageTitle,
} from '@internal/sg-ui-kit';
import { PORTAL_PAGES_TITLES } from 'usg-types';
import useBreadcrumbsFlag from '../Wizard/useBreadcrumbsFlag';

type LayoutProps = {
  title: string;
  children: ReactElement;
  className?: string;
};

export const Layout = (props: LayoutProps) => {
  return (
    <PageLayout
      type="entity"
      title="Stargate Multicloud"
      subtitle="Kubernetes Platform"
    >
      <Content>
        <ContentHeader title={props.title} />
        <div className={props.className ?? ''}>{props.children}</div>
      </Content>
    </PageLayout>
  );
};

type FormLayoutProps = LayoutProps & {
  operation: string;
  projectId: string;
  projectName: string;
  applicationName?: string;
};

const CREATE_SMC_OPERATION = 'create';
const ROOT_URL_LABEL_BREADCRUMBS = 'Project Workspace';

const getBreadcrumbs = (
  id: string,
  name: string,
  appName: string,
  url: string,
) => {
  return [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${id}?tab=resources`,
      display: name,
    },
    {
      isAbsolute: true,
      path: url,
      display: appName,
    },
  ];
};

type SMCPageTitleProps = {
  operation: string;
  projectName: string;
  applicationName?: string;
};

const SMCPageTitle = ({
  operation,
  projectName,
  applicationName,
}: SMCPageTitleProps) => {
  if (operation === CREATE_SMC_OPERATION) {
    return (
      <PageTitle
        customPageTitle={`Create Resource in ${projectName} | Stargate`}
      />
    );
  }
  return (
    <PageTitle
      customPageTitle={`${applicationName} | Resources | ${projectName} | Stargate`}
    />
  );
};

export const FormLayout = (props: FormLayoutProps) => {
  const { operation, projectId, projectName, applicationName } = props;
  const { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag } =
    useBreadcrumbsFlag();

  const location = useLocation();

  if (loadingBreadcrumbsFlag) {
    return <Progress />;
  }

  return (
    <PageLayout
      type="entity"
      title="Stargate Multicloud"
      subtitle="Kubernetes Platform"
      backToLink={
        isBreadCrumbsFlagEnabled && applicationName !== undefined
          ? getBreadcrumbs(
              projectId,
              projectName,
              applicationName,
              location.pathname,
            )
          : undefined
      }
    >
      <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
        <SMCPageTitle
          operation={operation}
          projectName={projectName}
          applicationName={applicationName}
        />
      </BinaryFeatureFlagged>
      <Content>
        <ContentHeader title={props.title} />
        <div className={props.className}>{props.children}</div>
      </Content>
    </PageLayout>
  );
};
