import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(1),
    minHeight: 'calc(100vh - 305px)',
  },
  heading: {
    marginTop: '30px',
  },
  avatar: {
    width: 70,
    height: 70,
    fontSize: 70 * 0.7,
    border: `1px solid ${theme.palette.textSubtle}`,
  },
  avatarText: {
    color: theme.palette.usgColors.primary.white,
    fontSize: '20px',
  },
  profileCard: {
    '& >div': {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
  },
  warningMsg: {
    padding: '7px',
  },
  warningSubHeading: {
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '-7px',
  },
  tokenText: {
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '5px',
  },
  tokenDiv: {
    width: '615px',
    overflowY: 'scroll',
    height: '75px',
    wordBreak: 'break-all',
  },
  token: {
    // height: '56px',
    // width: '85%',
    // display: '-webkit-box',
    // overflow: 'hidden',
    marginBottom: '8px',
    // WebkitBoxOrient: 'vertical',
    // WebkitLineClamp: 3,
    fontSize: 12,
    fontWeight: 400,
  },
  chip: {
    marginTop: 10,
    background: `linear-gradient(0deg, rgba(16, 89, 205, 0.2), rgba(16, 89, 205, 0.2)), ${theme.palette.usgColors.primary.white}`,
    borderRadius: '12px',
  },
  copyToken: {
    display: 'flex',
    marginBottom: '6px',
    alignItems: 'center',
  },
  groupTable: {
    // padding: '20px',
    backgroundColor: theme.palette.background.default,
  },
  groupBox: {
    display: 'table',
    width: '100%',
    '& thead tr': {
      backgroundColor: `${theme.palette.background.default} !important`,
      '& th': {
        color: theme.palette.textContrast,
      },
    },
    '& tbody tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  groupTitle: {
    fontSize: 15,
    fontWeight: 700,
    padding: '12px',
    marginLeft: '12px',
  },
  displayName: {
    fontWeight: 700,
  },
  email: {
    color: theme.palette.textContrast,
  },
  link: {
    color: theme.palette.usgColors.primary.blue,
  },
  settingsCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
