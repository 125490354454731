import { useCallback, useEffect, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import { sortBy } from 'lodash';
import { projectApiRef } from '../../../../api';

type Manager = {
  user_email: string;
};

const extract = (manager: Manager) => {
  return {
    value: manager.user_email,
    isValid: true,
  };
};

const convertToManagerList = (managers: Manager[]) => {
  return managers?.map((m: Manager) => extract(m));
};

export const useResourceManagersHook = (
  projectData: any,
  email: string,
  resourceId: string,
) => {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [managers, setManagers] = useState<Manager[] | undefined>(undefined);
  const [isResourceManager, setIsResourceManager] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAddManagerEnabled, setIsAddManagerEnabled] = useState<boolean>(true);

  const refresh = useCallback(async () => {
    if (email === '') {
      return;
    }
    setLoading(true);
    const idToken = await authref.getIdToken();
    try {
      const res: any = await projectApi.getGroupManagersOfUserGroup(
        projectData.id,
        resourceId,
        idToken,
      );
      if (res?.status === 200) {
        const project_resource_managers = res?.data?.project_resource_managers;
        const sortedEmails: any = sortBy(project_resource_managers, [
          'user_email',
        ]);

        setManagers(sortedEmails);

        const resourceManagerRole = res?.data?.project_resource_managers?.some(
          (obj: any) => obj.user_email === email,
        );
        setIsResourceManager(resourceManagerRole);
      } else {
        const errorMsg = res?.data?.error?.message;
        errorApi.post(new Error(`${errorMsg}`));
        // navigate(`/projects/${projectData.id}`);
      }
    } catch (error) {
      errorApi.post(new Error(`${error?.message}`));
    } finally {
      setLoading(false);
    }
  }, [projectData, resourceId, authref, errorApi, projectApi, email]);

  useEffect(() => {
    refresh();
  }, [projectData, resourceId, refresh]);

  const checkUserValidity = async (emailInput: string) => {
    try {
      const token = await authref.getIdToken();
      const isValidRes = await projectApi.checkIfUserIsValid(
        projectData.id,
        emailInput,
        token,
      );
      return isResponseStatus2XX(isValidRes);
    } catch (e) {
      return false;
    }
  };

  return {
    managers,
    isResourceManager,
    loading,
    isAddManagerEnabled,
    setIsAddManagerEnabled,
    setManagers,
    checkUserValidity,
    convertToManagerList,
  };
};
