import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useTranslation } from '../../../../../hooks/useTranslation';

import { useStyles } from '../../../styles';

type resData = {
  key: string;
  name: string;
};

type NamespaceVaultProps = {
  resourceData: resData;
};

const NamespaceVaultComponent = ({ resourceData }: NamespaceVaultProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper>
      <Grid container sx={{ pb: 4 }}>
        <Grid item>
          <Container>
            <h4 className={classes.labelHeader}>
              {t('resource.view.vault.namespaceKeyLabel')}
            </h4>
            <p id="resource-key-value" className={classes.viewTag}>
              {resourceData?.key}
            </p>
            <h4 className={classes.labelHeader}>
              {t('resource.view.vault.namespaceNameLabel')}
            </h4>
            <p id="resource-name-value" className={classes.viewTag}>
              {resourceData?.name}
            </p>
          </Container>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NamespaceVaultComponent;
