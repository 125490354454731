import { USGColorScheme } from './types';

export const USG_COLOR_SCHEME: USGColorScheme = {
  primary: {
    red: '#EB0A1E',
    blue: '#0E4295',
    white: '#FFFFFF',
    black: '#000000',
    gray: '#58595B',
    blue50: '#1976d2',
    blue60: '#1059CD',
  },
  secondary: {
    darkGray: '#1A2122',
    slateGray: '#8c8d8f',
    mediumGray: '#C4C4C4',
    silverGray: '#E6E6E6',
    lightGray: '#F7F7F7',
    blue: '#1059CD',
    lightBlue: '#1059CD',
    darkRed: '#CC0000',
    redberry: '#8C0000',
    green: '#00a40e',
    orange: '#f48b00',
    grey50: '#A2A9B0',
  },
  others: {
    blue: {
      50: '#EBF7FF',
      100: '#ECF3FE',
      200: '#C6DAFB',
      300: '#679DF4',
      400: '#2E78EF',
      500: '#1059CD',
      600: '#0B4198',
      700: '#0D3A80',
      800: '#0D3069',
      900: '#0C2957',
      1000: '#081D3F',
    },
    grey: {
      100: '#F8F9FB',
      200: '#F4F5F7',
      300: '#E7EAEE',
      400: '#C1C7CD',
      500: '#A2A9B0',
      600: '#8B919A',
      700: '#697077',
      800: '#4D5358',
      900: '#343A3F',
      1000: '#121619',
    },
    generic: {
      orange: '#F87720',
      yellow: '#E2D91B',
      green: '#11CE20',
      darkGreen: '#187656',
      teal: '#4BB8A5',
      cyan: '#61DBEA',
      blue: '#27A9F3',
      purple: '#AB82DF',
      darkPurple: '#7C3699',
      magenta: '#FB6DD7',
      grey: '#737373',
    },
    green: {
      100: '#E0FAEA',
      200: '#009C0D',
    },
    red: {
      100: '#FEECED',
      200: '#F7CACA',
      300: '#DF2F2F',
      400: '#921616',
    },
    orange: {
      100: '#FFF6EB',
      200: '#F48B00',
    },
    mix: {
      grey: {
        100: '#585858',
        0: '#F1F3F6',
        1: '#E0E0E0',
        2: '#d5d5d5',
        3: '#B0B0B0',
        4: '#E8E8E8',
        200: '#A9A9A9',
      },
      blue: {
        90: '#9FC3FF',
        95: '#9FC3FF',
        96: '#307FFD',
        100: '#1890ff',
        200: '#0D6AFF',
        300: '#0000FF',
        400: '#0F59CD',
        500: 'rgba(14, 66, 149, 1)',
        600: 'rgba(16, 89, 205, 0.2)',
      },
      red: {
        0: '#fdebed',
        1: '#ffcccc',
        100: '#D22020',
        200: '#FF0000',
        300: '#CC0000',
      },
      orange: {
        100: '#ee832a',
      },
      green: {
        100: '#00FF00',
      },
    },
  },
};
