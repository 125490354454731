import React from 'react';
import { Typography } from '@material-ui/core';

interface CloudUsageSummaryProps {
  selectedResourceType: string;
  cloudUsageData: any;
  classes: any;
}

const CloudUsageSummary: React.FC<CloudUsageSummaryProps> = ({
  selectedResourceType,
  cloudUsageData,
  classes,
}) => {
  return (
    <div className={classes.totalDiv}>
      <Typography className={classes.totalText}>
        {(selectedResourceType === 'GHE' || selectedResourceType === 'EMU') && (
          <>
            <span className={classes.totalTitle}>Total Hours: </span>
            <span id="total-hr">
              {cloudUsageData?.total_usage_hours_rounded || '-'}
            </span>
          </>
        )}
        {selectedResourceType === 'OBSLOGS' && (
          <>
            <span className={classes.totalTitle}>
              Total Ingested Gigabytes:
            </span>
            <span id="total-gb">
              {cloudUsageData?.total_ingested_gigabytes_rounded || '-'}
            </span>
          </>
        )}
        {selectedResourceType === 'OBSMETRICS' && (
          <>
            <span className={classes.totalTitle}>Total Points Per Second:</span>
            <span id="total-gb">
              {cloudUsageData?.total_points_per_second_rounded || '-'}
            </span>
          </>
        )}
        {selectedResourceType === 'SMC' && (
          <>
            <span className={classes.totalTitle}>Total Items: </span>
            <span id="total-gb">{cloudUsageData?.total_item_count || '-'}</span>
          </>
        )}
        {(selectedResourceType === 'AS' ||
          selectedResourceType === 'ARTIFACTORY_SAAS') && (
          <>
            <span className={classes.totalTitle}>
              Total Network Storage (GB):{' '}
            </span>
            <span id="total-gb">
              {cloudUsageData?.total_usage_gigabytes_rounded || '-'}
            </span>
          </>
        )}
      </Typography>
      {selectedResourceType === 'ARTIFACTORY_SAAS' && (
        <Typography className={classes.totalText}>
          <span className={classes.totalTitle}>Total Network Usage (GB): </span>
          <span id="total-usage-gb">
            {cloudUsageData?.total_network_usage_gigabytes_rounded || '-'}
          </span>
        </Typography>
      )}
      <Typography className={classes.totalText}>
        {selectedResourceType === 'OBSLOGS' && (
          <>
            <span className={classes.totalTitle}>
              Approx. Total Ingestion Cost:
            </span>
            <span id="total-cost">
              ¥{' '}
              {cloudUsageData?.total_ingestion_cost_rounded?.toLocaleString() ||
                '-'}
            </span>
          </>
        )}
        {(selectedResourceType === 'GHE' ||
          selectedResourceType === 'EMU' ||
          selectedResourceType === 'OBSMETRICS' ||
          selectedResourceType === 'SMC' ||
          selectedResourceType === 'ARTIFACTORY_SAAS') && (
          <>
            <span className={classes.totalTitle}>Approx. Total Cost: </span>
            <span id="total-cost">
              ¥ {cloudUsageData?.total_cost_rounded?.toLocaleString() || '-'}
            </span>
          </>
        )}
      </Typography>
      {selectedResourceType === 'OBSLOGS' && (
        <>
          <Typography className={classes.totalText}>
            <span className={classes.totalTitle}>
              Total Storage in Gigabytes:{' '}
            </span>{' '}
            <span id="total-gb">
              {cloudUsageData?.total_storage_gigabytes_rounded || '-'}
            </span>
          </Typography>
          <Typography className={classes.totalText}>
            <span className={classes.totalTitle}>
              Approx. Total Storage Costs:{' '}
            </span>
            <span id="total-cost">
              ¥{' '}
              {cloudUsageData?.total_storage_cost_rounded?.toLocaleString() ||
                '-'}
            </span>
          </Typography>
        </>
      )}
    </div>
  );
};

export default CloudUsageSummary;
