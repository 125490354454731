import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import WorkIcon from '@material-ui/icons/Work';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import FeedbackIcon from '@material-ui/icons/Feedback';
import GitHub from '@material-ui/icons/GitHub';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import { SidebarSettings } from './../sidebar-settings';
import { UserSettingsPinToggle } from './UserSettingsPinToggle';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { USGTheme } from 'usg-theme';
import {
  CAN_ACCESS_ROVER_COMPANY_ID,
  MARKETPLACE_V2,
  NOTIFICATIONS,
  TEST_ACCOUNT_DOMAIN,
} from 'usg-types';
import {
  BinaryFeatureFlagged,
  NetPromoterScore,
  SidebarItemTag,
} from '@internal/sg-ui-kit';
import { RoverIcon } from '../rover/RoverIcon';
import { useCompaniesApi, usePermissions } from '@internal/plugin-projects';
import {
  identityApiRef,
  useApi,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { useObservable } from 'react-use';
import { appLanguageApiRef } from '@backstage/core-plugin-api/alpha';
import { NotificationsSidebarItem } from '@internal/backstage-plugin-notifications';
import { useTranslation } from '../../useTranslation';

const useSidebarLogoStyles = makeStyles((theme: USGTheme) => ({
  sidebar: {
    '& > nav > div > div': {
      background: `linear-gradient(to bottom, ${theme.palette.usgColors.primary.black}, ${theme.palette.usgColors.primary.black}, ${theme.palette.usgColors.primary.black},${theme.palette.usgColors.primary.black}, ${theme.palette.usgColors.primary.blue})`,
    },
  },
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  bodyContent: {
    minHeight: 'calc(100vh - 160px)',
    overflowX: 'hidden',
  },
  pinRoot: {
    width: sidebarConfig.drawerWidthClosed,
    height: 2 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
    '&:hover': {
      '& .MuiTypography-h4': {
        color: theme.palette.usgColors.primary.white,
      },
    },
  },
  navBar: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
    '& a': {
      '& .MuiTypography-subtitle2': {
        color: theme.palette.usgColors.secondary.slateGray,
        fontSize: '12px',
      },

      '&:hover': {
        color: theme.palette.usgColors.primary.white,
        '& .MuiTypography-subtitle2': {
          color: theme.palette.usgColors.primary.white,
        },
      },
      '&[aria-current]': {
        borderImage: 'linear-gradient( to bottom, #0D6AFF, #91B9FA) 0 0 0 100%',
        '& .MuiTypography-subtitle2': {
          color: theme.palette.usgColors.primary.white,
        },
      },
    },
    '& h6': {
      fontSize: '14px',
      width: '144px',
    },
    '& button': {
      '& .MuiTypography-subtitle2': {
        color: theme.palette.usgColors.secondary.slateGray,
        fontSize: '12px',
      },
      '&:hover': {
        color: theme.palette.usgColors.primary.white,
        '& .MuiTypography-subtitle2': {
          color: theme.palette.usgColors.primary.white,
        },
      },
    },
  },
  link: {
    marginLeft: 16,
  },
  searchContainer: {
    '& span': {
      padding: '7px',
      background: theme.palette.usgColors.primary.blue60,
      color: theme.palette.usgColors.primary.white,
      marginLeft: '7px',
      zIndex: 1,
    },
    '& input': {
      background: theme.palette.usgColors.others.mix.grey[100],
      color: theme.palette.usgColors.secondary.silverGray,
      borderRadius: '0px 5px 5px 0px',
      textIndent: '10px',
      height: '6px',
    },
  },
  lang: {
    display: 'none',
  },
  sideBarItemStyle: {
    borderRadius: '0px !important',
    letterSpacing: '0.2px',
    '&:hover': {
      color: theme.palette.usgColors.primary.white,
    },
  },
}));

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        <LogoIcon />
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const classes = useSidebarLogoStyles();
  const [dialogOpenNPS, setDialogOpenNPS] = React.useState(false);
  const { isGuest } = usePermissions();
  const identityApi = useApi(identityApiRef);
  const { allCompanies } = useCompaniesApi();
  const [accessRover, setAccessRover] = React.useState(false);
  const dialogOnCloseHelper: () => void = () => {
    setDialogOpenNPS(false);
  };

  const languageApi = useApi(appLanguageApiRef);
  const storageApi = useApi(storageApiRef);
  const [languageObservable] = useState(() =>
    storageApi.observe$<string>('language'),
  );
  const { t } = useTranslation();

  const { value: currentLanguage } = useObservable(
    languageObservable,
    storageApi.snapshot('language'),
  );

  useEffect(() => {
    languageApi.setLanguage(currentLanguage || 'en');
  }, [languageApi, currentLanguage]);

  React.useEffect(() => {
    (async () => {
      const getUser = await identityApi.getProfileInfo();
      const userDomain = getUser.email?.split('@')[1] || '';
      const companyCanAccess: any = allCompanies.find(
        (company: any) => company?.id === CAN_ACCESS_ROVER_COMPANY_ID,
      );
      const canAccessRover =
        companyCanAccess &&
        [
          ...(companyCanAccess?.email_domains || []),
          TEST_ACCOUNT_DOMAIN,
        ].includes(userDomain) &&
        !isGuest;
      setAccessRover(canAccessRover);
    })();
  }, [allCompanies, identityApi, isGuest]);

  return (
    <>
      <SidebarPage>
        <div className={classes.sidebar}>
          <Sidebar>
            <SidebarLogo />
            <div className={classes.navBar}>
              <SidebarGroup label="Search" to="/search">
                <SidebarItem
                  icon={SearchIcon}
                  to="/search"
                  text={t('sidebar.menu.search')}
                  id="search-document-button"
                />
              </SidebarGroup>
              <SidebarDivider />
              <SidebarGroup label="Menu" icon={<MenuIcon />}>
                <SidebarItem
                  icon={HomeIcon}
                  to="welcome-page"
                  text={t('sidebar.menu.home')}
                />
                <SidebarItem
                  icon={WorkIcon}
                  to="projects"
                  text={t('sidebar.menu.projectWorkspace')}
                  id="project-workspace-button"
                />
                <SidebarItem
                  icon={CategoryIcon}
                  to="catalog"
                  text={t('sidebar.menu.catalog')}
                  id="catalog-button"
                />
                <BinaryFeatureFlagged withFlag={MARKETPLACE_V2}>
                  <SidebarItem
                    icon={GitHub}
                    to="/marketplace"
                    text={t('sidebar.menu.marketplace')}
                    id="actions-catalog-button"
                  />
                </BinaryFeatureFlagged>
                <BinaryFeatureFlagged withoutFlag={MARKETPLACE_V2}>
                  <SidebarItem
                    icon={GitHub}
                    to="/actions-catalog"
                    text={t('sidebar.menu.ciCdMarketplace')}
                    id="actions-catalog-button"
                  />
                </BinaryFeatureFlagged>
                <SidebarItem
                  icon={QuestionAnswerIcon}
                  text={t('sidebar.menu.qaForum')}
                  id="forums-button"
                  children={<SidebarItemTag label="New" />}
                  to="/qeta/questions"
                />
                {accessRover && (
                  <SidebarItem
                    icon={RoverIcon}
                    text={t('sidebar.menu.rover')}
                    id="rover-button"
                    children={<SidebarItemTag label="New" />}
                    to="/rover/ui"
                  />
                )}
                <SidebarItem
                  icon={LibraryBooks}
                  to="/docs"
                  text={t('sidebar.menu.documentation')}
                  end
                />
              </SidebarGroup>
              <SidebarSpace />
              <BinaryFeatureFlagged withFlag={NOTIFICATIONS}>
                <NotificationsSidebarItem
                  snackbarEnabled={false}
                  titleCounterEnabled={false}
                  webNotificationsEnabled={false}
                />
              </BinaryFeatureFlagged>
              <SidebarItem
                icon={ContactSupportIcon}
                className={classes.sideBarItemStyle}
                onClick={() =>
                  window.open('https://support.woven.toyota/', '_blank')
                }
                text={t('sidebar.menu.getSupport')}
              />
              <SidebarItem
                icon={FeedbackIcon}
                className={classes.sideBarItemStyle}
                onClick={() => {
                  setDialogOpenNPS(true);
                }}
                text={t('sidebar.menu.provideFeedback')}
              />
              <SidebarDivider />
              <SidebarSettings to="profile" />
            </div>
            <div className={classes.pinRoot}>
              <UserSettingsPinToggle />
            </div>
          </Sidebar>
        </div>
        <div className={classes.bodyContent}>{children}</div>
        {dialogOpenNPS && (
          <NetPromoterScore
            npsLocation="generic"
            userJourney="Generic_v1"
            dialogOpen={dialogOpenNPS}
            dialogOnCloseHelper={dialogOnCloseHelper}
          />
        )}
      </SidebarPage>
    </>
  );
};
