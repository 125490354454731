import React, { ReactElement } from 'react';
import { useLocation } from 'react-router';

import { ContentHeader } from '@backstage/core-components';
import { PageLayout, Content } from '@internal/sg-ui-kit';

type LayoutProps = {
  title: string;
  children: ReactElement;
  className?: string;
  operation?: string;
  projectName?: string;
  projectId?: string;
  applicationName?: string;
};

const CREATE_SMC_OPERATION = 'create';
const ROOT_URL_LABEL_BREADCRUMBS = 'Project Workspace';

const getBreadcrumbs = (
  id: string,
  name: string,
  appName: string,
  url: string,
) => {
  return [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${id}?tab=resources`,
      display: name,
    },
    {
      isAbsolute: true,
      path: url,
      display: appName,
    },
  ];
};

export const Layout = (props: LayoutProps) => {
  const { operation, projectId, projectName, applicationName } = props;
  const location = useLocation();

  const isBreadcrumbsAvailable =
    operation !== CREATE_SMC_OPERATION &&
    projectId !== undefined &&
    projectName !== undefined &&
    applicationName !== undefined;

  return (
    <PageLayout
      type="entity"
      title="Stargate Multicloud"
      subtitle="Kubernetes Platform"
      backToLink={
        isBreadcrumbsAvailable
          ? getBreadcrumbs(
              projectId,
              projectName,
              applicationName,
              location.pathname,
            )
          : undefined
      }
    >
      <Content>
        <ContentHeader title={props.title} />
        <div className={props.className}>{props.children}</div>
      </Content>
    </PageLayout>
  );
};
