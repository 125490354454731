import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

const FONT_SIZE = 12;
const LETTER_SPACING = 0;
const FONT_WEIGHT = 400;
export const useStyles = makeStyles((theme: USGTheme) => ({
  lightBackground: {
    padding: 0,
    margin: 0,
    color: theme.palette.usgColors.primary.black,
  },
  darkBackground: {
    padding: 0,
    margin: 0,
    color: theme.palette.usgColors.primary.white,
  },
  link: {
    display: 'inline-block',
  },
  text: {
    color: theme.palette.usgColors.primary.black,
    fontWeight: FONT_WEIGHT,
    fontSize: FONT_SIZE,
    letterSpacing: LETTER_SPACING,
  },
}));
