import React from 'react';

import { Button, Grid } from '@material-ui/core';
import { UnarchiveButtonProps } from '../types';

import { useStyles } from '../../styles';

export const UnarchiveButton = ({ setUnArchiveOpen }: UnarchiveButtonProps) => {
  const classes = useStyles();

  const handleUnarchiveUserResource = () => {
    setUnArchiveOpen(true);
  };

  return (
    <Grid item xs={3} className={classes.btnAlign}>
      <Button
        id="project-button-unarchive"
        variant="contained"
        onClick={handleUnarchiveUserResource}
      >
        UnArchive
      </Button>
    </Grid>
  );
};
