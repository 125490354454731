export const TABS_MEMBERS_TRANSLATION_PREFIX = 'usergroups.view.tabs.members';
export const TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX =
  'usergroups.view.tabs.assignedResources';
export const TABS_USERGROUPS_TRANSLATION_PREFIX =
  'resource.view.tabs.usergroups';
export const RESOURCE_GITHUB_TRANSLATION_PREFIX = 'resource.view.github';
export const RESOURCE_ARTIFACTORY_TRANSLATION_PREFIX =
  'resource.view.artifactory';
export const RESOURCE_ARTIFACTORYSAAS_TRANSLATION_PREFIX =
  'resource.view.artifactorysaas';
export const RESOURCE_JIRA_TRANSLATION_PREFIX = 'resource.view.jira';
