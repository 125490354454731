import React, { useState } from 'react';

import { IconButton, Link } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useViewEditResourceContext } from '../../../context';

export const ResourceUrl = (props: any) => {
  const { environment } = props;
  const resourceContext = useViewEditResourceContext();

  const [isUrlCopied, setIsUrlCopied] = useState(false);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-start"
    >
      <Grid item sx={{ mt: 1, mr: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Link:
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 1 }}>
        {environment?.url ? (
          <Link underline="always" href={environment?.url}>
            <Typography variant="body1">{environment?.url}</Typography>
          </Link>
        ) : (
          <Typography
            variant="body1"
            sx={{ marginTop: 0, marginBottom: '12px' }}
          >
            No link for this environment
          </Typography>
        )}
      </Grid>
      {environment?.url && (
        <Grid item sx={{ ml: 1 }}>
          {isUrlCopied ? (
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', mt: 1, mb: 1.5 }}
              color="primary"
            >
              Copied!
            </Typography>
          ) : (
            <IconButton
              id="project-iconbutton-copy"
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.protocol}//${window.location.hostname}/projects/${resourceContext.projectDetails.id}?resource=${environment.id}&path=`,
                );
                setIsUrlCopied(true);
                setTimeout(() => {
                  setIsUrlCopied(false);
                }, 2000);
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};
