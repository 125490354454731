import React from 'react';
import { EmptyDataMessage } from '@internal/sg-ui-kit';
import { Link, Table, TableColumn } from '@backstage/core-components';
import {
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@material-ui/core';
import { ADMIN, DEFAULT_TABLE_PAGE_SIZE_OPTIONS, RO_ADMIN } from 'usg-types';
import { useStyles } from './styles';

export type GroupWithRoles = {
  id: string;
  name: string;
  isGroupManager: boolean;
  isGroupMember: boolean;
  roles: string[];
};

export type ResourceWithRoles = {
  id: string;
  name: string;
  isResourceManager: boolean;
  isResourceMember: boolean;
  roles: string[];
};
const LABEL_OWNER_ROLE = 'Owner';
const LABEL_MEMBER_ROLE = 'Member';
const LABEL_MANAGER_ROLE = 'Group Manager';
const GROUP_ROLE_MEMBER = 'member';
const GROUP_ROLE_MANAGER = 'group_manager';
const RESOURCE_ROLE_MANAGER = 'resource_manager';
const LABEL_RESOURCE_MANAGER_ROLE = 'Resource Manager';
interface Props {
  data?: [];
  roles?: string[];
}

export const ProfileProjectTable: React.FC<Props> = ({ data = [], roles }) => {
  const classes = useStyles();

  const getGroupWithRoles = (groupData: any): GroupWithRoles => {
    const g: GroupWithRoles = {
      id: groupData.id,
      name: groupData.name,
      isGroupManager: false,
      isGroupMember: false,
      roles: [],
    };
    if (groupData.group_roles) {
      if (groupData.group_roles.includes(GROUP_ROLE_MANAGER)) {
        g.isGroupManager = true;
        g.roles.push(LABEL_MANAGER_ROLE);
      }
      if (groupData.group_roles.includes(GROUP_ROLE_MEMBER)) {
        g.isGroupMember = true;
        g.roles.push(LABEL_MEMBER_ROLE);
      }
    } else {
      g.isGroupMember = true;
      g.roles.push(LABEL_MEMBER_ROLE);
    }
    return g;
  };

  const getResourceWithRoles = (resourceData: any): ResourceWithRoles => {
    const g: ResourceWithRoles = {
      id: resourceData.id,
      name: resourceData.name,
      isResourceManager: false,
      isResourceMember: false,
      roles: [],
    };
    if (resourceData.resource_roles) {
      if (resourceData.resource_roles.includes(RESOURCE_ROLE_MANAGER)) {
        g.isResourceManager = true;
        g.roles.push(LABEL_RESOURCE_MANAGER_ROLE);
      }
      if (resourceData.resource_roles.includes(GROUP_ROLE_MEMBER)) {
        g.isResourceMember = true;
        g.roles.push(LABEL_MEMBER_ROLE);
      }
    } else {
      g.isResourceMember = true;
      g.roles.push(LABEL_MEMBER_ROLE);
    }
    return g;
  };

  const getRolesForProject = (projectData: any): string[] => {
    const rolesList: string[] = [];
    let hasMembership = false;
    let hasManagership = false;
    let hasResourceManagership = false;

    // Always show owner role if applicable
    if (projectData.is_owner) {
      rolesList.push(LABEL_OWNER_ROLE);
    } else {
      for (const ug of projectData.user_groups) {
        if (hasManagership && hasMembership) {
          break;
        }
        const gr = getGroupWithRoles(ug);
        hasManagership = hasManagership || gr.isGroupManager;
        hasMembership = hasMembership || gr.isGroupMember;
      }

      for (const ug of projectData.resources) {
        if (hasResourceManagership && hasMembership) {
          break;
        }
        const gr = getResourceWithRoles(ug);
        hasResourceManagership = hasResourceManagership || gr.isResourceManager;
        hasMembership = hasMembership || gr.isResourceMember;
      }

      if (hasResourceManagership) {
        rolesList.push(LABEL_RESOURCE_MANAGER_ROLE);
      }

      if (hasManagership) {
        rolesList.push(LABEL_MANAGER_ROLE);
      }
    }

    // Only show member role if they have no other roles
    if (
      !hasManagership &&
      !projectData.is_owner &&
      hasMembership &&
      !hasResourceManagership
    ) {
      rolesList.push(LABEL_MEMBER_ROLE);
    }

    return rolesList;
  };

  const feedColumns: Array<TableColumn<any>> = [
    {
      field: 'id',
      title: 'Project Id',
      width: '20%',
    },
    {
      field: 'name',
      title: 'Project Name',
      width: '35%',
      render: rowData =>
        roles?.some(role => [ADMIN, RO_ADMIN].includes(role)) ||
        rowData.management_type === 'PROJECT_OWNERS_END_USERS' ||
        (rowData.management_type === 'PROJECT_OWNERS' && rowData.is_owner) ? (
          <Link
            to={`/projects/${rowData.id}`}
            key={rowData.id}
            className={classes.link}
          >
            {rowData.name}
          </Link>
        ) : (
          rowData.name
        ),
    },
    {
      field: 'key',
      title: 'Project Key',
      sorting: false,
      width: '30%',
    },
    {
      field: 'roles',
      title: 'roles',
      render: rowData => getRolesForProject(rowData).join(', '),
      sorting: false,
      width: '20%',
    },
  ];

  const renderUserGroupRow = (groupData: any, projectData: any) => {
    const projectID = projectData.id;
    const gr = getGroupWithRoles(groupData);
    return (
      <TableRow key={gr.id}>
        <TableCell component="th" scope="row">
          {gr.id}
        </TableCell>
        <TableCell>
          {roles?.some(role => [ADMIN, RO_ADMIN].includes(role)) ||
          projectData.management_type === 'PROJECT_OWNERS_END_USERS' ||
          (projectData.management_type === 'PROJECT_OWNERS' &&
            projectData.is_owner) ? (
            <Link
              className={classes.link}
              key={gr.id}
              to={`/projects/${projectID}/usergroup/${gr.id}`}
            >
              {gr.name}
            </Link>
          ) : (
            <p>{gr.name}</p>
          )}
        </TableCell>
        <TableCell>{gr.roles.join(', ')}</TableCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.heading}>
      <Table
        columns={feedColumns}
        options={{
          paging: true,
          search: true,
          sorting: true,
          actionsColumnIndex: -1,
          padding: 'dense',
          pageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
          pageSizeOptions: DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
          emptyRowsWhenPaging: false,
          // showFirstLastPageButtons: false,
          actionsCellStyle: { padding: '5px 10px' },
          detailPanelColumnAlignment: 'right',
        }}
        data={data}
        title="My Projects"
        detailPanel={[
          {
            tooltip: 'View User Groups',
            render: (rowData: any) => {
              return (
                <div className={classes.groupTable}>
                  <Divider />
                  <Typography
                    variant="body2"
                    gutterBottom
                    component="div"
                    className={classes.groupTitle}
                  >
                    My User Groups
                  </Typography>
                  <div className={classes.groupBox}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="19.5%">Group ID</TableCell>
                        <TableCell width="19.5%">Group Name</TableCell>
                        <TableCell>Group Roles</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData?.rowData?.user_groups.length === 0 ? (
                        <>
                          <EmptyDataMessage />
                        </>
                      ) : (
                        ''
                      )}
                      {rowData?.rowData?.user_groups.map((group: any) =>
                        renderUserGroupRow(group, rowData?.rowData),
                      )}
                    </TableBody>
                  </div>
                </div>
              );
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: <EmptyDataMessage />,
          },
          toolbar: {
            searchPlaceholder: 'Search',
          },
        }}
      />
    </div>
  );
};
