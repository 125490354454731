import isArray from 'lodash/isArray';

/**
 * group the values of an array by a field
 *
 * @param items
 * @param fieldName
 * @returns { Record }
 */
export const groupByArrayField = <T extends object>(
  items: T[],
  fieldName: keyof T,
): Record<string, T[]> =>
  items.reduce((result, item) => {
    const fieldValue = item[fieldName];

    if (fieldValue && isArray(fieldValue)) {
      fieldValue.forEach(el => {
        if (result[el]) {
          result[el].push(item);
        } else {
          result[el] = [item];
        }
      });
    }

    return result;
  }, {} as Record<string, T[]>);

/**
 * Check if a group is dynamic
 *
 * @param groupData
 * @returns { Boolean }
 */
export const isDynamicGroup = (groupData: any): boolean => {
  return Boolean(groupData?.rule_based);
};

export const isGithubEMUDynamicGroup = (groupData: any): boolean => {
  return (
    Boolean(groupData?.rule_based) &&
    (groupData.name.includes('githubemu') ||
      (groupData.tags &&
        groupData.tags['platform-limit-tool']?.includes('githubemu')))
  );
};

/**
 * filters dynamic user groups based on a passed regex
 * @param array
 * @param regex
 * @param field
 * @param match
 * @returns { Array<any> }
 */
export const filterDynamicUserGroups = (
  array: Array<any>,
  regex: RegExp,
  field: string,
  match: boolean,
): Array<any> => {
  return array.filter((ug: any) => {
    if (isDynamicGroup(ug)) {
      if (match) {
        return regex.test(ug[field]);
      }
      return !regex.test(ug[field]);
    }
    return ug;
  });
};
