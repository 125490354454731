import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    gridArea: 'pageHeader',
  },
  'container--catalog': {
    backgroundColor: theme.palette.usgColors.others.mix.grey[1],
    backgroundSize: 'cover',
    color: theme.palette.usgColors.primary.white,
    '& h1, & h2, & h6, & a span': {
      color: theme.palette.usgColors.primary.white,
    },
  },
  content: {
    minHeight: '142px',
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  extendedContent: {
    paddingTop: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(1.5),
  },
  searchHeading: {
    color: theme.palette.usgColors.primary.white,
  },
  title: {
    color: theme.palette.usgColors.primary.white,
  },
  additionalControls: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
  },
  searchBar: {
    borderRadius: '50px',
    border: `1px solid ${theme.palette.usgColors.others.mix.grey[1]}`,
    padding: '5px 20px',
    backgroundColor: theme.palette.usgColors.primary.white,
  },
  searchBarClearButton: {
    padding: '0',
    height: '100%',
  },
  searchFormContainer: {
    position: 'relative',
    width: '100%',
  },
  searchResultContainer: {
    backgroundColor: theme.palette.usgColors.primary.white,
    color: theme.palette.textContrast,
    position: 'absolute',
    width: '100%',
    margin: '2px 0',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.usgColors.others.mix.grey[1]}`,
    padding: '10px 10px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  searchResultItem: {
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.usgColors.others.mix.grey[1],
    },
  },
  searchResultItemText: {
    color: theme.palette.textContrast,
  },
  mainSearchSection: {
    marginLeft: '20px',
  },
}));
