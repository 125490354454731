import React from 'react';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
} from 'usg-types';
import VaultViewEdit from '../view-edit-components/Vault';
import ArtifactorySaasViewEdit from '../view-edit-components/ArtifactorySaas';
import ArtifactoryViewEdit from '../view-edit-components/Artifactory';
import ConfluenceViewEdit from '../view-edit-components/Confluence';
import JamaViewEdit from '../view-edit-components/Jama';
import GithubEmuViewEdit from '../view-edit-components/GithubEmu';
import JiraViewEdit from '../view-edit-components/Jira';
import GithubViewEdit from '../view-edit-components/Github';
import { ViewEditResourceFormPropsType } from './Types';

const ViewEditResourceForm = (
  props: React.PropsWithChildren<ViewEditResourceFormPropsType>,
) => {
  const memoizedProps = React.useMemo(() => props, [props]);
  const {
    devToolId,
    isEdit,
    classes,
    formik,
    resourceData,
    resourceSpecificProps,
    roles,
  } = memoizedProps;
  const eventType: 'view' | 'edit' = isEdit ? 'edit' : 'view';

  const commonProps = { eventType, classes, formik, resourceData, roles };
  switch (devToolId) {
    case ARTIFACTORY_DEV_TOOL_ID:
      return (
        <ArtifactoryViewEdit
          {...commonProps}
          specificProps={{
            artifactoryProperties: resourceSpecificProps.artifactoryProperties,
            dataData: resourceSpecificProps.dataData,
            descriptionDirty: resourceSpecificProps.descriptionDirty,
            nameDirty: resourceSpecificProps.nameDirty,
            onDescriptionClick: resourceSpecificProps.onDescriptionClick,
            setArtifactoryProperties:
              resourceSpecificProps.setArtifactoryProperties,
            setNameDirty: resourceSpecificProps.setNameDirty,
            showArtifactoryGithubWorkflowAccess:
              resourceSpecificProps.showArtifactoryGithubWorkflowAccess,
          }}
        />
      );
    case CONFLUENCE_DEV_TOOL_ID:
      return (
        <ConfluenceViewEdit
          {...commonProps}
          specificProps={{
            descriptionDirty: resourceSpecificProps.descriptionDirty,
            nameDirty: resourceSpecificProps.nameDirty,
            onDescriptionClick: resourceSpecificProps.onDescriptionClick,
            setNameDirty: resourceSpecificProps.setNameDirty,
          }}
        />
      );
    case GITHUBEMU_DEV_TOOL_ID:
      return (
        <GithubEmuViewEdit
          {...commonProps}
          specificProps={{
            dataData: resourceSpecificProps.dataData,
            descriptionDirty: resourceSpecificProps.descriptionDirty,
            nameDirty: resourceSpecificProps.nameDirty,
            onDescriptionClick: resourceSpecificProps.onDescriptionClick,
            setNameDirty: resourceSpecificProps.setNameDirty,
            projectId: resourceSpecificProps.projectId,
          }}
        />
      );
    case JAMA_DEV_TOOL_ID:
      return (
        <JamaViewEdit
          {...commonProps}
          specificProps={{
            descriptionDirty: resourceSpecificProps.descriptionDirty,
            nameDirty: resourceSpecificProps.nameDirty,
            onDescriptionClick: resourceSpecificProps.onDescriptionClick,
            setNameDirty: resourceSpecificProps.setNameDirty,
          }}
        />
      );
    case JIRA_DEV_TOOL_ID:
      return (
        <JiraViewEdit
          {...commonProps}
          specificProps={{
            descriptionDirty: resourceSpecificProps.descriptionDirty,
            nameDirty: resourceSpecificProps.nameDirty,
            onDescriptionClick: resourceSpecificProps.onDescriptionClick,
            setNameDirty: resourceSpecificProps.setNameDirty,
          }}
        />
      );
    case VAULT_DEV_TOOL_ID:
      return (
        <VaultViewEdit
          {...commonProps}
          specificProps={{
            copyButtonLabel: resourceSpecificProps.copyButtonLabel,
            environmentsData: resourceSpecificProps.environmentsData,
            projectId: resourceSpecificProps.projectId,
            resourceID: resourceSpecificProps.resourceID,
            setCopyButtonLabel: resourceSpecificProps.setCopyButtonLabel,
            setResourceID: resourceSpecificProps.setResourceID,
          }}
        />
      );
    case ARTIFACTORY_SAAS_DEV_TOOL_ID:
      return (
        <ArtifactorySaasViewEdit
          {...commonProps}
          specificProps={{
            artifactoryProperties: resourceSpecificProps.artifactoryProperties,
            dataData: resourceSpecificProps.dataData,
            descriptionDirty: resourceSpecificProps.descriptionDirty,
            onDescriptionClick: resourceSpecificProps.onDescriptionClick,
            setArtifactoryProperties:
              resourceSpecificProps.setArtifactoryProperties,
            showArtifactoryGithubWorkflowAccess:
              resourceSpecificProps.showArtifactoryGithubWorkflowAccess,
          }}
        />
      );
    case GITHUB_DEV_TOOL_ID:
      return (
        <GithubViewEdit
          {...commonProps}
          specificProps={{
            dataData: resourceSpecificProps.dataData,
            descriptionDirty: resourceSpecificProps.descriptionDirty,
            nameDirty: resourceSpecificProps.nameDirty,
            onDescriptionClick: resourceSpecificProps.onDescriptionClick,
            setNameDirty: resourceSpecificProps.setNameDirty,
            projectId: resourceSpecificProps.projectId,
          }}
        />
      );

    default:
      return <></>;
  }
};

export default ViewEditResourceForm;
