import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { Dialog } from '@material-ui/core';

import { isAttachResourcesRoleRequired } from 'sg-utils-frontend';
import { VAULT_DEV_TOOL_ID } from 'usg-types';

import { projectApiRef } from '../../api';

import {
  initalAttachUserGroups,
  TUserGroupAttachment,
  UserGroupAttachmentRequest,
} from './types';
import { AttachGroupActions } from './AttachGroupActions';
import { DialogHeader } from './DialogHeader';
import { AttachUserGroupContent } from './AttachUserGroupContent';

export const AttachUserGroupToResource = (props: {
  isVisible: boolean;
  handleClose: () => void;
  projectId: string;
  userGroups: any;
  isAdmin: boolean;
  resourceData: any;
  handleSuccess: () => void;
  isGithubEMU?: boolean;
}) => {
  const {
    isVisible,
    handleClose,
    projectId,
    userGroups,
    handleSuccess,
    resourceData,
    isGithubEMU = false,
  } = props;

  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);

  const [attachError, setAttachError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');

  const [userGroupAttachments, setUserGroupAttachments] = useState<
    TUserGroupAttachment[]
  >(initalAttachUserGroups);

  const [{ loading: isLoadingUserGroup = false }, attachResources] = useAsyncFn(
    async (request: UserGroupAttachmentRequest[]) => {
      const token = await authRef.getIdToken();
      // new api
      const userGroupRolesValues = request?.map((ug: any) => {
        return { id: parseInt(ug.id, 10), role: ug.role };
      });
      const paramsdata = {
        idToken: token,
        userGroupRoles: [
          {
            op: 'add',
            value: userGroupRolesValues,
          },
        ],
      };

      const res = await projectApi.assignUserGroupToResources(
        paramsdata,
        projectId,
        resourceData?.id,
      );
      if (res?.status === 200) {
        handleSuccess();
        setUserGroupAttachments(initalAttachUserGroups);
      } else {
        setAttachError(res?.data?.message);
      }

      handleClose();
    },
    [resourceData],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setAttachError('');
      setDuplicateError('');
      setUserGroupAttachments(initalAttachUserGroups);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);

  // check if all roles are selected properly with required data
  const isRoleSelected = userGroupAttachments.every((item: any) => {
    // For non vault or when New vault ui feature flag is disabled then use the default checking for role requirement
    if (resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID) {
      return isAttachResourcesRoleRequired(
        resourceData?.dev_tool_id,
        item.usergroup_id,
        item.role,
      );
    }

    // for new vault ui feature flag enabled and the resource is vault then role and ug must exists
    return item.usergroup_id !== '' && item.role !== '';
  });

  const isAssignResourceDisable =
    userGroupAttachments.length === 0 ||
    !isRoleSelected ||
    isLoadingUserGroup ||
    duplicateError !== '';

  const handleDialogClose = () => {
    setUserGroupAttachments(initalAttachUserGroups);
    setAttachError('');
    setDuplicateError('');
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isVisible}
      onClose={handleDialogClose}
    >
      <DialogHeader handleClose={handleDialogClose} />
      <AttachUserGroupContent
        userGroupAttachments={userGroupAttachments}
        setDuplicateError={setDuplicateError}
        setUserGroupAttachments={setUserGroupAttachments}
        projectId={projectId}
        setAttachError={setAttachError}
        isLoadingUserGroup={isLoadingUserGroup}
        isGithubEMU={isGithubEMU}
        attachError={attachError}
        duplicateError={duplicateError}
        userGroups={userGroups}
        resourceData={resourceData}
      />
      <AttachGroupActions
        isLoadingUserGroup={isLoadingUserGroup}
        handleClose={handleDialogClose}
        isAssignResourceDisable={isAssignResourceDisable}
        userGroupAttachments={userGroupAttachments}
        attachResources={attachResources}
      />
    </Dialog>
  );
};
