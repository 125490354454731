import React from 'react';

import { Link } from '@backstage/core-components';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Button, FormHelperText } from '@material-ui/core';

import { ResourceUrlProps } from './types';
import { useStyles } from '../styles';

export const ResourceUrl = ({
  resourceUrl,
  projectId,
  resourceId,
  copyButtonLabel,
  setCopyButtonLabel,
}: ResourceUrlProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.shareableUrl}>
        <Link
          to={`${resourceUrl}`}
          target="_blank"
          style={{
            fontWeight: 'bold',
            textDecorationLine: 'underline',
            color: '#1059CD',
            marginBottom: '28px',
          }}
        >
          <span style={{ display: 'flex' }}>
            {resourceUrl}
            <OpenInNewIcon fontSize="small" style={{ marginLeft: '8px' }} />
          </span>
        </Link>
        <div>
          <Button
            id="project-button-copyresourceurl"
            variant="outlined"
            style={{ marginLeft: '24px' }}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.protocol}//${window.location.hostname}/projects/${projectId}?resource=${resourceId}&path=`,
              );
              setCopyButtonLabel('COPIED');
              setTimeout(() => {
                setCopyButtonLabel('Shareable URL');
              }, 2000);
            }}
            disabled={copyButtonLabel === 'COPIED'}
          >
            {copyButtonLabel}{' '}
            <FileCopyIcon style={{ marginLeft: '8px' }} fontSize="small" />
          </Button>
          <FormHelperText style={{ marginLeft: '24px', padding: '0 24px' }}>
            Click to copy resource URL.
          </FormHelperText>
        </div>
      </div>
    </>
  );
};
