/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { TabProps } from '@material-ui/core/Tab';
import { RoutedTabs } from '@backstage/core-components';
import { Header } from '../Header';
import {
  attachComponentData,
  useElementFilter,
} from '@backstage/core-plugin-api';
import { BackToLink } from '../Header/components/Navigation';
import { PathLink } from '../SGBreadcrumbs';

/** @public */
export type PageLayoutRouteProps = {
  path: string;
  title: string;
  children: JSX.Element;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

export const LAYOUT_DATA_KEY = 'plugin.projects.pageLayout';
export const LAYOUT_ROUTE_DATA_KEY = 'plugin.projects.pageLayoutRoute';

const Route: (props: PageLayoutRouteProps) => null = () => null;
attachComponentData(Route, LAYOUT_ROUTE_DATA_KEY, true);

// This causes all mount points that are discovered within this route to use the path of the route itself
attachComponentData(Route, 'core.gatherMountPoints', true);

interface PageLayoutWithTabsProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  type?: 'catalog' | 'entity';
  headerAdditionalControls?: JSX.Element;
  headerExtendedContent?: JSX.Element;
  backToLink?: BackToLink | PathLink[];
  headerBackgroundImg?: string;
  children: React.ReactNode;
}

export const PageLayoutWithTabs = (props: PageLayoutWithTabsProps) => {
  const {
    title,
    subtitle,
    type = 'catalog',
    headerAdditionalControls,
    headerExtendedContent,
    backToLink,
    headerBackgroundImg,
    children,
  } = props;

  const routes = useElementFilter(children, elements =>
    elements
      .selectByComponentData({
        key: LAYOUT_ROUTE_DATA_KEY,
        withStrictError:
          'Child of PagesLayoutWithTabs must be an PageLayoutWithTabs.Route',
      })
      .getElements<PageLayoutRouteProps>()
      .map(child => child.props),
  );

  return (
    <>
      <Header
        type={type}
        title={title}
        subtitle={subtitle}
        additionalControls={headerAdditionalControls}
        extendedContent={headerExtendedContent}
        backToLink={backToLink}
        backgroundImg={headerBackgroundImg}
      />
      <RoutedTabs routes={routes} />
    </>
  );
};

attachComponentData(PageLayoutWithTabs, LAYOUT_DATA_KEY, true);

PageLayoutWithTabs.Route = Route;
