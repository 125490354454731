import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useStyles } from '../../../styles';

type DialogUnArchiveConfirmationProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onConfirm: () => Promise<void>;
};

export const DialogUnArchiveConfirmation = ({
  isOpen,
  setIsOpen,
  onConfirm,
}: DialogUnArchiveConfirmationProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      maxWidth="sm"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginX: 2 }}>
          Confirm Unarchive
        </Typography>
      </DialogTitle>
      <IconButton
        id="project-iconbutton-close"
        onClick={() => setIsOpen(!isOpen)}
        className={classes.dialogCloseIcon}
        sx={{ position: 'absolute' }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent
        id="vault-environment-archive-confirmation-dialog"
        style={{ fontWeight: 'bold' }}
      >
        <Typography variant="body1" sx={{ marginX: 1, marginY: 1 }}>
          Are you sure you want to unarchive this environment?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogCancelButton}>
        <Button
          id="dialog-vault-environment-cancel-button"
          variant="outlined"
          size="small"
          onClick={() => setIsOpen(!isOpen)}
        >
          Cancel
        </Button>
        <Button
          id="dialog-vault-environment-archive-button"
          variant="contained"
          size="small"
          onClick={async () => {
            setIsOpen(!isOpen);
            await onConfirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
