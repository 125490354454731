import { useCallback, useEffect, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { VAULT_DEV_TOOL_ID } from 'usg-types';
import { projectApiRef } from '../../../../api';

const ALL_ENVRIONMENTS = [
  {
    label: 'prod',
    name: 'Production',
  },
  {
    label: 'stg',
    name: 'Staging',
  },
  {
    label: 'dev',
    name: 'Development',
  },
];

const MAX_ENV = 3;

const sortEnvironment = (envs: any) => {
  return [
    envs.find((e: { environment: string }) => e.environment === 'prod'),
    envs.find((e: { environment: string }) => e.environment === 'stg'),
    envs.find((e: { environment: string }) => e.environment === 'dev'),
  ].filter(e => e !== undefined);
};

const getName = (label: string) => {
  return ALL_ENVRIONMENTS.filter(m => m.label === label)[0].name;
};

const extractEnvironment = (res: any) => {
  return {
    id: res.id,
    key: res.key,
    name: res.name,
    url: res.url,
    environment: res.environment,
    dev_tool_id: res.dev_tool_id,
    generated_resource_key: res.generated_resource_key,
    deleted_on: res.deleted_on ?? false,
  };
};

const collectEnvironments = (resource: any) => {
  const envs = [extractEnvironment(resource)];

  if (
    resource.linkedResources === undefined ||
    resource.linkedResources.length === 0
  ) {
    return sortEnvironment(envs);
  }

  for (const e of resource?.linkedResources) {
    if (envs.filter(el => el.id === e.id).length > 0) {
      continue;
    }
    envs.push(extractEnvironment(e));
  }

  return sortEnvironment(envs);
};

export const useEnvironmentsHook = (
  projectId: string,
  resource: any,
  userPermissions: any,
  selectedTabIndex: number,
  setSelectedTabIndex: (index: number) => void,
) => {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [environments, setEnvironments] = useState(
    collectEnvironments(resource),
  );
  const [menus, setMenus] = useState<string[]>([]);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const isResourceManagerOfAnyEnv = () => {
    let isAnyResourceManager = false;
    if (environments?.length) {
      for (let i = 0; i < environments.length; i++) {
        if (userPermissions.isResourceManager(Number(environments[i].id))) {
          isAnyResourceManager = true;
        }
      }
      return isAnyResourceManager;
    }
    return false;
  };

  const refreshProject = useCallback(async () => {
    const idToken = await authref.getIdToken();
    const params = {
      manipulators: [
        'resources',
        `resource_${resource.id}`,
        'is_combined',
        'user_groups',
      ],
    };

    try {
      const updatedProject = await projectApi.getProjectByID(
        projectId,
        idToken,
        params,
      );
      setEnvironments(collectEnvironments(updatedProject.resources[0]));
    } catch (error) {
      errorApi.post(new Error(`${error?.message}`));
    }
  }, [authref, resource, projectApi, projectId, errorApi]);

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
    } else {
      // refresh the environments
      refreshProject();
    }
  }, [selectedTabIndex, isFirstTime, setIsFirstTime, refreshProject]);

  useEffect(() => {
    if (environments.length === 0) {
      return;
    }
    const missingEnvs = ALL_ENVRIONMENTS.filter(
      m => environments.filter(e => e.environment === m.label).length === 0,
    );
    setMenus(missingEnvs.map(m => m.name));
  }, [environments]);

  const resourceIdForCreatingNewEnv = () => {
    let rId = resource.id;
    for (let i = 0; i < resource.linkedResources?.length; i++) {
      if (
        userPermissions.isResourceManager(
          Number(resource.linkedResources[i].id),
        )
      ) {
        rId = Number(resource.linkedResources[i].id);
      }
    }
    return rId;
  };

  const createNewEnvironment = async (newEnvironment: string) => {
    const newEnvName = ALL_ENVRIONMENTS.filter(
      e => e.name === newEnvironment,
    )[0].label;

    const idToken = await authref.getIdToken();
    const resourceId = resourceIdForCreatingNewEnv();
    const params: any = {
      idToken,
      environments: [{ environment: newEnvName }],
    };

    try {
      const rsp: any = await projectApi.createNewProjectResourceAsPerEnv(
        Number(projectId),
        resourceId,
        params,
      );

      let success = false;
      for (const item of rsp.result) {
        if (item.status !== 'fulfilled') {
          continue;
        }
        if (item.value.status !== 200 && item.value.status !== 201) {
          continue;
        }

        // found successfully created env
        // no need to continue since we can only create 1 env at a time
        const newEnvironmentList = sortEnvironment([
          extractEnvironment(item.value.data),
          ...environments,
        ]);
        setEnvironments(newEnvironmentList);
        success = true;

        // set selected tab index to the newly created environment tab
        const newEnvironmentIndex = newEnvironmentList.findIndex(
          env => env.environment === item.value.data.environment,
        );
        setSelectedTabIndex(newEnvironmentIndex);
        break;
      }

      if (!success) {
        errorApi.post(
          new Error(
            `Something went wrong, resource creation for ${newEnvName} environment was not successful`,
          ),
        );
      }
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
    }
  };

  const isAddEnvEnabled = () => {
    if (environments.length === MAX_ENV) {
      return false;
    }

    const isProjectOwner = userPermissions.isProjectOwner(Number(projectId));
    if (
      isProjectOwner ||
      userPermissions.isAdmin ||
      (userPermissions.isResourceCreator(
        Number(projectId),
        VAULT_DEV_TOOL_ID,
      ) &&
        isResourceManagerOfAnyEnv())
    ) {
      return true;
    }
    return false;
  };

  return {
    menus,
    environments,
    createNewEnvironment,
    getName,
    isAddEnvEnabled,
    setEnvironments,
  };
};
