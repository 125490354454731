import project from './en/project-en.json';
import resource from './en/resource-en.json';
import common from './en/common-en.json';
// import usergroups from './en/usergroups.json';

export const en = {
  project,
  resource,
  common,
  // usergroups,
};
