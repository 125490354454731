import React, { useEffect, useState } from 'react';

import { Table, TableColumn } from '@backstage/core-components';
import { Link } from '@material-ui/core';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { EmptyDataMessage } from '@internal/sg-ui-kit';
import { DEFAULT_TABLE_PAGE_SIZE_OPTIONS } from 'usg-types';

import { AttachUserGroupToResource } from '../../../../../AttachUserGroupToResource';
import { DetachResource } from '../../../../../DetachResource';
import { UpdateResourceRole } from '../../../../../UpdateResourceRole';
import { useViewEditResourceContext } from '../../../context';
import {
  EMPTY_USER_GROUP,
  UserGroupEntity,
  useUserGroupsHook,
} from '../../../hooks/useUserGroupsNewHook';
import { DialogSuccessAssignUserGroups } from '../Dialogs/DialogSuccessAssignUserGroups';
import { DialogName, Environment } from './types';

import { SortedUserGroupsArrayType } from '../../../types';

import { useStyles } from '../../../../styles';

type UserGroupProps = {
  id: string;
  name: string;
};

const UserGroupName = ({ id, name }: UserGroupProps) => {
  const resourceContext = useViewEditResourceContext();
  const classes = useStyles();

  return (
    <Link
      href={`/projects/${resourceContext.projectDetails.id}/usergroup/${id}`}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.hyperLink}
      title={name}
    >
      {name}
    </Link>
  );
};

type ActionsProps = {
  disableGroupListChanges: (environmentId: string) => boolean;
  setDisplayedDialog: (input: DialogName) => void;
  setSelectedUserGroup: (input: UserGroupEntity) => void;
  userGroup: UserGroupEntity;
  environmentId: string;
};

type ActionButtonProps = ActionsProps & {
  buttonLabel: string;
};

const ActionButton = ({
  disableGroupListChanges,
  environmentId,
  setSelectedUserGroup,
  userGroup,
  setDisplayedDialog,
  buttonLabel,
}: ActionButtonProps) => {
  const classes = useStyles();

  const DIALOG_TOBE_DISPLAYED =
    buttonLabel === 'Detach Group'
      ? DialogName.DETACH_USER_GROUP
      : DialogName.UPDATE_USER_GROUP_ROLE;

  const BUTTON_STYLE =
    buttonLabel === 'Detach Group'
      ? {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
        }
      : {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
          paddingLeft: 0,
        };

  const BUTTON_ID =
    buttonLabel === 'Detach Group'
      ? 'detach-group-button'
      : 'change-role-button';

  return (
    <Button
      id={BUTTON_ID}
      disabled={disableGroupListChanges(environmentId) ? true : false}
      disableRipple
      sx={BUTTON_STYLE}
      onClick={() => {
        setSelectedUserGroup({
          name: userGroup.name,
          id: userGroup.id,
          role: userGroup.role,
        });
        setDisplayedDialog(DIALOG_TOBE_DISPLAYED);
      }}
    >
      <Typography
        className={
          !disableGroupListChanges(environmentId)
            ? classes.enabledBtn
            : classes.disabledBtn
        }
        textTransform="initial"
        variant="body2"
        fontWeight="bold"
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
};

const Actions = (props: ActionsProps) => {
  return (
    <Stack direction="row" spacing={0}>
      <ActionButton {...props} buttonLabel="Change Role" />
      <ActionButton {...props} buttonLabel="Detach Group" />
    </Stack>
  );
};

type AssignUserButtonProps = {
  environmentId: string;
  disableGroupListChanges: (environmentId: string) => boolean;
};

const AssignUserButton = ({
  environmentId,
  disableGroupListChanges,
}: AssignUserButtonProps) => {
  const classes = useStyles();
  return (
    <Typography
      fontWeight="bold"
      className={
        !disableGroupListChanges(environmentId)
          ? classes.enabledBtn
          : classes.disabledBtn
      }
    >
      ASSIGN USER GROUP
    </Typography>
  );
};

type ResourceRole = {
  name: string;
  display_name: string;
};

type AssignUserGroupDialogsProps = {
  displayedDialog: DialogName;
  setDisplayedDialog: (input: DialogName) => void;
  environmentId: string;
  resourceRoles: ResourceRole[];
  setIsNeedToDisplaySuccess: (value: boolean) => void;
  refreshAssignedGroups: () => void;
  availableUserGroups: SortedUserGroupsArrayType;
  selectedUserGroup: UserGroupEntity;
  setSelectedUserGroup: (input: UserGroupEntity) => void;
  openSuccess: boolean;
  setOpenSuccess: (value: boolean) => void;
};

const AssignUserGroupDialogs = ({
  displayedDialog,
  setDisplayedDialog,
  environmentId,
  setIsNeedToDisplaySuccess,
  resourceRoles,
  refreshAssignedGroups,
  availableUserGroups,
  setSelectedUserGroup,
  selectedUserGroup,
  openSuccess,
  setOpenSuccess,
}: AssignUserGroupDialogsProps) => {
  const resourceContext = useViewEditResourceContext();
  const currentResource = resourceContext.projectDetails.resources[0];

  return (
    <>
      <AttachUserGroupToResource
        isVisible={displayedDialog === DialogName.ASSIGN_USER_GROUPS}
        handleClose={() => {
          setDisplayedDialog(DialogName.NONE);
        }}
        projectId={resourceContext.projectDetails.id}
        userGroups={availableUserGroups}
        isAdmin={resourceContext.userDetailsAndPermissions.isAnyAdmin}
        resourceData={{
          id: environmentId,
          roles: resourceRoles,
          dev_tool_id: currentResource.dev_tool_id,
        }}
        handleSuccess={() => {
          setIsNeedToDisplaySuccess(true);
        }}
      />

      <UpdateResourceRole
        isVisible={displayedDialog === DialogName.UPDATE_USER_GROUP_ROLE}
        handleClose={() => {
          setSelectedUserGroup(EMPTY_USER_GROUP);
          setDisplayedDialog(DialogName.NONE);
        }}
        handleSuccess={() => {
          setIsNeedToDisplaySuccess(true);
        }}
        projectId={resourceContext.projectDetails.id}
        resourceId={Number(environmentId)}
        resourceName={currentResource?.name}
        resourceRole={selectedUserGroup?.role}
        groupData={selectedUserGroup} // groupdata should be removed on feautre flag removal
        resourceRoleOptions={resourceRoles}
      />

      <DetachResource
        isVisible={displayedDialog === DialogName.DETACH_USER_GROUP}
        handleClose={() => {
          setDisplayedDialog(DialogName.NONE);
        }}
        handleSuccess={() => {
          setIsNeedToDisplaySuccess(true);
        }}
        projectId={resourceContext.projectDetails.id}
        groupId={selectedUserGroup?.id.toString()}
        resourceId={Number(environmentId)}
        resourceName={currentResource?.name}
        groupData={selectedUserGroup} // groupdata should be removed on feautre flag removal
        tab="usergroups"
      />
      <DialogSuccessAssignUserGroups
        isOpen={openSuccess}
        handleSuccessClose={() => {
          setOpenSuccess(false);
          refreshAssignedGroups();
        }}
      />
    </>
  );
};

type UserGroupTableProps = {
  pageSize: number;
  environmentId: string;
  setDisplayedDialog: (input: DialogName) => void;
  assignedUserGroups: UserGroupEntity[];
  setSelectedUserGroup: (input: UserGroupEntity) => void;
};

const UserGroupTable = ({
  pageSize,
  environmentId,
  setDisplayedDialog,
  assignedUserGroups,
  setSelectedUserGroup,
}: UserGroupTableProps) => {
  const resourceContext = useViewEditResourceContext();

  const disableGroupListChanges = (resourceID: string) => {
    if (
      !resourceContext.userDetailsAndPermissions.isProjectOwner(
        Number(resourceContext.projectDetails.id),
      ) &&
      !resourceContext.userDetailsAndPermissions.isAdmin
    ) {
      return !resourceContext.userDetailsAndPermissions.isResourceManager(
        Number(resourceID),
      );
    }

    return false;
  };

  const feedColumns: Array<TableColumn<UserGroupEntity>> = [
    {
      field: 'name',
      title: 'Group Name',
      width: '25%',
      render: ({ id, name }) => <UserGroupName id={String(id)} name={name} />,
    },
    {
      field: 'role',
      title: 'Role',
      width: '25%',
      render: ({ role }) => <Typography>{role}</Typography>,
    },
    {
      field: 'actions',
      title: 'Actions',
      width: '25%',
      render: ({ id, name, role }) => (
        <Actions
          disableGroupListChanges={disableGroupListChanges}
          userGroup={{ id, name, role }}
          environmentId={environmentId}
          setSelectedUserGroup={setSelectedUserGroup}
          setDisplayedDialog={setDisplayedDialog}
        />
      ),
    },
  ];

  const displayAction = () => {
    return (
      <AssignUserButton
        environmentId={environmentId}
        disableGroupListChanges={disableGroupListChanges}
      />
    );
  };

  return (
    <Table
      columns={feedColumns}
      options={{
        paging: true,
        search: false,
        sorting: false,
        actionsColumnIndex: -1,
        padding: 'dense',
        pageSize: pageSize,
        pageSizeOptions: DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
        emptyRowsWhenPaging: false,
        actionsCellStyle: { padding: '5px 10px' },
      }}
      data={assignedUserGroups}
      title="ASSIGNED GROUP LIST"
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessage message="No assigned user groups." />
          ),
        },
      }}
      actions={[
        {
          icon: displayAction,
          tooltip: 'assign user group',
          isFreeAction: true,
          onClick: () => setDisplayedDialog(DialogName.ASSIGN_USER_GROUPS),
          disabled: disableGroupListChanges(environmentId),
          hidden: false,
        },
      ]}
    />
  );
};

type AssignUserGroupsComponentProps = {
  environment: Environment;
};

export const AssignUserGroupsComponent = ({
  environment,
}: AssignUserGroupsComponentProps) => {
  const resourceContext = useViewEditResourceContext();
  const {
    refreshAssignedGroups,
    assignedUserGroups,
    availableUserGroups,
    resourceRoles,
  } = useUserGroupsHook(resourceContext.projectDetails, String(environment.id));

  const [selectedUserGroup, setSelectedUserGroup] =
    useState<UserGroupEntity>(EMPTY_USER_GROUP);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
  );

  const [displayedDialog, setDisplayedDialog] = useState<DialogName>(
    DialogName.NONE,
  );
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isNeedToDisplaySuccess, setIsNeedToDisplaySuccess] = useState(false);

  useEffect(() => {
    if (displayedDialog === DialogName.NONE && isNeedToDisplaySuccess) {
      // We use timeout here because if we open the success dialog immediately, it will make the vertical scrollbar to disappear then the page cannot be scrolled again
      // ideally we use just one dialog instead of 2 dialogs
      // then this 1 dialog will have its content changed during different phases (display first message, processing, success message and failed message)
      setTimeout(() => setOpenSuccess(true), 500);
    } else {
      setIsNeedToDisplaySuccess(false);
    }
  }, [displayedDialog, isNeedToDisplaySuccess]);

  // the page of the table is always 1 because the data from backend for
  // the assigned user groups are actually NOT paginated. and just give list of all assigned user groups
  // therefore, paginated table is not
  // really correct to be chosen for displaying the UserGroups.
  useEffect(() => {
    if (assignedUserGroups === undefined) {
      return;
    }
    setPageSize(pageSize + DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0]);
  }, [assignedUserGroups, pageSize]);

  return (
    <>
      <UserGroupTable
        pageSize={pageSize}
        environmentId={String(environment.id)}
        setDisplayedDialog={setDisplayedDialog}
        assignedUserGroups={assignedUserGroups ?? []}
        setSelectedUserGroup={setSelectedUserGroup}
      />
      <AssignUserGroupDialogs
        displayedDialog={displayedDialog}
        setDisplayedDialog={setDisplayedDialog}
        environmentId={String(environment.id)}
        setIsNeedToDisplaySuccess={setIsNeedToDisplaySuccess}
        resourceRoles={resourceRoles}
        refreshAssignedGroups={refreshAssignedGroups}
        availableUserGroups={availableUserGroups}
        setSelectedUserGroup={setSelectedUserGroup}
        selectedUserGroup={selectedUserGroup}
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
      />
    </>
  );
};
