import React, { useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TablePagination,
  Tooltip,
  Radio,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { Table, TableColumn } from '@backstage/core-components';
import { AlertNotification, EmptyDataMessage } from '@internal/sg-ui-kit';
import { useParams } from 'react-router';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { useAsyncFn } from 'react-use';
import GetAppIcon from '@material-ui/icons/GetApp';
import DatePicker from 'react-multi-date-picker';
import { CSVLink } from 'react-csv';
import CloudUsageSummary from './CloudUsageSummary';
import { getRoles } from '@internal/sg-utils-common';
import { transformResourceName } from 'sg-utils-frontend';
import {
  ADMIN,
  DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
  RO_ADMIN,
  SMC_CLOUD_USAGE,
} from 'usg-types';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { cloudUsageApiRef } from '@internal/backstage-plugin-chargeback';

import { useStyles } from './styles';

export const CloudUsage = ({ projId }: { projId?: string }) => {
  const classes = useStyles();
  const { projectId = projId } = useParams();
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const cloudUsageApi = useApi(cloudUsageApiRef);
  const csvRef = useRef<any>();
  const [isNoRecords, setIsNoRecords] = React.useState(false);
  const [downloadInProgress, setDownloadInProgress] = React.useState(true);
  const [smcCloudUsageEnabled, setSmcCloudUsageEnabled] = React.useState(false);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  const [tableState, setTableState] = React.useState({
    page: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
  });
  const [selectedResourceType, setSelectedResourceType] = React.useState('GHE');
  const [runnerType, setRunnerType] = React.useState('Runner by Repo');

  React.useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('usageType', 'cloud');
    window.history.pushState(null, '', url.toString());
  }, []);

  const startTimeFormat = (date: any) => {
    return new Date(
      Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    );
  };
  const endTimeFormat = (date: any) => {
    return new Date(
      Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth() + 1,
        new Date(date).getDate() - 1,
        23,
        59,
        59,
        0,
      ),
    );
  };
  const defaultDate = new Date();
  const defaultEndDate = new Date(
    Date.UTC(
      defaultDate.getFullYear(),
      defaultDate.getMonth(),
      defaultDate.getDate(),
      23,
      59,
      59,
      0,
    ),
  );
  defaultDate.setMonth(defaultDate.getMonth() - 1, 1);
  const [values, setValues] = React.useState([defaultDate, new Date()]);
  const [dates, setDates] = React.useState({
    start_time: startTimeFormat(defaultDate),
    end_time: defaultEndDate,
  });
  const [sortingState, setSortingState] = React.useState<{
    order: 'asc' | 'desc' | undefined;
    orderBy: string;
  }>({
    order: 'asc',
    orderBy: 'name',
  });
  const feedColumns: Array<TableColumn<any>> = [
    {
      field: 'name',
      title: 'Repository Name',
      width: '25%',
      hidden: !(
        runnerType === 'Runner by Repo' &&
        (selectedResourceType === 'AS' ||
          selectedResourceType === 'ARTIFACTORY_SAAS' ||
          selectedResourceType === 'GHE' ||
          selectedResourceType === 'EMU')
      ),
      defaultSort: sortingState.order,
    },
    {
      field: 'type',
      title: 'Runner Type',
      width: '25%',
      hidden: runnerType !== 'Runner by Repo' ? false : true,
      defaultSort: sortingState.order,
    },
    {
      field: 'usage_gigabytes_rounded',
      title: 'Network Storage (GB)',
      type: 'numeric',
      width: '20%',
      sorting: false,
      hidden:
        selectedResourceType === 'AS' ||
        selectedResourceType === 'ARTIFACTORY_SAAS'
          ? false
          : true,
    },
    {
      field: 'network_usage_gigabytes_rounded',
      type: 'numeric',
      title: 'Network Usage (GB)',
      defaultSort: sortingState.order,
      width: '20%',
      hidden: selectedResourceType === 'ARTIFACTORY_SAAS' ? false : true,
    },
    {
      field: 'usage_hours_rounded',
      title: 'Hours',
      type: 'numeric',
      width: '20%',
      defaultSort: sortingState.order,
      hidden:
        selectedResourceType === 'GHE' || selectedResourceType === 'EMU'
          ? false
          : true,
    },
    {
      field: 'name',
      title: 'Metrics Source',
      width: '25%',
      hidden: selectedResourceType === 'OBSMETRICS' ? false : true,
      defaultSort: sortingState.order,
      customSort: (row1, row2): number => {
        const col1 = row1.name.toLowerCase();
        const col2 = row2.name.toLowerCase();

        if (col1 < col2) {
          return -1;
        }
        if (col1 > col2) {
          return 1;
        }
        return 0;
      },
    },
    {
      field: 'type',
      title: 'Type',
      width: '20%',
      hidden: selectedResourceType === 'OBSMETRICS' ? false : true,
      defaultSort: sortingState.order,
    },
    {
      field: 'points_per_second_rounded',
      title: 'PPS',
      type: 'numeric',
      width: '20%',
      defaultSort: sortingState.order,
      hidden: selectedResourceType === 'OBSMETRICS' ? false : true,
    },
    {
      field: 'cost_rounded',
      type: 'numeric',
      title: 'Approx. Cost(JP¥)',
      defaultSort: sortingState.order,
      width: '20%',
      hidden:
        selectedResourceType === 'GHE' ||
        selectedResourceType === 'EMU' ||
        selectedResourceType === 'ARTIFACTORY_SAAS' ||
        selectedResourceType === 'OBSMETRICS'
          ? false
          : true,
      render: expense => expense?.cost_rounded?.toLocaleString(),
    },
    {
      field: 'name',
      title: 'Log Source',
      width: '20%',
      hidden: selectedResourceType === 'OBSLOGS' ? false : true,
      defaultSort: sortingState.order,
    },
    {
      field: 'ingested_gigabytes_rounded',
      title: 'Bytes Ingested (GB)',
      type: 'numeric',
      width: '20%',
      defaultSort: sortingState.order,
      hidden: selectedResourceType === 'OBSLOGS' ? false : true,
    },
    {
      field: 'ingestion_cost_rounded',
      type: 'numeric',
      title: 'Ingestion Cost (JP¥)',
      defaultSort: sortingState.order,
      width: '20%',
      hidden: selectedResourceType === 'OBSLOGS' ? false : true,
      render: expense => expense?.ingestion_cost_rounded?.toLocaleString(),
    },
    {
      field: 'storage_gigabytes_rounded',
      type: 'numeric',
      title: 'Bytes Stored (GB)',
      defaultSort: sortingState.order,
      width: '20%',
      hidden: selectedResourceType === 'OBSLOGS' ? false : true,
    },
    {
      field: 'storage_cost_rounded',
      type: 'numeric',
      title: 'Storage Cost (JP¥)',
      defaultSort: sortingState.order,
      width: '20%',
      hidden: selectedResourceType === 'OBSLOGS' ? false : true,
      render: expense => expense?.storage_cost_rounded?.toLocaleString(),
    },
    {
      field: 'item',
      title: 'Item',
      defaultSort: sortingState.order,
      width: '25%',
      hidden: selectedResourceType === 'SMC' ? false : true,
    },
    {
      field: 'cost',
      type: 'numeric',
      title: 'Cost (JP¥)',
      defaultSort: sortingState.order,
      width: '20%',
      hidden: selectedResourceType === 'SMC' ? false : true,
    },
    {
      field: 'cost_rounded',
      type: 'numeric',
      title: 'Approx. Cost (JP¥)',
      defaultSort: sortingState.order,
      width: '20%',
      hidden: selectedResourceType === 'SMC' ? false : true,
      render: expense => expense?.cost_rounded?.toLocaleString(),
    },
  ];

  const [resourceTypeData, setResourceTypeData] = React.useState<any[]>([
    {
      id: 1,
      value: 'GHE',
      name: 'GitHub Enterprise Runners',
    },
    {
      id: 2,
      value: 'EMU',
      name: 'GitHub EMU Runners',
    },
    {
      id: 3,
      value: 'AS',
      name: `${transformResourceName('Artifactory')} Storage`,
    },
    {
      id: 4,
      value: 'OBSLOGS',
      name: 'Observability GCP Cloud Logging',
    },
    {
      id: 5,
      value: 'OBSMETRICS',
      name: 'Observability Wavefront',
    },
    {
      id: 7,
      value: 'ARTIFACTORY_SAAS',
      name: 'Artifactory SaaS',
    },
    // Future resource types
    // {
    //   id: 4,
    //   value: 'Mtfuji',
    //   name: 'Mt.fuji',
    // },
    // {
    //   id: 5,
    //   value: 'OBS',
    //   name: 'Oberservability',
    // },
    // {
    //   id: 6,
    //   value: 'BRC',
    //   name: 'Bazel Remote Cache',
    // },
  ]);

  const [{ value: roles }, fetchRoles] = useAsyncFn(async () => {
    const token = await authref.getIdToken();
    const paramsForUserDetails = {
      idToken: token,
    };
    const queryParams = {
      include: 'owned_projects',
    };
    const res: any = await projectApi.getUserDetails(
      paramsForUserDetails,
      queryParams,
    );
    if (res?.response?.status === 200) {
      const roleArray = getRoles(res?.response?.data?.roles);
      return roleArray;
    }
    return [];
  }, []);

  React.useEffect(
    () => {
      (async () => {
        const smcCloudUsageResponse = await featureFlagsApi.getBinaryFlag(
          SMC_CLOUD_USAGE,
        );
        setSmcCloudUsageEnabled(smcCloudUsageResponse.data);
      })();
      fetchRoles();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (smcCloudUsageEnabled) {
      setResourceTypeData(prev => [
        ...prev,
        {
          id: 6,
          value: 'SMC',
          name: 'Stargate Multicloud',
        },
      ]);
    }
  }, [smcCloudUsageEnabled]);

  React.useEffect(
    () => {
      if (roles?.includes(ADMIN) || roles?.includes(RO_ADMIN)) {
        setResourceTypeData([...resourceTypeData]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles],
  );

  const runnerTypes = [
    {
      id: 1,
      name: 'Runner by Repo',
    },
    {
      id: 2,
      name: 'Runner by Type',
    },
  ];
  const [cloudUsageTableData, setCloudUsageTableData] = React.useState(
    [] as any,
  );
  const [cloudUsageData, setCloudUsageData] = React.useState([] as any);
  const [cloudUsageCSVData, setCloudUsageCSVData] = React.useState([] as any);
  const [{ loading }, fetchCloudData] = useAsyncFn(async () => {
    setIsNoRecords(false);
    const data = (await await authref.getIdToken().then(async (token: any) => {
      const params = {
        start_time: dates.start_time.toISOString(),
        end_time: dates.end_time.toISOString(),
        page: tableState.page,
        size: tableState.pageSize,
        // need to revisit during actual API integration
        show_cost: true,
      } as any;

      if (sortingState.order !== undefined) {
        params.order = sortingState.order;
        params.order_by = sortingState.orderBy;
      }

      const idToken = {
        idToken: token,
      };
      switch (selectedResourceType) {
        case 'GHE':
          if (runnerType === 'Runner by Type') {
            return await cloudUsageApi.getGHEUsageByRunners(
              projectId,
              idToken,
              params,
            );
          }
          return await cloudUsageApi.getGHEUsageByRepos(
            projectId,
            idToken,
            params,
          );

        case 'EMU':
          if (runnerType === 'Runner by Type') {
            return await cloudUsageApi.getGitHubUsageByRunners(
              projectId,
              idToken,
              params,
            );
          }
          return await cloudUsageApi.getGitHubUsageByRepos(
            projectId,
            idToken,
            params,
          );

        case 'AS':
          return await cloudUsageApi.getArtifactoryCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'ARTIFACTORY_SAAS':
          return await cloudUsageApi.getArtifactorySaaSCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'OBSLOGS':
          return await cloudUsageApi.getObsLogsCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'OBSMETRICS':
          return await cloudUsageApi.getObsMetricsCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'SMC':
          return await cloudUsageApi.getMulticloudCostUsage(
            projectId,
            idToken,
            params,
          );
        default:
          return await cloudUsageApi.getGitHubUsageByRunners(
            projectId,
            idToken,
            params,
          );
      }
    })) as any;
    if (data?.response?.status === 200) {
      setCloudUsageData(data.response.data);
      if (
        (selectedResourceType === 'GHE' || selectedResourceType === 'EMU') &&
        runnerType === 'Runner by Type'
      ) {
        setCloudUsageTableData(data.response.data.runners);
      } else if (selectedResourceType === 'OBSLOGS') {
        setCloudUsageTableData(data.response.data.log_sources);
      } else if (selectedResourceType === 'OBSMETRICS') {
        setCloudUsageTableData(data.response.data.metric_sources);
      } else if (selectedResourceType === 'SMC') {
        setCloudUsageTableData(data.response.data.items);
      } else {
        setCloudUsageTableData(data.response.data.repositories);
      }
    } else {
      setCloudUsageData([]);
      setCloudUsageTableData([]);
    }
    const code = data?.response?.data?.status;
    if (code === 401 || code === 404 || code === 403) {
      const error = data?.response?.data?.message;
      errorApi.post(new Error(`${error}`));
    }
    const statusCode = data?.response?.status;
    if (statusCode !== 200) {
      const error = data?.error;
      errorApi.post(new Error(`${error}`));
    }
    setDownloadInProgress(false);
    return data;
  }, [selectedResourceType, tableState, runnerType, dates, sortingState]);

  React.useEffect(
    () => {
      setDownloadInProgress(true);
      fetchCloudData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [runnerType, selectedResourceType, tableState, dates, sortingState],
  );

  // increment row
  const handleRowChange = (e: any) => {
    const pageSize = e.target.value;
    const page = 1;
    setTableState({ ...tableState, pageSize, page });
  };

  // increment page
  const handlePageChange = (_: any, newPage: number) => {
    const page = newPage + 1;
    setTableState({ ...tableState, page });
  };

  const handleSelectResourceChange = async (event: any) => {
    setSelectedResourceType(event.target.value);
    setRunnerType('Runner by Repo');
    setSortingState({
      order: 'asc',
      orderBy: event.target.value === 'SMC' ? 'item' : 'name',
    });
  };

  const handleChangeRunnerType = (event: any) => {
    setRunnerType(event.target.value);
    setTableState({
      page: 1,
      pageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
    });
    switch (event.target.value) {
      case 'Runner by Type':
        setSortingState({ order: 'asc', orderBy: 'cost' });
        break;
      case 'Runner by Repo':
      default:
        setSortingState({ order: 'asc', orderBy: 'cost' });
        break;
    }
  };

  const helper_function = async (count: any) => {
    const data = (await authref.getIdToken().then(async (token: any) => {
      const params = {
        start_time: dates.start_time.toISOString(),
        end_time: dates.end_time.toISOString(),
        page: count,
        size: 1000,
        // need to revisit during actual API integration
        show_cost: true,
      } as any;
      const idToken = {
        idToken: token,
      };
      switch (selectedResourceType) {
        case 'GHE':
          if (runnerType === 'Runner by Type') {
            return await cloudUsageApi.getGHEUsageByRunners(
              projectId,
              idToken,
              params,
            );
          }
          return await cloudUsageApi.getGHEUsageByRepos(
            projectId,
            idToken,
            params,
          );

        case 'EMU':
          if (runnerType === 'Runner by Type') {
            return await cloudUsageApi.getGitHubUsageByRunners(
              projectId,
              idToken,
              params,
            );
          }
          return await cloudUsageApi.getGitHubUsageByRepos(
            projectId,
            idToken,
            params,
          );

        case 'AS':
          return await cloudUsageApi.getArtifactoryCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'ARTIFACTORY_SAAS':
          return await cloudUsageApi.getArtifactorySaaSCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'OBSLOGS':
          return await cloudUsageApi.getObsLogsCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'OBSMETRICS':
          return await cloudUsageApi.getObsMetricsCostUsage(
            projectId,
            idToken,
            params,
          );
        case 'SMC':
          return await cloudUsageApi.getMulticloudCostUsage(
            projectId,
            idToken,
            params,
          );
        default:
          return await cloudUsageApi.getGitHubUsageByRunners(
            projectId,
            idToken,
            params,
          );
      }
    })) as any;
    const code = data?.response?.data?.status;
    if (code === 401 || code === 404 || code === 403) {
      const error = data?.response?.data?.message;
      errorApi.post(new Error(`${error}`));
    }
    if (data?.response?.status === 500) {
      const error = data?.error;
      errorApi.post(new Error(`${error}`));
    }
    return data;
  };
  const getCSVPageCount = (): number => {
    if (
      (selectedResourceType === 'GHE' || selectedResourceType === 'EMU') &&
      runnerType === 'Runner by Type'
    ) {
      return Math.ceil(cloudUsageData?.total_runners_count / 1000);
    } else if (selectedResourceType === 'OBSLOGS') {
      return Math.ceil(cloudUsageData?.total_log_source_count / 1000);
    } else if (selectedResourceType === 'OBSMETRICS') {
      return Math.ceil(cloudUsageData?.total_metric_source_count / 1000);
    } else if (selectedResourceType === 'SMC') {
      return Math.ceil(cloudUsageData?.total_item_count / 1000);
    }
    return Math.ceil(cloudUsageData?.total_repo_count / 1000);
  };

  const getCSVData = (input: any) => {
    if (
      (selectedResourceType === 'GHE' || selectedResourceType === 'EMU') &&
      runnerType === 'Runner by Type'
    ) {
      return input?.response?.data?.runners;
    } else if (selectedResourceType === 'OBSLOGS') {
      return input?.response?.data?.log_sources;
    } else if (selectedResourceType === 'OBSMETRICS') {
      return input?.response?.data?.metric_sources;
    } else if (selectedResourceType === 'SMC') {
      return input?.response?.data?.items;
    }
    return input?.response?.data?.repositories;
  };

  const handleDownloadCSVReport = async () => {
    setIsNoRecords(false);
    setDownloadInProgress(true);
    if (cloudUsageTableData?.length === 0) {
      setIsNoRecords(true);
      setDownloadInProgress(false);
      return;
    }
    const pageNumbers = [];
    for (let i = 1; i <= getCSVPageCount(); i++) {
      pageNumbers.push(i);
    }
    const promises: any[] = [];
    Object.keys(pageNumbers).forEach((count: any) => {
      promises.push(helper_function(parseInt(count, 10) + 1));
    });
    const totalData = await Promise.all(promises).then(result => {
      return result;
    });
    const couldCSVData1 = totalData
      .map(i => Object.values(getCSVData(i)).flat())
      .flat();
    setCloudUsageCSVData(couldCSVData1);
    setDownloadInProgress(false);
  };

  useEffect(() => {
    if (cloudUsageCSVData.length > 0) {
      csvRef.current.link.click();
    }
  }, [cloudUsageCSVData]);

  const handleOrderChange = (orderBy: any, order: any) => {
    let columnOrder;
    let column;
    if (orderBy === -1) {
      columnOrder = undefined;
      column = '';
    } else {
      columnOrder = order;
      column =
        feedColumns[orderBy].field?.toString().split('_rounded')?.[0] ?? '';
    }
    setSortingState({ order: columnOrder, orderBy: column });
  };

  const getEndTime = (startDate: any, endDate: any, endTime: any) => {
    const currentDate = new Date();
    if (new Date(endTime).toString() === 'Invalid Date') {
      if (new Date(startDate).getMonth() === currentDate.getMonth()) {
        return defaultEndDate;
      }
      return endTimeFormat(startDate);
    }
    if (new Date(endDate).getMonth() === currentDate.getMonth()) {
      return defaultEndDate;
    }
    return endTime;
  };

  const getRecordsCount = (): number => {
    if (
      (selectedResourceType === 'GHE' || selectedResourceType === 'EMU') &&
      runnerType === 'Runner by Type'
    ) {
      return cloudUsageData?.total_runners_count || 0;
    } else if (selectedResourceType === 'OBSLOGS') {
      return cloudUsageData?.total_log_source_count || 0;
    } else if (selectedResourceType === 'OBSMETRICS') {
      return cloudUsageData?.total_metric_source_count || 0;
    } else if (selectedResourceType === 'SMC') {
      return cloudUsageData?.total_item_count || 0;
    }
    return cloudUsageData?.total_repo_count || 0;
  };

  return (
    <div>
      <AlertNotification
        severity="warning"
        alertMessage="There is no data to download"
        condition={isNoRecords}
      />
      <Grid container spacing={2} className={classes.resourceFilterSection}>
        <Grid item xs={3} className={classes.resourceType}>
          <FormControl variant="outlined" fullWidth>
            <Typography
              variant="button"
              component="div"
              style={{ marginBottom: '8px', textTransform: 'none' }}
              id="sort-label"
            >
              Resource Type
            </Typography>
            <Select
              labelId="sort-label"
              id="sort"
              displayEmpty
              value={selectedResourceType}
              onChange={handleSelectResourceChange}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {resourceTypeData.map((resource: any) => (
                <MenuItem key={resource.id} value={resource.value}>
                  {resource.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <Typography
              variant="button"
              component="div"
              style={{ marginBottom: '8px', textTransform: 'none' }}
              id="sort-label"
            >
              Month
            </Typography>
            <div className={classes.monthDate}>
              <DatePicker
                id="cu-date-picker"
                value={values as any}
                onlyMonthPicker
                minDate={new Date('01-09-2022')}
                render={<input type="text" readOnly />}
                maxDate={new Date()}
                onChange={(dateObject: any) => {
                  const startDate = dateObject[0];
                  const endDate = dateObject[1];
                  setValues(dateObject);
                  const start_time = startTimeFormat(startDate);
                  const endTime = endTimeFormat(endDate);
                  const end_time = getEndTime(startDate, endDate, endTime);
                  setDates({ ...dates, start_time, end_time });
                }}
                range
                rangeHover
              />
            </div>
            <FormHelperText>Select month or month range.</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.downloadIcon}>
          {downloadInProgress ? (
            <CircularProgress
              className={classes.downloadProgress}
              size="24px"
            />
          ) : (
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
              arrow
              placement="left"
              title="Download Report CSV"
            >
              <GetAppIcon
                id="download-csv"
                color="primary"
                onClick={() => handleDownloadCSVReport()}
              />
            </Tooltip>
          )}
          <CSVLink
            data={cloudUsageCSVData}
            filename="cloud-usage.csv"
            asyncOnClick
            ref={csvRef}
          />
        </Grid>
      </Grid>
      <CloudUsageSummary
        selectedResourceType={selectedResourceType}
        cloudUsageData={cloudUsageData}
        classes={classes}
      />
      <div className={classes.noteDiv}>
        <Typography className={classes.noteText}>
          <b>Note:</b> Calculations are based on the cloud provider and will
          vary based on the underlining cloud costs.
        </Typography>
        {selectedResourceType === 'GHE' || selectedResourceType === 'EMU' ? (
          <div id="radio-button-github-runner-by">
            {runnerTypes.map((runner: any, index: any) => (
              <span key={index}>
                <Radio
                  value={runner.name}
                  checked={runner.name === runnerType ? true : false}
                  name={`${runner.id}`}
                  id={`${runner.id}`}
                  inputProps={{ 'aria-label': runner.id }}
                  onChange={handleChangeRunnerType}
                />
                {runner.name}&nbsp;
              </span>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
      <Table
        isLoading={loading}
        columns={feedColumns}
        options={{
          paging: true,
          search: false,
          sorting: true,
          actionsColumnIndex: -1,
          padding: 'dense',
          pageSizeOptions: DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          actionsCellStyle: { padding: '5px 10px' },
          pageSize: 100,
          headerStyle: {
            fontWeight: 700,
            fontSize: '12px',
            textTransform: 'uppercase',
            color: '#0E1319B2',
            letterSpacing: '0.01em',
          },
        }}
        data={cloudUsageTableData}
        onOrderChange={handleOrderChange}
        title="Cloud Usage"
        localization={{
          body: {
            emptyDataSourceMessage: <EmptyDataMessage />,
          },
        }}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              rowsPerPageOptions={DEFAULT_TABLE_PAGE_SIZE_OPTIONS}
              count={getRecordsCount()}
              page={tableState.page - 1}
              onPageChange={handlePageChange}
              rowsPerPage={tableState.pageSize}
              onRowsPerPageChange={handleRowChange}
            />
          ),
        }}
      />
    </div>
  );
};
