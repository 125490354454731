import { useCallback, useEffect, useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';

import { VALIDATE_USER_MEMBERSHIP_REQUEST } from 'usg-types';

const useValidateUserMembershipFlag = () => {
  const [
    isUsingValidateUsermembershipRequest,
    setIsUsingValidateUsermembershipRequest,
  ] = useState<boolean>(false);
  const [loadingFlag, setLoadingFlag] = useState<boolean>(true);

  const featureFlagsApi = useApi(featureFlagsApiRef);

  const getFeatureFlag = useCallback(async () => {
    setLoadingFlag(true);
    const isEnabled = await featureFlagsApi.getBinaryFlag(
      VALIDATE_USER_MEMBERSHIP_REQUEST,
    );
    setIsUsingValidateUsermembershipRequest(isEnabled.data);
    setLoadingFlag(false);
  }, [featureFlagsApi]);

  useEffect(() => {
    getFeatureFlag();
  }, [getFeatureFlag]);

  return { isUsingValidateUsermembershipRequest, loadingFlag };
};

export default useValidateUserMembershipFlag;
