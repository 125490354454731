import React, { useState } from 'react';
import { useAsyncFn } from 'react-use';

import { Formik } from 'formik';

import { Link, Progress } from '@backstage/core-components';
import {
  errorApiRef,
  identityApiRef,
  microsoftAuthApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { useCompaniesApi, usePermissions } from '@internal/plugin-projects';
import { rootRouteRef as welcomeRouteRef } from '@internal/plugin-welcome-page';
import {
  BinaryFeatureFlagged,
  PageLayout,
  PageTitle,
  Tabs,
} from '@internal/sg-ui-kit';

import {
  getCompaniesList,
  getTechDocsLink,
  isValidUrl,
} from 'sg-utils-frontend';
import { PORTAL_PAGES_TITLES, URL_CUE } from 'usg-types';

import { projectApiRef } from '../../api';
import {
  PROJECT_MGMT_NO_MGMT,
  PROJECT_MGMT_TYPE_OWNERS,
  PROJECT_MGMT_TYPE_OWNERS_END_USERS,
} from '../../common/SGConstants';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { rootRouteRef } from '../../routes';
import { AlertModal } from '../AlertModal';
import { ROOT_URL_LABEL_BREADCRUMBS } from '../ViewEditResources/ViewEditResourcePage/types';
import { initialValues } from './initialValues';
import { ProjectRoleManagement } from './ProjectRoleManagement';
import { useNavigate, useParams } from 'react-router';
import useBreadcrumbsFlag from '../../hooks/useBreadcrumbsFlag';

import { useStyles } from './styles';

interface ViewEditProjectComponentProps {
  projectId: string;
}

export const ViewEditProjectComponent = ({
  projectId,
}: ViewEditProjectComponentProps) => {
  const classes = useStyles();
  const rootLink = useRouteRef(rootRouteRef);
  const welcomePageRoute = useRouteRef(welcomeRouteRef);
  const identityApi = useApi(identityApiRef);
  const [onEdit, setOnEdit] = useState(false);
  const [onEditSelfService, setOnEditSelfService] = useState(false);
  const [idTokenData, setIdTokenData] = useState([] as any);
  const [projectData, setProjectData] = useState({} as any);
  const [open, setOpen] = useState(false);
  const [managementType, setManagementType] = useState(false);

  const [selfServiceData, setSelfServiceData] = useState([] as any);
  const [activeTab, setActiveTab] = useState(0);
  const {
    allCompanies,
    isSuccess: allCompaniesSuccess,
    message: allCompaniesMessage,
    isLoading: isLoadingCompanies,
  } = useCompaniesApi();
  const [participantCompanies, setParticipantCompanies] = useState([] as any);
  const [ipOwnerCompanies, setIpOwnerCompanies] = useState([] as any);

  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();
  const [selfService, setSelfService] = React.useState(true);
  const [selfServiceEditSwitch, setSelfServiceEditSwitch] =
    React.useState(true);
  const {
    isAdmin,
    isProjectOwner,
    isLoading: isLoadingPermissions,
    error: permissionsError,
  } = usePermissions();
  const { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag } =
    useBreadcrumbsFlag();

  const handleChange = (event: any) => {
    setSelfServiceEditSwitch(event.target.checked);
  };
  const backToTarget = projectId ? `/projects/${projectId}` : rootLink();
  const backToLink = React.useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backToTarget],
  );

  const [{ value: data = [], loading, error }, fetchContent] =
    useAsyncFn(async (): Promise<any> => {
      const token = await authref.getIdToken();
      const params = {
        manipulators: ['participant_companies'],
      };
      setIdTokenData(token);

      let projectDataRes;
      try {
        projectDataRes = await projectApi.getProjectByID(
          projectId,
          token,
          params,
        );
        setProjectData(projectDataRes);
      } catch (err: any) {
        errorApi.post(new Error(`${err?.message}`));
      }
      return projectDataRes;
    }, []);

  const fetchSelfServiceData = async () => {
    try {
      const res: any = await projectApi.getOptOutData(parseInt(projectId, 10));
      if (Object.keys(res).length > 0) {
        setSelfServiceData(res);
        setSelfServiceEditSwitch(res?.enable_self_service);
        setSelfService(res?.enable_self_service);
      } else {
        setSelfServiceEditSwitch(true);
      }
    } catch (err: any) {
      errorApi.post(new Error(err?.message));
    }
  };

  React.useEffect(
    () => {
      fetchContent();
      fetchSelfServiceData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    try {
      if (
        data?.participant_companies &&
        data?.ip_owner_companies &&
        allCompanies
      ) {
        const participantsList = getCompaniesList(
          data?.participant_companies,
          allCompanies,
        );
        const ipOwnersList = getCompaniesList(
          data?.ip_owner_companies,
          allCompanies,
        );
        setParticipantCompanies(participantsList);
        setIpOwnerCompanies(ipOwnersList);
      }
    } catch (err) {
      errorApi.post(new Error(err?.message));
    }
  }, [data, allCompanies, errorApi]);

  if (loading || isLoadingPermissions || loadingBreadcrumbsFlag) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(error.message));
    navigate('/projects');
  }

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  if (!allCompaniesSuccess && !isLoadingCompanies) {
    return (
      <AlertModal
        alertMessage={allCompaniesMessage}
        isModalOpen={!allCompaniesSuccess}
        onClose={() => {
          navigate(welcomePageRoute());
        }}
      />
    );
  }

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (value: any) => {
    const editData = await projectApi.editById(projectId, {
      idToken: idTokenData,
      name: data?.name,
      description: value?.values?.project_description,
      management_type: value?.values?.management_type,
      owners: data?.owners,
      ip_owner_companies: data?.ip_owner_companies,
      participant_companies: data?.participant_companies,
    });
    const code = editData?.response?.status;
    if (code === 400 || code === 401 || code === 404 || code === 403) {
      const errorMsg = editData?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
    if (code === 200) {
      handleDialogOpen();
      setActiveTab(0);
      setOnEdit(false);
    }
  };

  const onCheckForm = (value: any) => {
    if (value?.values?.management_type === undefined || '') {
      setManagementType(true);
    } else {
      onSubmit(value);
    }
  };

  const handleSaveSelfServiceForm = async (value: any) => {
    const profile = await identityApi.getProfileInfo();
    const editData = (await projectApi.upsertOptOutData(
      parseInt(projectId, 10),
      {
        user_email: profile?.email,
        enable_self_service: selfServiceEditSwitch,
        url_link: value?.values?.link,
        instructions: value?.values?.access_instructions,
      },
    )) as any;
    if (editData) {
      handleDialogOpen();
      setActiveTab(1);
      setOnEditSelfService(false);
    }
  };

  const handleCancelEditHandler = () => {
    // upon cancel edit, reset edit form and switch to it's backend value
    setOnEditSelfService(false);
    setSelfServiceEditSwitch(selfService);
  };

  const handleDialogClose = () => {
    setOpen(false);
    fetchContent();
    fetchSelfServiceData();
  };

  const handleEditSelfService = () => {
    setOnEditSelfService(!onEditSelfService);
    fetchSelfServiceData();
  };

  const managementTypeData = (type: any) => {
    switch (type) {
      case PROJECT_MGMT_NO_MGMT:
        return 'No Management';
      case PROJECT_MGMT_TYPE_OWNERS:
        return 'Project Owners';
      case PROJECT_MGMT_TYPE_OWNERS_END_USERS:
        return 'Project Owners and End Users';
      default:
        return '';
    }
  };
  const projectDetailsTabs = [
    {
      label: 'PROJECT DETAILS',
      value: 'project_details',
      content: (
        <div>
          {!onEdit ? (
            <Grid container>
              <Grid item xs={6}>
                <>
                  <h4>{data?.name || null}</h4>
                  <p>{data?.description || null}</p>

                  <h4 style={{ paddingTop: '4%' }}>Management Type</h4>
                  <p style={{ fontSize: '90%' }}>
                    {managementTypeData(data?.management_type)}
                  </p>

                  <h4 style={{ paddingTop: '4%' }}>IP Owner Companies</h4>
                  <table
                    style={{ emptyCells: 'show' }}
                    data-testid="ip-owner-companies-tableview"
                  >
                    <tr style={{ textAlign: 'left' }}>
                      <th>Company Name</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th>Start Date</th>
                      <td>&nbsp;</td>

                      <th>End Date</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th>Percentage</th>
                    </tr>
                    {ipOwnerCompanies.map((res: any) => {
                      return (
                        <>
                          <tr style={{ textAlign: 'left' }}>
                            <p>{res.name}</p>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{ fontSize: '90%' }}>
                              {res.started_on
                                ? `${new Date(
                                    res?.started_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>
                            {res?.ended_on ? (
                              <td>- &nbsp; </td>
                            ) : (
                              <td> &nbsp; </td>
                            )}
                            <td style={{ fontSize: '90%' }}>
                              {res.ended_on
                                ? `${new Date(
                                    res?.ended_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>

                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>{`${res.percentage}%`}</td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                  <Grid
                    container
                    style={{ paddingTop: '4%', flexWrap: 'nowrap' }}
                    alignItems="center"
                  >
                    <h4>
                      Collaborating Companies (including subcontracting
                      companies)
                    </h4>

                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.tooltipArrow,
                      }}
                      arrow
                      placement="right"
                      title={
                        <div style={{ textAlign: 'center' }}>
                          All members of Stargate project workspaces must belong
                          to one of the workspace's registered Collaborating
                          Companies. If you need to add members from another
                          company,{' '}
                          <Link
                            className={classes.hyperlink}
                            target="_blank"
                            to={`${getTechDocsLink(
                              'add-collaborating-companies',
                            )}#add-collaborating-companies-to-project-workspace-procedure`}
                          >
                            please follow the instructions here to add
                            collaborating companies to your project workspace.
                          </Link>
                        </div>
                      }
                      interactive
                    >
                      <InfoOutlined
                        fontSize="small"
                        className={classes.iconColor}
                      />
                    </Tooltip>
                  </Grid>

                  <table
                    style={{ emptyCells: 'show' }}
                    data-testid="collaborating-companies-tableview"
                  >
                    <tr style={{ textAlign: 'left' }}>
                      <th>Company Name</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th>Start Date</th>
                      <td>&nbsp;</td>

                      <th>End Date</th>
                    </tr>
                    {participantCompanies.map((res: any) => {
                      return (
                        <>
                          <tr style={{ textAlign: 'left' }}>
                            <p>{res.name}</p>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{ fontSize: '90%' }}>
                              {res?.started_on
                                ? `${new Date(
                                    res?.started_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>
                            {res?.ended_on ? (
                              <td>- &nbsp; </td>
                            ) : (
                              <td> &nbsp; </td>
                            )}
                            <td style={{ fontSize: '90%' }}>
                              {res?.ended_on
                                ? `${new Date(
                                    res?.ended_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2} className={classes.editBtn}>
                {isAdmin || isProjectOwner(Number(projectId)) ? (
                  <Button
                    id="projects-button-details-edit"
                    data-testid="button-edit-project-details"
                    variant="contained"
                    onClick={() => setOnEdit(!onEdit)}
                  >
                    EDIT
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <div>
              <Formik
                initialValues={{
                  ...initialValues,
                  management_type: data?.management_type || '',
                  project_description: data?.description || '',
                }}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {formik => {
                  initialValues.project_description = data?.description as any;
                  initialValues.management_type = data?.management_type;
                  initialValues.IP_Owner_Companies = data?.ip_owner_companies;
                  initialValues.Collaborating_Companies =
                    data?.participant_companies;

                  return (
                    <>
                      <h4>{data?.name}</h4>
                      <InputLabel>Description</InputLabel>
                      <TextField
                        name="project_description"
                        type="text"
                        placeholder={data?.description}
                        onChange={formik.handleChange}
                        value={formik.values.project_description}
                        rows={3}
                        multiline
                        inputProps={{
                          maxLength: 200,
                        }}
                        style={{ width: '50%' }}
                        data-testid="textfield-project-description"
                      />
                      <FormHelperText>
                        Give a brief description of your project (max 200
                        characters)
                      </FormHelperText>
                      <div
                        className={
                          !isProjectOwner(Number(projectId))
                            ? classes.disableManagementType
                            : ''
                        }
                      >
                        <InputLabel style={{ paddingTop: '1%' }}>
                          Management Type*
                        </InputLabel>
                        <TextField
                          name="management_type"
                          select
                          onChange={formik.handleChange}
                          value={formik.values.management_type}
                          fullWidth
                          required
                          style={{ width: '50%' }}
                          onClick={() => setManagementType(false)}
                          error={managementType}
                          helperText={
                            managementType ? 'Provide the Management Type' : ''
                          }
                        >
                          <MenuItem
                            value=""
                            disabled
                            id="projects-menu-management-select"
                          >
                            Select
                          </MenuItem>
                          {formik.values.management_type ===
                          PROJECT_MGMT_NO_MGMT ? (
                            <MenuItem
                              value={PROJECT_MGMT_NO_MGMT}
                              id="projects-menu-management-entec"
                            >
                              EnTec managed
                            </MenuItem>
                          ) : (
                            ''
                          )}
                          <MenuItem
                            value={PROJECT_MGMT_TYPE_OWNERS}
                            id="projects-menu-management-owners"
                          >
                            Project Owners
                          </MenuItem>
                          <MenuItem
                            value={PROJECT_MGMT_TYPE_OWNERS_END_USERS}
                            id="projects-menu-management-owners-end-users"
                          >
                            Project Owners and End Users
                          </MenuItem>
                        </TextField>
                        <FormHelperText>
                          Select Management Type for the Project
                        </FormHelperText>
                        <FormHelperText>
                          <Link
                            className={classes.hyperlink}
                            to={getTechDocsLink('project-access')}
                            target="_blank"
                          >
                            Click here
                          </Link>{' '}
                          to know how management type effects your project
                          workspace.
                        </FormHelperText>
                      </div>
                      <Grid container spacing={2} style={{ paddingTop: '3%' }}>
                        <Grid item>
                          <Button
                            id="btn-cancel-edit-project"
                            variant="outlined"
                            onClick={() => {
                              setOnEdit(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            id="btn-submit-edit-project"
                            variant="contained"
                            type="submit"
                            onClick={() => onCheckForm(formik)}
                            data-testid="button-save"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      ),
    },
  ];

  const isSelfServicePresent =
    projectData?.management_type === PROJECT_MGMT_TYPE_OWNERS_END_USERS;

  if (isSelfServicePresent) {
    projectDetailsTabs.push({
      label: 'SELF SERVICE ACCESS',
      value: 'self_service_access',
      content: (
        <div>
          {!onEditSelfService ? (
            <Grid container>
              <Grid item xs={6}>
                <>
                  <h4>Self Service Access</h4>
                  <p style={{ fontSize: '90%' }}>
                    {selfService ? 'Enabled' : 'Disabled'}
                  </p>
                  {!selfService && (
                    <>
                      <h4 style={{ paddingTop: '4%' }}>Access Instructions</h4>
                      <p style={{ fontSize: '90%' }}>
                        {selfServiceData?.instructions}
                      </p>
                      <h4 style={{ paddingTop: '4%' }}>Link</h4>
                      <Link
                        rel="external"
                        to={`//${selfServiceData?.url_link}`}
                        target="_blank"
                        className={classes.link}
                      >
                        <span style={{ display: 'flex' }}>
                          {selfServiceData?.url_link}
                        </span>
                      </Link>
                    </>
                  )}
                </>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2} className={classes.editBtn}>
                {isAdmin || isProjectOwner(Number(projectId)) ? (
                  <Button
                    id="projects-button-self-service-edit"
                    variant="contained"
                    onClick={() => handleEditSelfService()}
                  >
                    EDIT
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <div className={classes.selfServiceDiv}>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {formik => {
                  initialValues.link = selfServiceData?.url_link;
                  initialValues.access_instructions =
                    selfServiceData?.instructions || '';
                  return (
                    <>
                      <InputLabel style={{ paddingTop: '1%' }}>
                        Self Service Access
                      </InputLabel>
                      <FormHelperText>
                        Allow users to request access to the project by enabling
                        this feature. If you choose to disable this feature;
                        provide access instructions to users.
                      </FormHelperText>
                      <Switch
                        checked={selfServiceEditSwitch}
                        onChange={handleChange}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        className={classes.inputLabel}
                      />
                      {selfServiceEditSwitch ? (
                        ''
                      ) : (
                        <>
                          <InputLabel className={classes.inputLabel}>
                            Access Instructions *
                          </InputLabel>
                          <TextField
                            name="access_instructions"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            value={formik.values.access_instructions}
                            error={
                              formik.values.access_instructions?.trim()
                                .length === 0
                            }
                            required
                            rows={3}
                            multiline
                            inputProps={{
                              maxLength: 200,
                            }}
                            style={{ width: '100%' }}
                          />
                          <FormHelperText className={classes.ownerLable}>
                            Provide instructions on how to request access to
                            this project.
                          </FormHelperText>
                          <InputLabel className={classes.inputLabel}>
                            Link *
                          </InputLabel>
                          <TextField
                            name="link"
                            type="text"
                            placeholder="https://www.example.com"
                            required
                            error={
                              formik.values.link === '' ||
                              !isValidUrl(formik.values.link)
                            }
                            onChange={formik.handleChange}
                            value={formik.values.link}
                            rows={3}
                            style={{ width: '100%' }}
                          />
                          <FormHelperText className={classes.ownerLable}>
                            {`Provide a link to request access to the project. ${URL_CUE}`}
                          </FormHelperText>
                        </>
                      )}
                      <Grid container spacing={2} style={{ paddingTop: '3%' }}>
                        <Grid item>
                          <Button
                            id="btn-cancel-edit-project"
                            variant="outlined"
                            onClick={handleCancelEditHandler}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            id="btn-submit-edit-project"
                            variant="contained"
                            type="submit"
                            onClick={() => handleSaveSelfServiceForm(formik)}
                            disabled={
                              !selfServiceEditSwitch &&
                              (formik.values.link === '' ||
                                formik.values.access_instructions?.trim()
                                  .length === 0 ||
                                !isValidUrl(formik.values.link))
                            }
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      ),
    });
  }

  projectDetailsTabs.push({
    label: 'ROLE MANAGEMENT',
    value: 'role_management',
    content: (
      <ProjectRoleManagement
        projectId={projectId}
        projectData={projectData}
        loading={loading}
        fetchContent={() => {
          fetchContent();
          setActiveTab(isSelfServicePresent ? 2 : 1);
        }}
      />
    ),
  });

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: data?.id,
      display: data?.name,
    },
    {
      path: `projectmanagement`,
      display: 'Project Details',
    },
  ];

  return (
    <PageLayout
      type="entity"
      title={data?.name || null}
      headerAdditionalControls={
        <ProjectOwners owners={projectData?.owners || null} />
      }
      backToLink={isBreadCrumbsFlagEnabled ? breadcrumbs : backToLink}
    >
      <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
        <PageTitle
          customPageTitle={`Project Details | ${data?.name} | Stargate`}
        />
      </BinaryFeatureFlagged>
      <div className={classes.container}>
        <Tabs tabs={projectDetailsTabs} initialActiveTabIndex={activeTab} />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="xs"
        style={{ top: '5' }}
        className={classes.dialog}
      >
        <DialogContent
          id="project-update-confirmed-dialog"
          style={{ fontWeight: 'bold' }}
        >
          Your project is being edited, please wait whilst we add it to your
          project workspace.
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            id="dialog-close-button"
            variant="contained"
            size="small"
            onClick={handleDialogClose}
            style={{ backgroundColor: 'black' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export const ViewEditProject = () => {
  const { projectId } = useParams() as { projectId: string };
  return <ViewEditProjectComponent projectId={projectId} />;
};
