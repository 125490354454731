import React, { useMemo } from 'react';

import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';

import { isDynamicGroup as isDynamicGroupFn } from 'sg-utils-frontend';

import { useStyles } from './styles';

type UserGroupSelectProps = {
  usergroup_id: string;
  handleResourceTypeChange: (event: any, i: number) => Promise<void>;
  userGroupIndex: number;
  userGroups: any;
};

export const UserGroupSelect = ({
  usergroup_id,
  handleResourceTypeChange,
  userGroupIndex,
  userGroups,
}: UserGroupSelectProps) => {
  const classes = useStyles();

  const userGroupSortedByName = useMemo(
    () =>
      [...userGroups].sort((a: any, b: any) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    [userGroups],
  );

  return (
    <FormControl variant="outlined" fullWidth>
      <Typography
        variant="button"
        component="div"
        style={{ marginBottom: '8px', textTransform: 'none' }}
        id="user-group-label"
      >
        User Group
      </Typography>
      <Select
        labelId="user-group-label"
        id="select-user-group"
        value={usergroup_id}
        name="usergroup_id"
        onChange={e => handleResourceTypeChange(e, userGroupIndex)}
        disabled={userGroups?.length === 0}
        displayEmpty
      >
        {userGroupSortedByName?.map((r: any) => (
          <MenuItem
            key={`ug_id_${r.id}`}
            value={r.id}
            title={r.name}
            className={classes.resourceName}
            data-isdynamicgroup={isDynamicGroupFn(r)}
          >
            {r.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
