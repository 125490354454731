import React from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { Link } from '@backstage/core-components';
import { getTechDocsLink } from 'sg-utils-frontend';
import { useStyles } from '../../../styles';

type DialogSuccessFailedProps = {
  isOpen: boolean;
  handleSuccessClose: () => void;
};

export const DialogSuccessAssignUserGroups = ({
  isOpen,
  handleSuccessClose,
}: DialogSuccessFailedProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleSuccessClose}
      maxWidth="xs"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogContent>
        <Typography variant="body2">
          <Box fontWeight={700}>
            Usergroup to resource assignment updated successfully. It can take
            up to 2 hours to synchronize fully.{' '}
            <Link
              className={classes.hyperLink}
              target="_blank"
              to={getTechDocsLink('dev-tool-sync')}
            >
              Learn more here
            </Link>
          </Box>
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          id="btn-assign-resource-modal-close"
          size="small"
          onClick={handleSuccessClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
