export enum DialogName {
  NONE,
  ASSIGN_USER_GROUPS,
  DETACH_USER_GROUP,
  UPDATE_USER_GROUP_ROLE,
}

export type UserGroup = {
  id: string;
  name: string;
  role: string;
  environment: string;
};

export type Environment = {
  id: number;
  environment: string;
  devToolId: number;
};

export type VaultTableProps = {
  resourceData: any;
  attachableUserGroups: Array<any>;
  selectedTabIndex: number;
  disableGroupListChanges?: boolean;
};

export type ResourceData = {
  id: string;
  linkedResources: Environment[];
};
