import React from 'react';

import { Grid } from '@material-ui/core';

import { ViewMoreLessButton } from './ViewMoreLessButton';
import { useStyles } from './styles';

const EMAIL_COLUMNS = 5;
const GRID_ITEM_VAL = 2;

type LongEmailListComponentProps = {
  changeDisplayType: () => void;
  emails: string[];
  isViewMore: boolean;
};

export const LongEmailListComponent = ({
  changeDisplayType,
  emails,
  isViewMore,
}: LongEmailListComponentProps) => {
  const classes = useStyles();

  const displayedEmails = isViewMore
    ? emails
    : emails.slice(0, EMAIL_COLUMNS - 1);

  return (
    <Grid container spacing={1}>
      {displayedEmails?.map((email: string) => (
        <Grid item xs={GRID_ITEM_VAL} className={classes.grid20}>
          <p className={classes.emailItem}> {email}</p>
        </Grid>
      ))}
      <ViewMoreLessButton
        isViewMore={isViewMore}
        changeDisplayType={changeDisplayType}
      />
    </Grid>
  );
};
