import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { projectApiRef } from '../../api';
import { useAsyncFn } from 'react-use';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  DialogTitle,
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import { TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX } from 'usg-types';
import { useStyles } from './styles';

export const UpdateResourceRole = (props: {
  isVisible: boolean;
  handleClose: any;
  handleSuccess: any;
  projectId: string;
  resourceId: number;
  resourceName: string;
  resourceRoleOptions: any;
  resourceRole: string;
  groupData: any;
}) => {
  const {
    isVisible,
    handleClose,
    handleSuccess,
    projectId,
    resourceId,
    resourceName,
    resourceRoleOptions,
    resourceRole,
    groupData,
  } = props;

  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const [selected, setSelected] = useState(resourceRole);
  const [updateError, setUpdateError] = useState('');
  const { t } = useTranslation();

  const [{ loading: reqLoading = false as boolean }, updateResourceRole] =
    useAsyncFn(async (rid, role, gid) => {
      const token = await authref.getIdToken();
      // new api
      const paramsdata = {
        idToken: token,
        userGroupRoles: [
          {
            op: 'add',
            value: [{ id: parseInt(gid, 10), role: role }],
          },
        ],
      };
      try {
        const res: any = await projectApi.assignUserGroupToResources(
          paramsdata,
          projectId,
          rid,
        );

        const code = res?.status;
        if (code === 200) {
          handleSuccess();
          handleClose();
        } else {
          setUpdateError(res?.data?.message);
        }
      } catch (e) {
        setUpdateError(e?.message);
      }
    }, []);

  useEffect(() => {
    setTimeout(() => {
      setUpdateError('');
    }, 1000);
  }, [isVisible]);

  useEffect(() => {
    setSelected(resourceRole);
  }, [resourceRole]);

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography variant="h3">
            {t(
              `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.updateRole.title`,
            )}
          </Typography>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              aria-label="close"
              className={classes.iconContainer}
              onClick={handleClose}
              id="projects-button-close-update-resource-role"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        {t(
          `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.updateRole.confirmationMessage`,
          {
            /* @ts-ignore */
            groupName: <b>“{groupData?.name}”</b>,
            resourceName: <b>“{resourceName}”</b>,
          },
        )}
        <FormControl variant="outlined" fullWidth>
          <Typography
            variant="button"
            component="div"
            style={{ marginTop: '16px', textTransform: 'none' }}
          >
            {t(
              `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.updateRole.roleFieldLabel`,
            )}
          </Typography>

          <RadioGroup row name="role" id="radio-group-update-resource-role">
            {resourceRoleOptions?.map((r: any) => (
              <FormControlLabel
                key={r?.id}
                value={r?.name}
                control={<Radio />}
                label={r?.display_name}
                checked={r?.name === selected}
                onChange={e => {
                  setSelected((e.target as HTMLInputElement).value);
                  setUpdateError('');
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {updateError && (
          <Typography
            style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
          >
            Error: {updateError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          id="modal-btn-role-cancel"
          variant="outlined"
          size="small"
          onClick={handleClose}
          disabled={reqLoading}
        >
          {t('common.form.cancelLabel')}
        </Button>
        <Button
          id="modal-btn-role-update"
          variant="contained"
          size="small"
          onClick={() =>
            updateResourceRole(resourceId, selected, groupData?.id)
          }
          disabled={reqLoading}
        >
          {t('common.form.updateLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
