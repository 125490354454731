import React from 'react';
import { GenricResourcePropsType } from '../Types';
import NewVaultViewEdit from './NewVault';
import { specificPropsType } from './types';

const VaultViewEdit = (
  props: React.PropsWithChildren<GenricResourcePropsType<specificPropsType>>,
) => {
  return <NewVaultViewEdit {...props} />;
};

export default VaultViewEdit;
