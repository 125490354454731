import React from 'react';
import useCustomDialogStyles from './styles';
import { CustomDialogMapperPropsType } from './types';
import {
  Button,
  DialogContent,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
} from 'usg-types';

export const CustomDialogMapper: React.FC<
  CustomDialogMapperPropsType
> = props => {
  const { selectedResource, handleDialogClose, isDialogOpen, rClass } = props;
  const classes = useCustomDialogStyles();

  const ASSIGN_USERGROUP_DIALOG = (
    <>
      <Dialog
        id="submitted-dialog"
        open={isDialogOpen}
        onClose={() => handleDialogClose('close')}
        maxWidth="xl"
        style={{ top: '5' }}
        className={classes.commonDialog}
        classes={{
          paperWidthXl: 'common-dialog-paper',
        }}
      >
        <DialogContent
          classes={{
            root: 'common-dialog-content',
          }}
        >
          {/* {artifactoryNameDirty && userRoleAndMessage.rClass !== 'local' ? artifactoryVirtualAndRemoteConditionCheck(artifactoryNameDirty,userRoleAndMessage.rClass, userRoleAndMessage.role, true) : `New resources request submitted, please wait whilst we add it to your
            project workspace.`} */}{' '}
          {/* //! -- 340 ticket code */}
          Your resource is being created. It may take a few minutes to be
          created. Please assign user groups now to avoid access issues with
          your resource.
        </DialogContent>
        <DialogActions className="common-dialog-actions">
          <Button
            variant="outlined"
            size="small"
            id="close-submitted-dialog-button"
            onClick={() => handleDialogClose('close')}
            style={{
              marginRight: '16px',
            }}
          >
            ASSIGN LATER
          </Button>
          <Button
            variant="contained"
            size="small"
            id="close-submitted-dialog-button-assign-user-groups"
            onClick={() => handleDialogClose('assign-user-groups')}
          >
            ASSIGN USER GROUPS
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const COMMON_DIALOG = (
    <>
      <Dialog
        id="submitted-dialog"
        open={isDialogOpen}
        onClose={() => handleDialogClose('close')}
        maxWidth="xl"
        style={{ top: '5' }}
        className={classes.commonDialog}
        classes={{
          paperWidthXl: 'common-dialog-paper',
        }}
      >
        <DialogContent
          classes={{
            root: 'common-dialog-content',
          }}
        >
          {/* {artifactoryNameDirty && userRoleAndMessage.rClass !== 'local' ? artifactoryVirtualAndRemoteConditionCheck(artifactoryNameDirty,userRoleAndMessage.rClass, userRoleAndMessage.role, true) : `New resources request submitted, please wait whilst we add it to your
          project workspace.`} */}{' '}
          {/* //! -- 340 ticket code */}
          Your resource is being created. It may take a few minutes to be
          created.
        </DialogContent>
        <DialogActions className={classes.commonDialogActions}>
          <Button
            variant="outlined"
            size="small"
            id="close-submitted-dialog-button"
            onClick={() => handleDialogClose('close')}
            style={{
              marginRight: '16px',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const isVirtualArtifactoryResource =
    (selectedResource === ARTIFACTORY_DEV_TOOL_ID.toString() ||
      selectedResource === ARTIFACTORY_SAAS_DEV_TOOL_ID.toString()) &&
    rClass === 'virtual';

  if (isVirtualArtifactoryResource) {
    return COMMON_DIALOG;
  }
  let result = COMMON_DIALOG;
  switch (selectedResource) {
    case VAULT_DEV_TOOL_ID.toString(): {
      result = ASSIGN_USERGROUP_DIALOG;
      break;
    }
    case GITHUB_DEV_TOOL_ID.toString():
    case JIRA_DEV_TOOL_ID.toString():
    case CONFLUENCE_DEV_TOOL_ID.toString():
    case ARTIFACTORY_DEV_TOOL_ID.toString():
    case ARTIFACTORY_SAAS_DEV_TOOL_ID.toString():
    case JAMA_DEV_TOOL_ID.toString():
    case GITHUBEMU_DEV_TOOL_ID.toString(): {
      result = ASSIGN_USERGROUP_DIALOG;
      break;
    }
    default:
      result = COMMON_DIALOG;
  }
  return result;
};
