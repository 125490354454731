enum order_by {
  'id',
  'name',
  'created_on',
  'updated_on',
}

enum order {
  'asc',
  'desc',
}
export interface GetProjectData {
  id: any;
  description: any;
  created_on: any;
  name?: string;
  owner_email?: string;
  ip_owner_company_id?: string;
  participant_company_id?: string;
  page?: string;
  size?: string;
  order_by?: order_by;
  order?: order;
}

export interface Role {
  role: string;
  expired_on?: string | undefined | null;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  deleted_on?: string;
  deleted_by?: string;
}

export interface DevToolData {
  id: number;
  name: string;
  description: string;
  enable_users_environment: boolean;
  default_role_id: number;
  roles: {
    id: number;
    name: string;
    display_name: string;
    description: string;
  }[];
  environments: {
    id: number;
    key: string;
    name: string;
    description: string;
  }[];
}

export interface DataItem {
  environment: 'prod' | 'stg' | 'dev'; // Strictly typing the environment field

  [key: string]: any;
}

// constants

export const GITHUB_DEV_TOOL_ID = 100;
export const JIRA_DEV_TOOL_ID = 101;
export const CONFLUENCE_DEV_TOOL_ID = 102;
export const ARTIFACTORY_DEV_TOOL_ID = 103;
export const MTFUJI_DEV_TOOL_ID = 104;
export const VAULT_DEV_TOOL_ID = 105;
export const JAMA_DEV_TOOL_ID = 106;
export const GITHUBEMU_DEV_TOOL_ID = 107;
export const ARTIFACTORY_SAAS_DEV_TOOL_ID = 108;

export const allowedTabs = ['user-groups', 'resources', 'members'];
export const allowedResourceStatuses = ['archived_resources', ''];
export const allowedGroupsType = ['archived_groups', 'attached_groups', ''];

// Define an enum for resources
export enum RESOURCES_ENUM {
  GITHUB_DEV_TOOL = 'GITHUB DEV TOOL',
  JIRA_DEV_TOOL = 'JIRA DEV TOOL',
  CONFLUENCE_DEV_TOOL = 'CONFLUENCE DEV TOOL',
  ARTIFACTORY_DEV_TOOL = 'ARTIFACTORY DEV TOOL',
  MTFUJI_DEV_TOOL = 'MTFUJI DEV TOOL',
  VAULT_DEV_TOOL = 'VAULT DEV TOOL',
  JAMA_DEV_TOOL = 'JAMA DEV TOOL',
  GITHUBEMU_DEV_TOOL = 'GITHUBEMU DEV TOOL',
  ARTIFACTORY_SAAS_DEV_TOOL = 'ARTIFACTORY SAAS DEV TOOL',
}

export enum RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT {
  GITHUB_DEV_TOOL = 'CreateGithubResource',
  JIRA_DEV_TOOL = 'CreateJiraResource',
  CONFLUENCE_DEV_TOOL = 'CreateConfluenceResource',
  ARTIFACTORY_DEV_TOOL = 'CreateArtifactoryResource',
  MTFUJI_DEV_TOOL = 'CreateMtFujiResource',
  VAULT_DEV_TOOL = 'CreateVaultResource',
  JAMA_DEV_TOOL = 'CreateJamaResource',
  GITHUBEMU_DEV_TOOL = 'CreateGithubEMUResource',
  ARTIFACTORY_SAAS_DEV_TOOL = 'CreateArtifactorySAASResource',
}

export const ADMIN = 'admin';
export const RO_ADMIN = 'ro_admin';
export const OWNER = 'owner';
export const GROUP_ROLE_MEMBER = 'member';
export const GROUP_ROLE_MANAGER = 'group_manager';
export const RESOURCE_ROLE_MANAGER = 'resource_manager';
export const GROUP_CREATOR = 'group_creator';
export const RESOURCE_CREATOR = 'resource_creator';
export const RESOURCE_MANAGERS = 'resource_manager';
export const ALL_ROLES = [
  ADMIN,
  RO_ADMIN,
  OWNER,
  GROUP_ROLE_MANAGER,
  GROUP_ROLE_MEMBER,
  RESOURCE_MANAGERS,
  RESOURCE_CREATOR,
  GROUP_CREATOR,
];
export const ADMIN_OWNER_ROLES = [ADMIN, RO_ADMIN, OWNER];
export const ADMIN_ROLES = [ADMIN, RO_ADMIN];
export const BUSINESS_JUSTIFICATION_DESCRIPTION_MAX_LENGTH = 30;
export const RESOURCE_NAME_MAX_LENGTH = {
  github: 39,
  confluence: 200,
  jira: 80,
  jama: 255,
  artifactory: 64,
  vault: 252,
};
export const RESOURCE_NAME_MIN_LENGTH = {
  github: 3,
  confluence: 3,
  jira: 3,
  jama: 3,
  artifactory: 3,
  vault: 3,
};

export const RESOURCE_KEY_MAX_LENGTH = { confluence: 50, jira: 10, jama: 16 };
export const RESOURCE_KEY_MIN_LENGTH = {
  confluence: 2,
  jira: 2,
  jama: 2,
};

export const RESOURCE_DESCRIPTION_MAX_LENGTH = 2000;
export const GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH = 160;
export const ALLOW_SHARING_OPTION_TWO = 2;
// Regex which allows letters, numbers and spaces but doesn't allow spaces at start and end of input
export const RESOURCE_NAME_REGEX = /^(?!\s)[A-Za-z0-9\s]*(?<!\s)$/;
export const SPECIAL_RESOURCE_NAME_REGEX = {
  jama: /^(?![.\s])[A-Za-z0-9.\-_\s]*(?<![.\s])$/,
  artifactory:
    /^(?![0-9])^(?!.*-[Cc][Aa][Cc][Hh][Ee]$)[A-Za-z0-9]+(-[A-Za-z0-9]+)*$/,
  github: /^([A-Za-z0-9]+-)*[A-Za-z0-9]+$/,
};

export const CONTRACT_NAME_MIN_LENGTH = 2;
export const CONTRACT_NAME_MAX_LENGTH = 256;
export const DEFAULT_DEBOUNCE_TIME = 800;
export const SEARCH_INPUT_MAX_LENGTH = 100;

export const DEVTOOL_ID_MAPPINGS = [
  {
    id: 100,
    name: 'GitHub Enterprise',
  },
  {
    id: 101,
    name: 'Jira',
  },
  {
    id: 102,
    name: 'Confluence',
  },
  {
    id: 103,
    name: 'Artifactory (Self-hosted)',
  },
  {
    id: 104,
    name: 'Stargate Multicloud',
  },
  {
    id: 105,
    name: 'Vault',
  },
  {
    id: 106,
    name: 'Jama',
  },
  {
    id: 107,
    name: 'GitHub (EMU)',
  },
  {
    id: 108,
    name: 'Artifactory (SaaS)',
  },
];

export const RESOURCE_ENABLED_DEVTOOLS = [
  ARTIFACTORY_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
];

export const DEV_TOOLS_TAG_MAP: any = {
  [GITHUBEMU_DEV_TOOL_ID]: {
    excluded: ['artifactorysaas', 'confluence', 'jira', 'jama', 'vault', 'smc'],
    allowed: 'githubemu',
  },
  [ARTIFACTORY_SAAS_DEV_TOOL_ID]: {
    excluded: ['githubemu', 'confluence', 'jira', 'jama', 'vault', 'smc'],
    allowed: 'artifactorysaas',
  },
  [CONFLUENCE_DEV_TOOL_ID]: {
    excluded: ['githubemu', 'artifactorysaas', 'jira', 'jama', 'vault', 'smc'],
    allowed: 'confluence',
  },
  [JIRA_DEV_TOOL_ID]: {
    excluded: [
      'githubemu',
      'artifactorysaas',
      'confluence',
      'jama',
      'vault',
      'smc',
    ],
    allowed: 'jira',
  },
  [JAMA_DEV_TOOL_ID]: {
    excluded: [
      'githubemu',
      'artifactorysaas',
      'confluence',
      'jira',
      'vault',
      'smc',
    ],
    allowed: 'jama',
  },
  [VAULT_DEV_TOOL_ID]: {
    excluded: [
      'githubemu',
      'artifactorysaas',
      'confluence',
      'jira',
      'jama',
      'smc',
    ],
    allowed: 'vault',
  },
  [MTFUJI_DEV_TOOL_ID]: {
    excluded: [
      'githubemu',
      'artifactorysaas',
      'confluence',
      'jira',
      'jama',
      'vault',
    ],
    allowed: 'smc',
  },
  default: {
    excluded: [
      'githubemu',
      'artifactorysaas',
      'confluence',
      'jira',
      'jama',
      'vault',
      'smc',
    ],
    allowed: '',
  },
};
