import project from './ja/project-ja.json';
import resource from './ja/resource-ja.json';
import common from './ja/common-ja.json';
import usergroups from './ja/usergroups-ja.json';

export const ja = {
  project,
  resource,
  common,
  usergroups,
};
