import React from 'react';
import { useAsync } from 'react-use';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/NavigateNext';
import Alert from '@material-ui/lab/Alert';
import { Link } from '@internal/sg-ui-kit';

import { InfoCard, Link as BackstageLink } from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { useTranslationRef } from '@backstage/core-plugin-api/alpha';

import { formatDate } from 'sg-utils-frontend';
import { NewsMetadata } from 'usg-types';

import { newsPageRouteRef } from '../../routes';
import { newsApiRef } from '../../api';
import { newsTranslationRef } from '../../translation';
import { useStyles } from './styles';

export const NewsWidget = () => {
  const classes = useStyles();
  const newsApi = useApi(newsApiRef);
  const getNewsPageRoute = useRouteRef(newsPageRouteRef);
  const { t } = useTranslationRef(newsTranslationRef);
  const translationPath = 'common.newswidget';

  // To get all list of news in home page
  const {
    value: newsList,
    loading: isLoading,
    error,
  } = useAsync(async (): Promise<any> => {
    const news = await newsApi.getNewsMetadata();
    return news.slice(0, 4);
  }, []);

  const header = React.useMemo(
    () => (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">{t(`${translationPath}.title`)}</Typography>
        </Grid>
        <Grid item>
          <Link
            to={getNewsPageRoute()}
            icon={<ArrowIcon className={classes.arrowIcon} />}
            spacing={0}
          >
            <Typography variant="button" component="div">
              {t(`${translationPath}.viewAll`)}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <InfoCard title={header} className={classes.container}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container direction="column" spacing={2}>
          {newsList.map((item: NewsMetadata) => (
            <Grid item className={classes.articleContent} key={item.id}>
              <BackstageLink
                to={`${getNewsPageRoute()}?${new URLSearchParams({
                  id: item.id,
                })}`}
                color="inherit"
                underline="none"
                key={item.id}
                className={classes.linkContainer}
              >
                <Typography variant="caption" className={classes.articleDate}>
                  {formatDate(item.date, false)}
                </Typography>
                <Typography
                  className={classes.articleTitle}
                  variant="body1"
                  noWrap
                >
                  {item.title}
                </Typography>
                <Typography noWrap variant="body1">
                  {item.description}
                </Typography>
              </BackstageLink>
            </Grid>
          ))}
        </Grid>
      )}
    </InfoCard>
  );
};
