import {
  useAnalytics,
  AnalyticsEventAttributes,
} from '@backstage/core-plugin-api';

type AnalyticsOption = {
  value?: number;
  attributes?: AnalyticsEventAttributes;
};

export const useGoogleAnalytics = () => {
  const analytics = useAnalytics();

  const captureGoogleAnalyticEvent = (
    action: string,
    subject: string,
    option: AnalyticsOption = {},
  ) => {
    analytics.captureEvent(action, subject, option);
  };

  return { captureGoogleAnalyticEvent };
};
