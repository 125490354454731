import React, { useState } from 'react';
import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { SGEmailChipInput } from '@internal/sg-ui-kit';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import { Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Field } from 'formik';
import Typography from '@mui/material/Typography';
import { EMAIL_REGEX, INVALID_EMAIL_ERROR } from 'usg-types';
import { projectApiRef } from '../../../../../../api';
import { useStyles } from './styles';

export type VaultResourceManagersProps = {
  projectId: string;
  editMode: boolean;
  resourceManagerData: any[];
  setIsAddUserLoading: any;
  setIsAddUserEnable: any;
  isAddUserEnable: boolean;
};

export const VaultResourceManagers = ({
  projectId,
  editMode,
  resourceManagerData,
  setIsAddUserEnable,
  isAddUserEnable,
  setIsAddUserLoading,
}: VaultResourceManagersProps) => {
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const [isViewMore, setIsViewMore] = useState(false);
  const emailColumns = 5;
  const gridItemVal = 3;
  const checkUserValidity = async (email: string) => {
    try {
      const token = await authref.getIdToken();
      const isValidRes = await projectApi.checkIfUserIsValid(
        projectId,
        email,
        token,
      );
      return isResponseStatus2XX(isValidRes);
    } catch (e) {
      return false;
    }
  };

  const checkAddUserValidity = async (email: string) => {
    const isAddUserValid = await checkUserValidity(email);
    setIsAddUserEnable(isAddUserValid && isAddUserEnable);

    return isAddUserValid;
  };
  const moreEmails: any[] = [];
  if (resourceManagerData?.length > emailColumns) {
    for (let i = 0; i < emailColumns - 1; i++) {
      moreEmails.push(resourceManagerData[i]);
    }
  }
  const ResourceManagerFieldView = () => {
    return (
      <Grid container className={classes.resourceManagersNonEditModeContainer}>
        <Grid item xs={12} className={classes.resourceManagerHeadingContainer}>
          <h4 className={classes.labelHeader}>Resource Managers</h4>
        </Grid>
        <Grid item xs={12}>
          {resourceManagerData?.length === 0 && (
            <p className={classes.noManagers}>Not Specified</p>
          )}
          {resourceManagerData?.length <= emailColumns && (
            <Grid container spacing={2} className={classes.mailsContainer}>
              {resourceManagerData?.map((res: any) => {
                return (
                  <Grid item xs={gridItemVal} className={classes.grid20}>
                    <p className={classes.emailItem}> {res.user_email}</p>
                  </Grid>
                );
              })}
            </Grid>
          )}

          {resourceManagerData?.length > emailColumns && (
            <Grid container spacing={2}>
              {!isViewMore &&
                moreEmails?.map((res: any) => {
                  return (
                    <Grid item xs={gridItemVal} className={classes.grid20}>
                      <p className={classes.emailItem}> {res.user_email}</p>
                    </Grid>
                  );
                })}

              {isViewMore &&
                resourceManagerData?.map((res: any) => {
                  return (
                    <Grid item xs={gridItemVal} className={classes.grid20}>
                      <p className={classes.emailItem}> {res.user_email}</p>
                    </Grid>
                  );
                })}
              <Grid item xs={gridItemVal} className={classes.grid20}>
                {!isViewMore && (
                  <Button
                    id="project-button-viewmore"
                    variant="text"
                    onClick={() => setIsViewMore(true)}
                  >
                    <Typography
                      variant="button"
                      color="primary"
                      sx={{
                        fontSize: '12px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      View more
                    </Typography>
                  </Button>
                )}
                {isViewMore && (
                  <Button
                    id="project-button-viewless"
                    variant="text"
                    onClick={() => setIsViewMore(false)}
                  >
                    <Typography
                      variant="button"
                      color="primary"
                      sx={{
                        fontSize: '12px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      View less
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  if (editMode) {
    return (
      <Grid container className={classes.resourceManagersEditModeContainer}>
        <Grid item xs={12} className={classes.resourceManagerHeadingContainer}>
          <h4 className={classes.labelHeader}>Resource Manager(s)</h4>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="addResourceManagers"
            data-testid="resource-managers-field"
            className={classes.resourceManagerGrid}
            component={SGEmailChipInput}
            required
            variant="outlined"
            patternMatch={EMAIL_REGEX}
            helperText="Enter user emails to assign resource managers. Press
                enter or space key to add email id to the list. Press
                ‘Save’ to save the changes."
            errorText={INVALID_EMAIL_ERROR}
            checkValidity={checkAddUserValidity}
            handleDeleteCallBack={(isError: boolean) => {
              setIsAddUserEnable(!isError);
            }}
            handleBeforeAddCallBack={(isError: boolean) => {
              setIsAddUserEnable(!isError);
            }}
            handleLoadingCallBack={(addUserloading: boolean) => {
              setIsAddUserLoading(addUserloading);
            }}
            handleBlurInputCallBack={(isError: boolean) => {
              setIsAddUserEnable(!isError);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return <ResourceManagerFieldView />;
};
