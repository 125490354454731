import React from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { Content } from '../Content';
import { BackToLink, NavigationLinkComponent } from './components/Navigation';
import { PathLink } from '../SGBreadcrumbs';
import { useStyles } from './styles';

interface HeaderProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  additionalControls?: JSX.Element;
  extendedContent?: JSX.Element;
  backgroundImg?: string;
  backToLink?: BackToLink | PathLink[];
}

export const CatalogPageHeader = ({
  title,
  subtitle,
  additionalControls,
  extendedContent,
  backgroundImg,
  backToLink,
}: HeaderProps) => {
  const classes = useStyles();

  const backgroundStyle = React.useMemo(
    () =>
      backgroundImg
        ? {
            backgroundImage: `linear-gradient(to left, rgba(145, 185, 250, 1) -14%, rgba(0, 0, 0, 0.76) 78%),url(${backgroundImg})`,
          }
        : {},
    [backgroundImg],
  );
  return (
    <header
      className={`${classes.container} ${classes['container--catalog']}`}
      style={backgroundStyle}
    >
      <Content className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item container direction="column" spacing={3}>
            {backToLink && (
              <Grid item>
                <NavigationLinkComponent
                  backToLink={backToLink}
                  isLightBackground={false}
                />
              </Grid>
            )}
            <Grid item>
              <Typography className={classes.title} variant="h1">
                {title}
              </Typography>
              {subtitle && (
                <Typography className={classes.subtitle} variant="subtitle1">
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.additionalControls}>
            {additionalControls}
          </Grid>
        </Grid>
      </Content>
      {extendedContent && (
        <>
          <Box mx={5}>
            <Divider light />
          </Box>
          <Content className={classes.extendedContent}>
            {extendedContent}
          </Content>
        </>
      )}
    </header>
  );
};
