import React from 'react';
import { Chip, ChipProps, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useStyles } from './styles';

interface TextProps {
  text: string;
  id: string;
}
interface ActionProps {
  label: string;
  onClick: () => void;
  props?: object;
  visible?: boolean;
}

interface RowItemIProps {
  title: TextProps;
  secondaryText?: TextProps;
  actions?: Array<ActionProps>;
  linkTo?: string;
  tags?: Array<ChipProps>;
}

export const SGRowItem = (props: RowItemIProps) => {
  const { title, secondaryText, actions, linkTo, tags } = props;
  const classes = useStyles();

  return (
    <div className={classes.rowDiv}>
      {linkTo ? (
        <Link
          to={linkTo}
          className={classes.resourceEnvLink}
          title={title.text}
        >
          <Typography id={title.id} noWrap>
            {title.text}
          </Typography>
        </Link>
      ) : (
        <Typography id={title.id} noWrap>
          {title.text}
        </Typography>
      )}
      {tags && (
        <div className={classes.rowSection}>
          {tags?.map(tag => (
            <Chip {...tag} key={`${title.text}-${tag.label}`} />
          ))}
        </div>
      )}

      <div className={classes.rowSection}>
        {secondaryText && (
          <Typography
            variant="caption"
            id={secondaryText.id}
            className={classes.secondaryText}
          >
            {secondaryText.text}
          </Typography>
        )}

        {actions?.map((action, index) => {
          if (action.visible !== false) {
            return (
              <button
                className={classes.actionBtn}
                {...action.props}
                onClick={action.onClick}
                key={`action-btn-${index}`}
              >
                {action.label}
              </button>
            );
          }
          return false;
        })}
      </div>
    </div>
  );
};
