import { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { createGlobalState } from 'react-use';
import { DateTime } from 'luxon';

const useServiceAccount = createGlobalState<{
  token: string;
  expire_by: DateTime | null;
}>({ token: '', expire_by: null });

export function useServiceAccountApi() {
  const projectApi = useApi(projectApiRef);

  const [accountToken, setAccountToken] = useServiceAccount();
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const loginWithServiceAccount = useCallback(async () => {
    const serviceAuthToken: any = await projectApi.getServiceAuthToken();
    if (serviceAuthToken?.status !== 200) {
      throw new Error(
        `Status Code ${serviceAuthToken?.status}. Failed to fetch get service auth token api: ${serviceAuthToken?.data?.message}`,
      );
    }
    return serviceAuthToken?.data;
  }, [projectApi]);

  const getServiceToken = useCallback(async () => {
    try {
      const account = await loginWithServiceAccount();
      const expireDate = DateTime.now().plus({
        seconds: 60,
      });

      setAccountToken({
        token: account.access_token,
        expire_by: expireDate,
      });
      setIsSuccess(true);
    } catch (err) {
      setIsSuccess(false);
      setMessage(err?.message);
      // we dont want to keep reusing the same token if it fails to fetch a new one
      setAccountToken({
        token: '',
        expire_by: null,
      });
    } finally {
      setIsLoading(false);
    }
  }, [setAccountToken, loginWithServiceAccount]);

  useEffect(() => {
    if (
      accountToken?.token === '' ||
      (accountToken?.expire_by && accountToken?.expire_by < DateTime.now())
    ) {
      setIsLoading(true);
      (async () => {
        await getServiceToken();
      })();
    } else {
      setIsLoading(false);
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { accountToken, isSuccess, message, isLoading, getServiceToken };
}
