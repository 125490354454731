import React from 'react';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { projectApiRef } from '../../../../api';
import { DialogArchiveResourcesProps } from '../types';
import { useStyles } from '../../styles';

export const DialogArchiveResource = ({
  projectId,
  navigate,
  resourceId,
  idTokenData,
  openArcive,
  setOpenArchive,
}: DialogArchiveResourcesProps) => {
  const classes = useStyles();

  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const onHandleArchive = async () => {
    const data: any = await projectApi.archiveProjectResource(
      projectId,
      resourceId,
      {
        idToken: idTokenData,
      },
    );
    const code = data?.response?.status;
    if (code === 403 || code === 401 || code === 404) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
    navigate(`/projects/${projectId}`);
  };

  // Calling the delete user group api
  const onCloseArchiveDialog = () => {
    onHandleArchive();
  };

  return (
    <Dialog
      open={openArcive}
      onClose={() => setOpenArchive(!openArcive)}
      maxWidth="xs"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogContent
        id="resource-archive-confirmation-dialog"
        style={{ fontWeight: 'bold' }}
      >
        Are you sure you want to archive this resource?
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          id="project-button-cancelarchive"
          variant="outlined"
          size="small"
          onClick={() => setOpenArchive(!openArcive)}
        >
          cancel
        </Button>
        <Button
          id="dialog-resource-archive-button"
          variant="contained"
          size="small"
          onClick={onCloseArchiveDialog}
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};
