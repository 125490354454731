import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  // row item
  actionBtn: {
    background: 'transparent',
    color: theme.palette.usgColors.primary.blue60,
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    marginLeft: '40px',
    fontFamily: 'ToyotaType',
  },
  resourceEnvLink: {
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 400,
    width: '50%',
  },
  rowDiv: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.usgColors.others.mix.grey[4],
    borderRadius: '4px',
    padding: '8px 24px 8px 16px',
    justifyContent: 'space-between',
    margin: '16px 0px 16px',
    alignItems: 'center',
    width: '100%',
    height: '50px',
  },
  rowSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  secondaryText: {
    fontSize: '12px',
    textTransform: 'capitalize',
    fontWeight: 'normal',
  },
  hyperlink: {
    color: theme.palette.usgColors.others.mix.blue[200],
    textDecoration: 'underline',
  },
}));
