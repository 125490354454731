import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { Progress } from '@backstage/core-components';

import useProjectHook from './hooks/useProjectHook';
import { NewVaultPageComponent } from './NewVaultPage';
import useVaultReducedApiCalls from './hooks/useVaultReducedApiCalls';
import { NewViewEditResourceComponent } from './NewViewEditResourceComponent';
import { VAULT_DEV_TOOL_ID } from 'usg-types';
import useBreadcrumbsFlag from '../../../hooks/useBreadcrumbsFlag';

export const ViewEditResourcePage = () => {
  const { projectId } = useParams() as { projectId: string };
  const { resourceId } = useParams() as { resourceId: string };

  const { isVaultReducedCallEnabled, loadingVaultReducedCallFlag } =
    useVaultReducedApiCalls();
  const projectDetails = useProjectHook(projectId, resourceId);
  const navigate = useNavigate();

  const { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag } =
    useBreadcrumbsFlag();

  if (
    loadingVaultReducedCallFlag ||
    loadingBreadcrumbsFlag ||
    projectDetails.data === undefined
  ) {
    return <Progress />;
  }

  if (projectDetails.data?.resources.length === 0) {
    // cannot get project data, return back to project
    navigate(`/projects/${projectId}`);
  }

  // if it is vault AND the reduced calls for vault is enabled
  if (
    isVaultReducedCallEnabled &&
    projectDetails.data?.resources[0].dev_tool_id === VAULT_DEV_TOOL_ID
  ) {
    return (
      <NewVaultPageComponent
        projectData={projectDetails.data}
        isBreadCrumbsFlagEnabled={isBreadCrumbsFlagEnabled ?? false}
      />
    );
  }

  return (
    <NewViewEditResourceComponent
      projectId={projectId}
      resourceId={resourceId}
      projectData={projectDetails.data}
      isBreadCrumbsFlagEnabled={isBreadCrumbsFlagEnabled ?? false}
    />
  );
};
