import { createContext, useContext } from 'react';
import { ViewEditResourceData } from './types';

export const ViewEditResourceContext =
  createContext<ViewEditResourceData | null>(null);

export function useViewEditResourceContext() {
  const viewEditResourceContext = useContext(ViewEditResourceContext);

  if (viewEditResourceContext === null) {
    throw new Error(
      'useViewEditResourceContext must be used with ViewEditResourceContext',
    );
  }

  return viewEditResourceContext;
}
