import React from 'react';

import { Button, DialogActions } from '@material-ui/core';

import { TUserGroupAttachment, UserGroupAttachmentRequest } from './types';

import { useStyles } from './styles';

type AttachGroupActionsProps = {
  isLoadingUserGroup: boolean;
  handleClose: () => void;
  isAssignResourceDisable: boolean;
  userGroupAttachments: TUserGroupAttachment[];
  attachResources: (value: UserGroupAttachmentRequest[]) => void;
};

export const AttachGroupActions = ({
  isLoadingUserGroup,
  handleClose,
  isAssignResourceDisable,
  userGroupAttachments,
  attachResources,
}: AttachGroupActionsProps) => {
  const classes = useStyles();

  const attachUserGroupsToResource = () => {
    const accessible_usergroups = userGroupAttachments.map(
      ({ usergroup_id, role }) => {
        return {
          id: usergroup_id,
          role,
        };
      },
    );
    attachResources(accessible_usergroups);
  };

  return (
    <DialogActions className={classes.dialogActions}>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClose}
        disabled={isLoadingUserGroup}
      >
        CANCEL
      </Button>
      <Button
        variant="contained"
        size="small"
        onClick={attachUserGroupsToResource}
        disabled={isAssignResourceDisable}
      >
        ASSIGN User group
      </Button>
    </DialogActions>
  );
};
