import React from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { Content } from '../Content';
import { BackToLink, NavigationLinkComponent } from './components/Navigation';
import { PathLink } from '../SGBreadcrumbs';

import { useStyles } from './styles';

interface HeaderProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  additionalControls?: JSX.Element;
  extendedContent?: JSX.Element;
  backToLink?: BackToLink | PathLink[];
}

export const EntityPageHeader = ({
  title,
  subtitle,
  additionalControls,
  extendedContent,
  backToLink,
}: HeaderProps) => {
  const classes = useStyles();

  return (
    <header className={classes.container}>
      <Content className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item container direction="column" spacing={0}>
            {backToLink && (
              <Grid item>
                <NavigationLinkComponent
                  backToLink={backToLink}
                  isLightBackground
                />
              </Grid>
            )}
            <Grid item>
              <Typography id="page-title" variant="h1">
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  id="page-subtitle"
                  className={classes.subtitle}
                  variant="subtitle1"
                >
                  {subtitle}
                </Typography>
              )}
            </Grid>
            {extendedContent && (
              <Grid item className={classes.extendedContent}>
                {extendedContent}
              </Grid>
            )}
          </Grid>
          <Grid item>{additionalControls}</Grid>
        </Grid>
      </Content>
      <Box mx={5}>
        <Divider />
      </Box>
    </header>
  );
};
