import React, { useEffect, useState } from 'react';
import {
  AlertNotification,
  BinaryFeatureFlagged,
  PageLayoutWithTabs,
  PageTitle,
} from '@internal/sg-ui-kit';

import {
  identityApiRef,
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { Grid, Typography, Button, Tooltip } from '@material-ui/core';
import { InfoCard, Avatar } from '@backstage/core-components';
import { UserNotificationSettingsCard } from '@internal/backstage-plugin-notifications';
import { projectApiRef } from '../../api';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ProfileProjectTable } from './ProfileProjectTable';
import { useAsyncFn } from 'react-use';
import { Progress } from '@backstage/core-components';
import { UserRoleList } from '../UserRoleList';
import { getRoles } from '@internal/sg-utils-common';
import {
  PORTAL_PAGES_TITLES,
  LANGUAGE_SWITCHER,
  NOTIFICATIONS,
  Role,
} from 'usg-types';
import { AchievementsPanel } from '../AchievementsPanel';
import { UserSettingsLanguageToggle } from './user-settings/UserSettingsLanguageToggle';
import { SidebarItemTag } from '@internal/sg-ui-kit';
import Stack from '@mui/material/Stack';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { useStyles } from './styles';

export function ProfilePageComponent() {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  const [profileData, setProfileData] = useState({} as any);
  const [userData, setUserData] = useState({} as any);
  const [token, setToken] = useState('');
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');
  const [roles, setRoles] = useState<string[]>([]);
  const [coreDBRoles, setCoreDBRoles] = useState<Role[]>([]);
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const [settingsEnabled, setSettingsEnabled] = useState(false);

  const handleUserRole = (user: any) => {
    const adminRoles = getRoles(user?.roles);
    setRoles(adminRoles);
  };

  const [{ loading, error }, fetchContent] = useAsyncFn(async () => {
    const profile = await identityApi.getProfileInfo();
    setProfileData(profile);

    const idToken = await authref.getIdToken();
    const params = {
      idToken: idToken,
    };
    const queryParams = {
      include: 'all',
    };
    setToken(idToken);

    try {
      const data: any = await projectApi.getUserDetails(params, queryParams);
      const resData = data?.response?.data;
      setUserData(resData);
      handleUserRole(resData);
    } catch (err) {
      const pError = err?.message;
      errorApi.post(new Error(`${pError}`));
    }
  }, []);

  const fetchDBRoles = async () => {
    try {
      setRolesLoading(true);
      const idToken = await authref.getIdToken();
      setToken(idToken);
      const res: any = await projectApi.getUserRoles(idToken);
      const nonDeletedRoles = res?.data?.roles.filter(
        (role: Role) => !role?.deleted_on,
      );
      setCoreDBRoles(nonDeletedRoles);
      setRolesLoading(false);
    } catch (err: any) {
      errorApi.post(new Error(err?.message));
      setRolesLoading(false);
    }
  };

  const process = async () => {
    await fetchDBRoles();
    await fetchContent();
  };
  useEffect(() => {
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const isNotificationEnabled = await featureFlagsApi.getBinaryFlag(
        NOTIFICATIONS,
      );
      const isLanguageSwitchEnabled = await featureFlagsApi.getBinaryFlag(
        LANGUAGE_SWITCHER,
      );
      setSettingsEnabled(
        isNotificationEnabled?.data || isLanguageSwitchEnabled?.data,
      );
    })();
  }, [featureFlagsApi]);

  if (loading || rolesLoading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  return (
    <PageLayoutWithTabs
      title={`Welcome, ${profileData?.displayName}`}
      subtitle="Your Stargate profile"
      headerBackgroundImg="/assets/welcome_bg.webp"
    >
      <PageLayoutWithTabs.Route path="/" title="Profile">
        <>
          <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
            <PageTitle
              customPageTitle={`${profileData?.displayName} | Profile | Stargate`}
            />
          </BinaryFeatureFlagged>
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InfoCard
                  variant="gridItem"
                  title="Profile"
                  className={classes.profileCard}
                >
                  <Grid item xs={12} md={12} lg={3}>
                    <Avatar
                      picture={profileData?.picture}
                      classes={{
                        avatarText: classes.avatarText,
                      }}
                      displayName={profileData?.displayName}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={9}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.displayName}
                      noWrap
                    >
                      {profileData?.displayName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.email}
                      noWrap
                    >
                      {profileData?.email}
                    </Typography>
                    {userData?.roles && (
                      <UserRoleList
                        curentUserEmail={profileData?.email}
                        roleList={coreDBRoles}
                        maxRolesVisible={2}
                      />
                    )}
                  </Grid>
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={8}>
                <InfoCard variant="gridItem" title="Authorization Token">
                  <div>
                    <div className={classes.copyToken}>
                      <div className={classes.tokenDiv}>
                        <Typography className={classes.token} id="id-token">
                          {token}
                        </Typography>
                      </div>
                      <Button
                        variant="outlined"
                        id="profile-button-copy-token"
                        style={{ marginLeft: '24px' }}
                        onClick={() => {
                          navigator.clipboard.writeText(token);
                          setCopyButtonLabel('COPIED');
                          setTimeout(() => {
                            setCopyButtonLabel('Copy');
                          }, 2000);
                        }}
                        disabled={copyButtonLabel === 'COPIED'}
                      >
                        {copyButtonLabel}
                        <FileCopyIcon
                          style={{ marginLeft: '8px' }}
                          fontSize="small"
                        />
                      </Button>
                    </div>
                  </div>
                  <AlertNotification
                    severity="warning"
                    alertMessage="Use at your own risk for experimentation or testing purposes.
                      Please talk to the Stargate team before using it in production"
                    alertTitle="Attention!"
                  />
                </InfoCard>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InfoCard variant="gridItem" title="Achievements">
                  <AchievementsPanel />
                </InfoCard>
              </Grid>
            </Grid>

            <ProfileProjectTable
              data={userData?.projects?.filter((p: any) => !p.is_archived)}
              roles={roles}
            />
          </div>
        </>
      </PageLayoutWithTabs.Route>
      {settingsEnabled && (
        <PageLayoutWithTabs.Route path="settings" title="Settings">
          <>
            <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
              <PageTitle customPageTitle="Settings | Stargate" />
            </BinaryFeatureFlagged>
            <Grid container spacing={2}>
              <BinaryFeatureFlagged withFlag={LANGUAGE_SWITCHER}>
                <Grid item xs={12} md={4}>
                  <InfoCard
                    variant="gridItem"
                    title="Language"
                    cardClassName={classes.settingsCard}
                  >
                    <Tooltip
                      title="This feature is in alpha and may not be fully integrated across the portal. Some translations might be incomplete."
                      placement="right"
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography className={classes.tokenText}>
                          Language
                        </Typography>
                        <SidebarItemTag label="Alpha" />
                      </Stack>
                    </Tooltip>
                    <UserSettingsLanguageToggle />
                  </InfoCard>
                </Grid>
              </BinaryFeatureFlagged>
              <BinaryFeatureFlagged withFlag={NOTIFICATIONS}>
                <Grid item xs={12} md={4}>
                  <UserNotificationSettingsCard
                    originNames={{ 'plugin:projects': 'Project Workspace' }}
                  />
                </Grid>
              </BinaryFeatureFlagged>
            </Grid>
          </>
        </PageLayoutWithTabs.Route>
      )}
    </PageLayoutWithTabs>
  );
}

export const ProfilePage = () => {
  return <ProfilePageComponent />;
};
