import React, { useEffect, useState } from 'react';
import { appLanguageApiRef } from '@backstage/core-plugin-api/alpha';
import {
  storageApiRef,
  useAnalytics,
  useApi,
} from '@backstage/core-plugin-api';
import useObservable from 'react-use/esm/useObservable';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

export const UserSettingsLanguageToggle = () => {
  const languageApi = useApi(appLanguageApiRef);
  const analytics = useAnalytics();
  const storageApi = useApi(storageApiRef);
  const [languageObservable] = useState(() => languageApi.language$());
  const { language: currentLanguage } = useObservable(
    languageObservable,
    languageApi.getLanguage(),
  );

  useEffect(() => {
    languageApi.setLanguage(currentLanguage || 'en');
  }, [languageApi, currentLanguage]);

  const { languages } = languageApi.getAvailableLanguages();

  if (languages.length <= 1) {
    return null;
  }
  const handleSetLanguage = (
    _: React.MouseEvent<HTMLElement>,
    newLanguage: string,
  ) => {
    languageApi.setLanguage(newLanguage);
    storageApi.set('language', newLanguage);

    // use GA to capture language switch event
    analytics.captureEvent('lang_switch', 'settings', {
      attributes: { newLanguage },
    });
  };
  // leave this as an option in case we want to change the language display name
  // TODO: remove on language feature flag removal
  // const getLanguageDisplayName = (language: string) => {
  //   try {
  //     const names = new Intl.DisplayNames([language], {
  //       type: 'language',
  //     });
  //     return names.of(language) || language;
  //   } catch (err) {
  //     return language;
  //   }
  // };
  return (
    <ToggleButtonGroup
      value={currentLanguage}
      exclusive
      onChange={handleSetLanguage}
      aria-label="Language"
      id="profile-language-switcher"
    >
      {languages.map(language => (
        <ToggleButton
          value={language}
          key={`profile-language-switcher-${language}`}
          id={`profile-language-switcher-${language}`}
        >
          {language}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
