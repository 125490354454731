import React from 'react';

import { useRouteRef } from '@backstage/core-plugin-api';
import { PageLayout } from '@internal/sg-ui-kit';
import { Typography } from '@material-ui/core';

import { rootRouteRef } from '../../routes';
import { ROOT_URL_LABEL_BREADCRUMBS } from '../ViewEditResources/ViewEditResourcePage/types';

import useBreadcrumbsFlag from '../../hooks/useBreadcrumbsFlag';
import { Progress } from '@backstage/core-components';

import { useStyles } from './styles';

export function CreateProject() {
  const classes = useStyles();
  const rootLink = useRouteRef(rootRouteRef);

  const { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag } =
    useBreadcrumbsFlag();

  const backToLink = React.useMemo(
    () => ({
      to: rootLink(),
      label: 'Back to Projects',
    }),
    [rootLink],
  );

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: 'create-project',
      display: 'Create Project Workspace',
    },
  ];
  if (loadingBreadcrumbsFlag || loadingBreadcrumbsFlag) {
    return <Progress />;
  }

  return (
    <PageLayout
      title="Projects"
      headerBackgroundImg="assets/welcome_bg.webp"
      backToLink={isBreadCrumbsFlagEnabled ? breadcrumbs : backToLink}
    >
      <div className={classes.container}>
        <Typography variant="h3" paragraph>
          Create New Project
        </Typography>
      </div>
    </PageLayout>
  );
}
