import React from 'react';

import { useRouteRef } from '@backstage/core-plugin-api';
import { PageLayout } from '@internal/sg-ui-kit';
import Stack from '@mui/material/Stack';

import { usePermissions } from '../../../../hooks/usePermissions';
import { ProjectOwners } from '../../../../pages/ProjectDetails/ProjectOwners';
import { rootRouteRef } from '../../../../routes';
import { ProjectDetails, ROOT_URL_LABEL_BREADCRUMBS } from '../types';
import { EnvironmentTabsComponent } from './EnvironmentTabs';
import NamespaceVault from './NamespaceVault/NamespaceVaultComponent';
import { ViewEditResourceContext } from '../context';

import { useStyles } from '../../styles';

type NewVaultPageComponentProps = {
  projectData: ProjectDetails;
  isBreadCrumbsFlagEnabled: boolean;
};

export const NewVaultPageComponent = ({
  projectData,
  isBreadCrumbsFlagEnabled,
}: NewVaultPageComponentProps) => {
  const classes = useStyles();

  const rootLink = useRouteRef(rootRouteRef);

  const permissionsData = usePermissions();
  const userDetailsAndPermissions = {
    data: permissionsData.userData,
    email: permissionsData?.userData?.email,
    roles: permissionsData.roles,
    permissions: permissionsData.permissions,
    isAdmin: permissionsData.isAdmin,
    isProjectOwner: permissionsData.isProjectOwner,
    isROAdmin: permissionsData.isROAdmin,
    isAnyAdmin: permissionsData.isAnyAdmin,
    isResourceCreator: permissionsData.isResourceCreator,
    isResourceManager: permissionsData.isResourceManager,
    refresh: permissionsData.refresh,
  };

  const backToTarget = projectData.id
    ? `/projects/${projectData.id}`
    : rootLink();
  const backToLink = React.useMemo(
    () => ({
      to: backToTarget,
      label: projectData.id ? 'Back to Project' : 'Back to Projects',
    }),
    [backToTarget, projectData.id],
  );

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${projectData?.id}?tab=resources`,
      display: projectData?.name,
    },
    {
      path: `manageresource/${projectData?.resources[0]?.id}`,
      display: projectData?.resources[0]?.key,
    },
  ];

  return (
    <ViewEditResourceContext.Provider
      value={{
        projectDetails: projectData,
        userDetailsAndPermissions,
      }}
    >
      <PageLayout
        type="entity"
        title={projectData?.name || ''}
        headerAdditionalControls={
          <ProjectOwners owners={projectData?.owners || null} />
        }
        backToLink={isBreadCrumbsFlagEnabled ? breadcrumbs : backToLink}
        children={undefined}
      />
      <div className={classes.container}>
        <Stack direction="column" spacing={2}>
          <NamespaceVault resourceData={projectData?.resources[0]} />
          <EnvironmentTabsComponent />
        </Stack>
      </div>
    </ViewEditResourceContext.Provider>
  );
};
