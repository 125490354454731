import React, { MouseEvent, SyntheticEvent, useState } from 'react';

import { Paper } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { AssignUserGroupsComponent } from './AssignUserGroups';
import { DialogAddEnvironmentConfirmation } from './Dialogs/DialogAddEnvironmentConfirmation';
import { EnvironmentForm } from './EnvironmentForm';
import { useViewEditResourceContext } from '../../context';
import { useEnvironmentsHook } from '../../hooks/useEnvironmentsHook';

export const EnvironmentTabsComponent = () => {
  const resourceContext = useViewEditResourceContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newEnv, setNewEnv] = useState('');
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const {
    menus,
    environments,
    createNewEnvironment,
    getName,
    isAddEnvEnabled,
    setEnvironments,
  } = useEnvironmentsHook(
    resourceContext.projectDetails.id,
    resourceContext.projectDetails.resources[0],
    resourceContext.userDetailsAndPermissions,
    selectedTabIndex,
    setSelectedTabIndex,
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setIsEdit(false);
    setSelectedTabIndex(newValue);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: MouseEvent<HTMLElement>) => {
    setNewEnv(event.currentTarget.textContent ?? '');
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const displayEnvironmentMenu = () => {
    return (
      <Menu
        id="new-environment-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menus.map((env, _index) => (
          <MenuItem
            id={`project-menuitem-${env}`}
            key={env}
            onClick={handleMenuItemClick}
          >
            {env}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const addEnv = async (newEnvi: string) => {
    setOpenDialog(false);
    await createNewEnvironment(newEnvi);
  };

  return (
    <Paper>
      <Stack sx={{ pt: 2, pb: 2, ml: 3, mr: 3 }} spacing={0}>
        <Typography variant="h6" fontWeight="bold">
          Environments
        </Typography>
        <Grid container sx={{ borderBottom: 2, borderBottomColor: 'grey.300' }}>
          <Grid item>
            <Tabs
              id="environment-tabs"
              value={selectedTabIndex}
              onChange={handleTabChange}
            >
              {environments.map(env => (
                <Tab
                  key={env.id}
                  label={getName(env.environment)}
                  sx={{ fontWeight: 'bold' }}
                />
              ))}
            </Tabs>
          </Grid>
          <Button
            onClick={handleClick}
            disabled={!isAddEnvEnabled()}
            id="btn-add-environment"
          >
            <AddIcon />
          </Button>
          {displayEnvironmentMenu()}
          <DialogAddEnvironmentConfirmation
            isOpen={openDialog}
            setIsOpen={setOpenDialog}
            env={newEnv}
            confirmArchive={addEnv}
            setEnv={setNewEnv}
          />
        </Grid>
        <EnvironmentForm
          isEdit={isEdit}
          setEnvironments={setEnvironments}
          selectedTabIndex={selectedTabIndex}
          setIsEdit={setIsEdit}
          environments={environments}
        />
        {environments[selectedTabIndex].deleted_on ? (
          <Alert severity="info">
            This environment has been archived. Group list is not available for
            archived resources.
          </Alert>
        ) : (
          <AssignUserGroupsComponent
            environment={environments[selectedTabIndex]}
          />
        )}
      </Stack>
    </Paper>
  );
};
