import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  chip: {
    marginTop: 10,
    marginBottom: 0,
    background:
      'linear-gradient(0deg, rgba(16, 89, 205, 0.2), rgba(16, 89, 205, 0.2)), #FFFFFF',
    borderRadius: '12px',
  },
  buttonContainer: {
    padding: '8px 0 !important',
  },
  button: {
    padding: '0',
    color: theme.palette.usgColors.primary.blue60,
    fontSize: '11px',
    '&:hover': {
      backgroundColor: 'rgba(230, 230, 230, 0)',
    },
    textTransform: 'none',
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  roleDialog: {
    '& .MuiPaper-root': {
      width: '400px',
    },
  },
  row: {
    margin: '1em 0',
  },
  rowTitle: {
    width: '60%',
  },
}));
