import React from 'react';

import { HeaderLink } from '../HeaderLink';
import { PathLink, SGBreadcrumbs } from '../../../SGBreadcrumbs';

export type BackToLink = {
  to: string;
  label: string;
};

type NavigationProps = {
  backToLink: BackToLink | PathLink[];
  isLightBackground: boolean;
};

export const NavigationLinkComponent = ({
  backToLink,
  isLightBackground,
}: NavigationProps) => {
  if (Array.isArray(backToLink)) {
    // its an array that means it is breadcrumbs
    return (
      <SGBreadcrumbs
        breadcrumbs={backToLink}
        isLightBackground={isLightBackground}
      />
    );
  }

  return (
    <HeaderLink to={backToLink?.to ?? ''} text={backToLink?.label ?? ''} />
  );
};
