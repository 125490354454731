import React from 'react';

import { Progress } from '@backstage/core-components';
import { Box } from '@material-ui/core';

import { Formik } from 'formik';

import { EnvironmentViewComponent } from './EnvironmentView';
import { ResourceManagersComponent } from './ResourceManagers';
import { useResourceManagersHook } from '../../hooks/useResourceManagersHook';
import { useViewEditResourceContext } from '../../context';

const PROGRESS_HEIGHT = 145;

export const EnvironmentForm = (props: any) => {
  const resourceContext = useViewEditResourceContext();
  const { environments, selectedTabIndex, setEnvironments, isEdit, setIsEdit } =
    props;

  const {
    managers,
    setManagers,
    loading,
    isAddManagerEnabled,
    setIsAddManagerEnabled,
    checkUserValidity,
    convertToManagerList,
  } = useResourceManagersHook(
    resourceContext.projectDetails,
    resourceContext.userDetailsAndPermissions.email,
    environments[selectedTabIndex].id,
  );

  if (loading) {
    return (
      <div>
        <Progress />
        <Box height={PROGRESS_HEIGHT} />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        addResourceManagers: convertToManagerList(managers ?? []),
      }}
      onSubmit={() => {}}
      enableReinitialize
    >
      {formik => {
        return (
          <>
            <EnvironmentViewComponent
              formik={formik}
              setEnvironments={setEnvironments}
              isAddManagerEnabled={isAddManagerEnabled}
              environments={environments}
              selectedTabIndex={selectedTabIndex}
              setManagers={setManagers}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
            <ResourceManagersComponent
              environment={environments[selectedTabIndex]}
              loading={loading}
              isAddManagerEnabled={isAddManagerEnabled}
              managers={managers}
              setIsAddManagerEnabled={setIsAddManagerEnabled}
              checkUserValidity={checkUserValidity}
              isEdit={isEdit}
            />
          </>
        );
      }}
    </Formik>
  );
};
