import React from 'react';

import { Breadcrumbs } from '@material-ui/core';
import Grid from '@mui/material/Grid';

import { BreadcrumbLink } from './BreadcrumbLink';

import { useStyles } from './styles';

export type PathLink = {
  path: string;
  display: string;
  isAbsolute?: boolean;
};

export type PathLinkProps = {
  value: string;
  pathSlice: string;
  isLast: boolean;
};

const PathLinkComponent = ({ value, pathSlice, isLast }: PathLinkProps) => {
  return <BreadcrumbLink to={pathSlice} text={value} isLast={isLast} />;
};

type SGBreadcrumbsComponentProps = {
  breadcrumbs?: PathLink[];
  isLightBackground: boolean;
};

export const SGBreadcrumbs = ({
  breadcrumbs,
  isLightBackground,
}: SGBreadcrumbsComponentProps) => {
  const pathnames = breadcrumbs?.map(b => b.path);
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item>
        <Breadcrumbs
          className={
            isLightBackground ? classes.lightBackground : classes.darkBackground
          }
        >
          {breadcrumbs?.map((value, index) => (
            <PathLinkComponent
              key={value.display}
              value={value.display}
              isLast={index === breadcrumbs.length - 1}
              pathSlice={
                value.isAbsolute
                  ? value.path
                  : `/${pathnames?.slice(0, index + 1).join('/')}`
              }
            />
          ))}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};
