import { createTranslationRef } from '@backstage/core-plugin-api/alpha';
import { en, ja } from './locales'; // import your translation files.
import { flattenObject } from 'sg-utils-frontend'; // we will need this to flatten our ja translation json object.
/** @alpha */

const new_ja = flattenObject(ja);
export const appTranslationRef = createTranslationRef({
  id: 'app',
  messages: en,
  translations: {
    ja: () =>
      Promise.resolve({
        default: new_ja,
      }),
  },
});
