import React, { useState } from 'react';

import { Grid } from '@material-ui/core';

import { LongEmailListComponent } from './LongEmailListComponent';
import { useStyles } from './styles';

const GRID_ITEM_VAL = 2;
const EMAIL_COLUMNS = 5;

const changeDisplayTypeFunction = (
  isViewMore: boolean,
  setIsViewMore: (value: boolean) => void,
) => {
  return isViewMore ? () => setIsViewMore(false) : () => setIsViewMore(true);
};

const listEmails = (isViewMore: boolean, emails: string[]): string[] => {
  return isViewMore ? emails : emails?.slice(0, EMAIL_COLUMNS);
};

export const SGEmailListGrid = (props: any) => {
  const classes = useStyles();

  const { emails } = props;
  const [isViewMore, setIsViewMore] = useState(false);

  const changeDisplayType = changeDisplayTypeFunction(
    isViewMore,
    setIsViewMore,
  );

  if (emails?.length <= EMAIL_COLUMNS) {
    return (
      <Grid container spacing={2} className={classes.mailsContainer}>
        {emails?.map((email: string) => {
          return (
            <Grid item xs={GRID_ITEM_VAL} className={classes.grid20}>
              <p className={classes.emailItem}> {email}</p>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  const emailsToBeDisplayed = listEmails(isViewMore, emails);

  return (
    <LongEmailListComponent
      isViewMore={isViewMore}
      changeDisplayType={changeDisplayType}
      emails={emailsToBeDisplayed}
    />
  );
};
