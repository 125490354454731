import React from 'react';

import { Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useStyles } from './styles';

export type Props = {
  to: string;
  text: string;
};

export const HeaderLink = ({ to, text }: Props) => {
  const classes = useStyles();

  return (
    <Link to={to} color="inherit" underline="always" className={classes.link}>
      <Typography variant="caption" className={classes.text}>
        {text}
      </Typography>
    </Link>
  );
};
