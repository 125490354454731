import { useState } from 'react';

import { ResultData, User } from '../components/ViewUserGroups3/Members/types';

export const useExportUsers = () => {
  const MAX_FETCH_PER_USER_FOR_EXPORT = 1000;

  const [displayExportProgressBar, setDisplayExportProgressBar] =
    useState<boolean>(false);

  const downloadFile = (blob: Blob, filename: string): void => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateCSVFile = (
    data: User[],
    isGetUserMemberListNewFlagEnabled: boolean,
  ): Blob => {
    const headers = ['Email', 'Name'];
    const filteredData = data.map(row => {
      return {
        email: `"${row.email}"`,
        displayName: isGetUserMemberListNewFlagEnabled
          ? `"${row.display_name}"`
          : `"${row.displayName}"`,
      };
    });
    const rows = filteredData
      .map(row => Object.values(row).join(','))
      .join('\n');
    const csvContent = `${headers}\n${rows}`;

    return new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  };

  const fetchPerMaxSize = (
    query: any,
    getUserListData: (query: any) => Promise<ResultData>,
  ): Promise<ResultData> => {
    return new Promise(resolve => {
      resolve(getUserListData(query));
    });
  };

  const fetchAllUsers = (
    totalThread: number,
    getUserListData: (query: any) => Promise<ResultData>,
  ): Promise<ResultData>[] => {
    const promises: Promise<ResultData>[] = [];

    for (let i = 0; i < totalThread; i++) {
      const query = {
        page: i,
        pageSize: MAX_FETCH_PER_USER_FOR_EXPORT,
      };

      promises.push(fetchPerMaxSize(query, getUserListData));
    }

    return promises;
  };

  const handleExportUser = async (
    members: ResultData,
    userGroupName: string,
    getUserListData: (query: any) => Promise<ResultData>,
    isGetUserMemberListNewFlagEnabled: boolean,
  ) => {
    if (members.totalCount === 0) {
      return;
    }

    setDisplayExportProgressBar(true);
    let data = members.data;

    if (data.length < members.totalCount) {
      const totalThreads =
        members.totalCount / MAX_FETCH_PER_USER_FOR_EXPORT +
        (members.totalCount % MAX_FETCH_PER_USER_FOR_EXPORT > 0 ? 1 : 0);

      const results = await Promise.all(
        fetchAllUsers(totalThreads, getUserListData),
      );
      data = [];
      for (const result of results) {
        data = [...data, ...result.data];
      }
    }

    const csvBlob: Blob = generateCSVFile(
      data,
      isGetUserMemberListNewFlagEnabled,
    );
    const fileName = `${userGroupName} members.csv`;
    downloadFile(csvBlob, fileName);
    setDisplayExportProgressBar(false);
  };

  return { displayExportProgressBar, handleExportUser };
};
