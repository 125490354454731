import React from 'react';
import { useAsyncFn } from 'react-use';
import cn from 'classnames';
import { Chip, Typography, Box, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatDate } from 'sg-utils-frontend';
import { newsApiRef } from '../../api';
import Alert from '@material-ui/lab/Alert';
import { Progress } from '@backstage/core-components';
import { Link } from '@backstage/core-components';
import { newsPageRouteRef } from '../../routes';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';

type Props = {
  id: string;
  tags?: string[];
  title: string;
  description: string;
  date: string;
  isInitiallyActive?: boolean;
};

export const NewsCard = ({
  id,
  tags,
  title,
  description,
  date,
  isInitiallyActive = false,
}: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const newsApi = useApi(newsApiRef);
  const [isContentVisible, setIsContentVisible] =
    React.useState<boolean>(false);
  const getNewsPageRoute = useRouteRef(newsPageRouteRef);
  const { search } = useLocation();

  // To get perticular news details based on id
  const [{ value: content, loading: isContentLoading, error }, fetchContent] =
    useAsyncFn(async () => {
      return await newsApi.getNewsContent(id);
    }, [id]);

  React.useMemo(() => new URLSearchParams(search).get('id'), [search]);

  React.useEffect(
    () => {
      if (isInitiallyActive) {
        setIsContentVisible(true);
        fetchContent();
        if (elementRef.current) {
          elementRef.current.scrollIntoView();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const toggleContent = React.useCallback(
    async () => {
      const isContentVisibleNextValue = !isContentVisible;
      setIsContentVisible(isContentVisibleNextValue);

      if (!content && isContentVisibleNextValue) {
        fetchContent();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content, isContentVisible],
  );

  React.useEffect(() => {
    const isMarkdownPresent = document.querySelectorAll('div [data-attribute]');
    isMarkdownPresent.forEach(element => {
      const removeElements = (elements: any) => {
        if (elements.length > 0) {
          while (elements[0]) {
            elements[0].parentNode.removeChild(elements[0]);
          }
        }
      };
      if (element) {
        removeElements(element.getElementsByTagName('svg'));
        removeElements(element.getElementsByTagName('header'));
        removeElements(element.getElementsByTagName('input'));
        removeElements(element.getElementsByTagName('footer'));
        removeElements(element.getElementsByClassName('md-skip'));
      }
    });

    const anchorsInContent: NodeListOf<HTMLElement> = document.querySelectorAll(
      'div [ data-md-component="content"] a',
    );
    anchorsInContent.forEach(element => {
      element.onclick = event => {
        const hrefVal = element.getAttribute('href') || '';
        if (hrefVal.startsWith('/')) {
          event.preventDefault(); // Prevent the default anchor behavior (navigation)
          navigate(hrefVal);
        }
      };
    });
  });

  // Displaying the news data
  const contentBlock = React.useMemo(
    () => {
      if (isContentLoading) {
        return <Progress />;
      }
      if (error) {
        return <Alert severity="error">{error.message}</Alert>;
      }
      if (content) {
        return (
          <div className={classes.content} data-attribute="markdown">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        );
      }
      return <Typography variant="body2">No content</Typography>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content, isContentLoading, error],
  );

  const handleOpenSingleNews = () => {
    setIsContentVisible(true);
    toggleContent();
  };

  return (
    <div ref={elementRef}>
      {tags && (
        <Box mb={2}>
          {tags.map(tag => (
            <Chip key={tag} size="small" label={tag} className={classes.tag} />
          ))}
        </Box>
      )}
      <Box mb={2}>
        <Link
          to={`${getNewsPageRoute()}?${new URLSearchParams({ id: id })}`}
          color="inherit"
          underline="none"
          key={id}
          className={classes.linkContainer}
          onClick={handleOpenSingleNews}
        >
          <Typography variant="h3">{title}</Typography>
        </Link>
      </Box>
      {description && (
        <Box mb={3}>
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Box>
      )}
      <Typography variant="caption">{formatDate(date, false)}</Typography>

      <Box mt={3}>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          onClick={toggleContent}
          spacing={0}
          className={classes.button}
        >
          <Grid item>
            <Typography variant="button">
              {isContentVisible ? 'Read less' : 'Read more'}
            </Typography>
          </Grid>
          <Grid item>
            <ExpandMoreIcon
              className={cn(classes.icon, {
                [classes.expandedIcon]: isContentVisible,
              })}
            />
          </Grid>
        </Grid>
      </Box>
      {isContentVisible && <Box mt={3}>{contentBlock}</Box>}
    </div>
  );
};
