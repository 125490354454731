import React from 'react';

import {
  BinaryFeatureFlagged,
  PageLayout,
  PageTitle,
} from '@internal/sg-ui-kit';
import { useRouteRef } from '@backstage/core-plugin-api';

import { PORTAL_PAGES_TITLES } from 'usg-types';

import { ROOT_URL_LABEL_BREADCRUMBS } from './ViewEditResourcePage/types';
import {
  ProjectOwnerProps,
  ProjectOwners,
} from '../../pages/ProjectDetails/ProjectOwners';
import { rootRouteRef } from '../../routes';

type ProjectData = ProjectOwnerProps & {
  id: string;
  name: string;
};

type ResourceData = {
  id: string;
  name: string;
};

type PageTitleBreadcrumbsProps = {
  isBreadCrumbsFlagEnabled: boolean;
  projectData: ProjectData;
  resourceData: ResourceData;
};

export const PageTitleBreadcrumbs = ({
  isBreadCrumbsFlagEnabled,
  projectData,
  resourceData,
}: PageTitleBreadcrumbsProps) => {
  const rootLink = useRouteRef(rootRouteRef);

  const backToTarget = projectData.id
    ? `/projects/${projectData.id}`
    : rootLink();

  const backToLink = React.useMemo(
    () => ({
      to: backToTarget,
      label: projectData.id ? 'Back to Project' : 'Back to Projects',
    }),
    [backToTarget, projectData.id],
  );

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${projectData.id}?tab=resources`,
      display: projectData.name,
    },
    {
      path: `manageresource/${resourceData.id}`,
      display: resourceData.name,
    },
  ];

  return (
    <>
      <PageLayout
        type="entity"
        title={projectData.name || ''}
        headerAdditionalControls={<ProjectOwners owners={projectData.owners} />}
        backToLink={isBreadCrumbsFlagEnabled ? breadcrumbs : backToLink}
        children={undefined}
      />
      <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
        <PageTitle
          customPageTitle={`${resourceData.name} | Resources | ${projectData.name} | Stargate`}
        />
      </BinaryFeatureFlagged>
    </>
  );
};
