import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAsyncFn } from 'react-use';
import { useSearchParams } from 'react-router-dom';

import { PageLayout, useGoogleAnalytics } from '@internal/sg-ui-kit';
import { Link, Progress } from '@backstage/core-components';
import { BinaryFeatureFlagged, PageTitle } from '@internal/sg-ui-kit';
import { Wizard } from '@internal/plugin-mtfuji';
import { usePermissions } from '@internal/plugin-projects';
import { Grid, Paper, Radio, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';

import { getTechDocsLink, transformResourceName } from 'sg-utils-frontend';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  RESOURCE_CREATOR,
  RESOURCE_ENABLED_DEVTOOLS,
  VAULT_DEV_TOOL_ID,
  RESOURCES_ENUM,
  RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT,
  PORTAL_PAGES_TITLES,
} from 'usg-types';

import { rootRouteRef } from '../../routes';
import { projectApiRef } from '../../api';
import useBreadcrumbsFlag from '../../hooks/useBreadcrumbsFlag';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { ROOT_URL_LABEL_BREADCRUMBS } from '../ViewEditResources/ViewEditResourcePage/types';
import { CreateArtifactorySaaSResourceForm } from './create-resource-forms/CreateArtifactorySaaSResourceForm';
import { CreateConfluenceResourceForm } from './create-resource-forms/CreateConfluenceResourceForm';
import { CreateGithubEMUResourceForm } from './create-resource-forms/CreateGithubEMUResourceForm';
import { CreateJamaResourceForm } from './create-resource-forms/CreateJamaResourceForm';
import { CreateJiraResourceForm } from './create-resource-forms/CreateJiraResourceForm';
import { CreateVaultResourceForm } from './create-resource-forms/CreateVaultResourceForm';
import { CustomDialogMapper } from './custom-dialog-mapper';

import { useStyles } from './styles';

interface CreateResourceComponentProps {
  projectId: string;
  defaultSelectedDevToolId: string;
}

type Role = {
  id: number;
  name: string;
  display_name: string;
  description: string;
};

type Environment = {
  id: number;
  key: string;
  name: string;
  description: string;
};

type Resource = {
  id: number;
  name: string;
  description: string;
  enable_users_environment: boolean;
  default_role_id: number;
  roles: Role[];
  environments: Environment[];
};

const CREATE_RESOURCE_ACTION_NAME = 'create_resource';

const CREATE_RESOURCE_SUBJECT_ATTRIBUTES_MAPPING: {
  [key: string]: {
    subject: string;
    resource: string;
  };
} = {
  [GITHUBEMU_DEV_TOOL_ID]: {
    subject: RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT.GITHUBEMU_DEV_TOOL,
    resource: RESOURCES_ENUM.GITHUBEMU_DEV_TOOL,
  },
  [CONFLUENCE_DEV_TOOL_ID]: {
    subject: RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT.CONFLUENCE_DEV_TOOL,
    resource: RESOURCES_ENUM.CONFLUENCE_DEV_TOOL,
  },
  [VAULT_DEV_TOOL_ID]: {
    subject: RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT.VAULT_DEV_TOOL,
    resource: RESOURCES_ENUM.VAULT_DEV_TOOL,
  },
  [JIRA_DEV_TOOL_ID]: {
    subject: RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT.JIRA_DEV_TOOL,
    resource: RESOURCES_ENUM.JIRA_DEV_TOOL,
  },
  [JAMA_DEV_TOOL_ID]: {
    subject: RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT.JAMA_DEV_TOOL,
    resource: RESOURCES_ENUM.JAMA_DEV_TOOL,
  },
  [ARTIFACTORY_SAAS_DEV_TOOL_ID]: {
    subject:
      RESOURCES_GA_CREATE_RESOURCE_EVENT_SUBJECT.ARTIFACTORY_SAAS_DEV_TOOL,
    resource: RESOURCES_ENUM.ARTIFACTORY_SAAS_DEV_TOOL,
  },
};

export const CreateResourceComponent = ({
  projectId,
  defaultSelectedDevToolId,
}: CreateResourceComponentProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rootLink = useRouteRef(rootRouteRef);
  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const { captureGoogleAnalyticEvent } = useGoogleAnalytics();
  const [resourcesList, setResourcesList] = useState<Resource[]>([]);
  const [selectedResource, setSelectedResource] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [descriptionDirty, setDescriptionDirty] = useState(false);
  const [keyDirty, setKeyDirty] = useState(false);
  const [vaultNameDirty, setVaultNameDirty] = useState(false);
  const [isArtifactoryNameDirty, setArtifactoryNameDirty] = useState(false);
  const [rClass, setRClass] = useState('');
  const [enviromentsData, setEnviromentsData] = useState([] as any);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [idToken, setIdToken] = useState<any>();
  const [apiLoader, setApiLoader] = useState(false);
  const [newResourceId, setNewResourceId] = useState('');

  const [enabledDevTools, setEnabledDevTools] = useState(
    RESOURCE_ENABLED_DEVTOOLS,
  );
  const {
    isAdmin,
    isProjectOwner,
    isLoading: rolesCheckLoading,
    isResourceCreator,
    error: permissionsError,
  } = usePermissions();
  const { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag } =
    useBreadcrumbsFlag();

  const backToTarget = projectId ? `/projects/${projectId}` : rootLink();
  const backToLink = useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backToTarget],
  );

  const getEnabledDevTools = (isAdminOrOwner: boolean) => {
    if (isAdminOrOwner) {
      setEnabledDevTools(prev => [...prev, ARTIFACTORY_SAAS_DEV_TOOL_ID]);
    }
  };

  const [
    { value: projectData = [] as any, loading, error: fetchProjectDataError },
    fetchProjectData,
  ] = useAsyncFn(
    async token => {
      let data;
      try {
        const params = { idToken: token };
        data = await projectApi.getProjectByID(projectId, token, {
          manipulators: ['user_groups'],
        });

        const queryParams = {
          include: 'all',
        };

        const res: any = await projectApi.getUserDetails(params, queryParams);
        const userData = res?.response?.data;
        const foundProject: any = userData?.projects.find(
          (p: any) => p.id.toString() === projectId,
        );

        getEnabledDevTools(isAdmin || isProjectOwner(Number(projectId)));

        const resourceCreators = foundProject?.development_tools?.some(
          (tool: any) =>
            tool?.dev_tool_roles?.some(
              (element: string) => element === RESOURCE_CREATOR,
            ),
        );
        if (
          !isAdmin &&
          !isProjectOwner(Number(projectId)) &&
          !resourceCreators
        ) {
          navigate(`/projects/${projectId}`);
        }
      } catch (err) {
        errorApi.post(new Error(`${err?.message}`));
        navigate('/projects');
      }
      return data;
    },
    [projectId, rolesCheckLoading],
  );

  const [
    { loading: loadingDevelopmentToolsData = false as boolean },
    fetchDevelopmentToolsData,
  ] = useAsyncFn(async token => {
    try {
      const devToolData: any = await projectApi.getDevelopmentToolsData(token);

      const allowedResourcesList = devToolData?.development_tools?.filter(
        ({ id }: { id: number }) =>
          id !== GITHUB_DEV_TOOL_ID && id !== ARTIFACTORY_DEV_TOOL_ID,
      );
      setResourcesList(allowedResourcesList);

      const defaultId =
        allowedResourcesList.find(
          ({ id }: { id: number }) => id === Number(defaultSelectedDevToolId),
        )?.id || MTFUJI_DEV_TOOL_ID;
      setSelectedResource(defaultId.toString());

      setEnviromentsData(
        devToolData?.development_tools?.filter((t: any) => t.id === 105)[0]
          ?.environments,
      );
    } catch (errorMsg) {
      errorApi.post(new Error(`${errorMsg?.message}`));
      setEnviromentsData([]);
      setResourcesList([]);
    }
  }, []);

  useEffect(() => {
    if (!rolesCheckLoading) {
      (async () => {
        const token = await authRef.getIdToken();
        setIdToken(token);
        await fetchProjectData(token);
        // getting Development tools data
        await fetchDevelopmentToolsData(token);
      })();
    }
  }, [rolesCheckLoading, fetchProjectData, fetchDevelopmentToolsData, authRef]);

  if (
    loading ||
    loadingDevelopmentToolsData ||
    rolesCheckLoading ||
    loadingBreadcrumbsFlag
  ) {
    return <Progress />;
  }

  if (fetchProjectDataError) {
    errorApi.post(new Error(`${fetchProjectDataError}`));
  }

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  const handleChange = (e: any) => {
    setVaultNameDirty(false);
    setArtifactoryNameDirty(false);
    setNameDirty(false);
    setKeyDirty(false);
    setDescriptionDirty(false);
    setSelectedResource('');
    setTimeout(() => {
      setSelectedResource(e.target.name);
    }, 100);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleCreateResource = () => {
    navigate(`/projects/${projectId}`);
  };

  const handleDialogClose: (
    modelCloseType: 'assign-user-groups' | 'close',
  ) => void = modelCloseType => {
    setIsDialogOpen(false);
    switch (modelCloseType) {
      case 'assign-user-groups':
        navigate(`/projects/${projectId}/manageresource/${newResourceId}`);
        break;
      default:
        navigate(`/projects/${projectId}`);
        break;
    }
  };

  const bodyMapper = (data: any) => {
    const resource = parseInt(selectedResource, 10);
    switch (resource) {
      case GITHUB_DEV_TOOL_ID:
        return {
          idToken,
          key: data.github_organization_name,
          name: data.github_organization_name,
          description: data.github_organization_description,
          dev_tool_id: resource,
        };
      case GITHUBEMU_DEV_TOOL_ID:
        return {
          idToken,
          key: data.githubemu_organization_name,
          name: data.githubemu_organization_name,
          description: data.githubemu_organization_description,
          dev_tool_id: resource,
        };
      case JIRA_DEV_TOOL_ID:
        return {
          idToken,
          key: data.jira_project_key.toUpperCase(),
          name: data.jira_project_name.trim(),
          description: data.jira_project_description,
          dev_tool_id: resource,
          config: {
            project_template: data.project_template,
          },
        };
      case JAMA_DEV_TOOL_ID:
        return {
          idToken,
          key: data.jama_resource_key,
          name: data.jama_resource_name.trim(),
          description: data.jama_resource_description,
          dev_tool_id: resource,
        };
      case CONFLUENCE_DEV_TOOL_ID:
        return {
          idToken,
          key: data.confluence_key,
          name: data.confluence_name.trim(),
          description: data.confluence_description,
          dev_tool_id: resource,
        };

      default:
        return {};
    }
  };

  const captureCreateResourceEvent = () => {
    const { subject, resource } =
      CREATE_RESOURCE_SUBJECT_ATTRIBUTES_MAPPING[selectedResource];
    captureGoogleAnalyticEvent(CREATE_RESOURCE_ACTION_NAME, subject, {
      attributes: { resource, projectId },
    });
  };

  const onSubmitResource = async (values: any) => {
    setApiLoader(true);
    const body: any = bodyMapper(values);
    let fine = true;
    try {
      const rsp: any = await projectApi.createNewProjectResource(
        projectId,
        body,
      );

      if (rsp && rsp.error) {
        throw new Error(rsp.error.message);
      }
      setNewResourceId(rsp?.id);
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
      fine = false;
    } finally {
      setApiLoader(false);
      if (fine) {
        captureCreateResourceEvent();
        handleDialogOpen();
      }
    }
  };

  const onSubmitCreateVault = async (values: any) => {
    let fine = true;
    window.scrollTo(0, 0);
    setApiLoader(true);

    const project_id = projectData.id.toString();
    const paramsForCreateNewProjectResource: any = {
      idToken,
      key: values.vault_namespace.toLowerCase(),
      name: values.vault_namespace,
      dev_tool_id: Number(selectedResource),
      environments: values.vaultEnvironments,
    };
    try {
      const rsp: any = await projectApi.createNewProjectResource(
        project_id,
        paramsForCreateNewProjectResource,
      );

      if (rsp && rsp.error) {
        throw new Error(rsp.error.message);
      }
      if (rsp?.moreData?.result) {
        const createdEnvs = [];

        rsp.moreData.result.forEach((item: any) => {
          if (
            item.status === 'fulfilled' &&
            (item.value.status === 200 || item.value.status === 201)
          ) {
            createdEnvs.push(item.value.data.environment);
          }
        });
        if (createdEnvs.length + 1 !== values.vaultEnvironments.length) {
          fine = false;
          errorApi.post(
            new Error(
              `We were only able to create resources for ${
                createdEnvs.length + 1
              } environments`,
            ),
          );
        }
      }
      setNewResourceId(rsp?.id);
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
      fine = false;
    }

    setApiLoader(false);
    if (fine) {
      captureCreateResourceEvent();
      handleDialogOpen();
    }
  };

  const getParamConfigForArtifactorySaas = (values: any) => {
    const config: any = {
      packageType: values.package_type,
      rclass: values.r_class,
    };
    if (values.r_class === 'local') {
      config.sites = [...values.sites];
    }
    if (values.r_class === 'federated') {
      config.sites = ['jp', 'us'];
    }
    if (
      isAdmin &&
      (values.r_class === 'remote' || values.r_class === 'virtual')
    ) {
      config.sites = [...values.sites];
    }
    return config;
  };

  const onSubmitCreateArtifactorySaas = async (values: any) => {
    setRClass(values.r_class);
    let fine = true;
    const project_id = projectData.id.toString();
    const params: any = {
      idToken,
      key: values.artifactory_saas_repo_name.toLowerCase(),
      name: values.artifactory_saas_repo_name,
      description: values.artifactory_saas_description,
      dev_tool_id: Number(selectedResource),
    };
    if (values.package_type !== '' && values.r_class !== '') {
      params.config = getParamConfigForArtifactorySaas(values);
    }
    setApiLoader(true);
    try {
      const rsp: any = await projectApi.createNewProjectResource(
        project_id,
        params,
      );

      if (rsp && rsp.error) {
        throw new Error(rsp.error.message);
      }
      setNewResourceId(rsp?.id);
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
      fine = false;
    } finally {
      setApiLoader(false);
      if (fine) {
        captureCreateResourceEvent();
        handleDialogOpen();
      }
    }
  };

  const disableResourceRadioBtn = (resourceID: any) => {
    // disable radio button for non resource creator list if they're not admin
    if (!isProjectOwner(Number(projectId)) && !isAdmin) {
      return !isResourceCreator(Number(projectId), resourceID);
    }
    // other wise disable for the disabled devtools
    return !enabledDevTools.includes(resourceID);
  };

  const showComingSoonText = (resourceID: any) => {
    // show for the disabled devtools that are not in resource creator list
    return (
      ![...enabledDevTools].includes(resourceID) &&
      !isResourceCreator(Number(projectId), resourceID)
    );
  };

  const enableInfoText = () => {
    const data = resourcesList?.map((resource: any) =>
      disableResourceRadioBtn(resource.id),
    );
    return data.includes(true);
  };

  const onDescriptionClick = () => {
    setDescriptionDirty(true);
  };

  const resourceKeyClickHandler = () => setKeyDirty(true);

  const resourceNameClickHandler = () => setNameDirty(true);

  const displayForm = () => {
    switch (selectedResource) {
      case GITHUBEMU_DEV_TOOL_ID.toString():
        return (
          <CreateGithubEMUResourceForm
            onSubmitResource={onSubmitResource}
            nameDirty={nameDirty}
            projectData={projectData}
            onResourceNameClick={resourceNameClickHandler}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
          />
        );

      case CONFLUENCE_DEV_TOOL_ID.toString():
        return (
          <CreateConfluenceResourceForm
            onSubmitResource={onSubmitResource}
            nameDirty={nameDirty}
            projectData={projectData}
            onResourceNameClick={resourceNameClickHandler}
            keyDirty={keyDirty}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            apiLoader={apiLoader}
            handleCreateResource={handleCreateResource}
            onResourceKeyClick={resourceKeyClickHandler}
          />
        );
      case MTFUJI_DEV_TOOL_ID.toString():
        return (
          <Grid item xs={12}>
            <Wizard
              stargateProjectId={parseInt(projectId, 10)}
              projectId={projectData?.id}
              projectName={projectData?.name}
            />
          </Grid>
        );
      case VAULT_DEV_TOOL_ID.toString():
        return (
          <CreateVaultResourceForm
            onSubmitCreateVault={onSubmitCreateVault}
            vaultNameDirty={vaultNameDirty}
            projectData={projectData}
            onVaultNameClick={() => setVaultNameDirty(true)}
            enviromentsData={enviromentsData}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
            isDisabled={disableResourceRadioBtn(Number(selectedResource))}
          />
        );
      case JIRA_DEV_TOOL_ID.toString():
        return (
          <CreateJiraResourceForm
            onSubmitResource={onSubmitResource}
            keyDirty={keyDirty}
            projectData={projectData}
            onResourceKeyClick={resourceKeyClickHandler}
            nameDirty={nameDirty}
            onResourceNameClick={resourceNameClickHandler}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
          />
        );

      case JAMA_DEV_TOOL_ID.toString():
        return (
          <CreateJamaResourceForm
            onSubmitResource={onSubmitResource}
            keyDirty={keyDirty}
            projectData={projectData}
            onResourceKeyClick={resourceKeyClickHandler}
            nameDirty={nameDirty}
            onResourceNameClick={resourceNameClickHandler}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
          />
        );

      case ARTIFACTORY_SAAS_DEV_TOOL_ID.toString():
        return (
          <CreateArtifactorySaaSResourceForm
            onSubmitCreateArtifactorySaaS={onSubmitCreateArtifactorySaas}
            isArtifactoryNameDirty={isArtifactoryNameDirty}
            onArtifactoryNameClick={() => setArtifactoryNameDirty(true)}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            apiLoader={apiLoader}
            handleCreateResource={handleCreateResource}
            isAdmin={isAdmin}
          />
        );

      default:
        return <></>;
    }
  };

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${projectData?.id}?tab=resources`,
      display: projectData?.name,
    },
    {
      path: 'create-resource',
      display: 'Create Resource',
    },
  ];

  return (
    <PageLayout
      type="entity"
      title={projectData?.name}
      headerAdditionalControls={<ProjectOwners owners={projectData?.owners} />}
      backToLink={isBreadCrumbsFlagEnabled ? breadcrumbs : backToLink}
    >
      <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
        <PageTitle
          customPageTitle={`Create Resource in ${projectData?.name} | Stargate`}
        />
      </BinaryFeatureFlagged>
      {apiLoader ? <Progress /> : ''}
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={classes.createResourceItem}>
          <Typography
            variant="h3"
            className={classes.selectResourceText}
            id="create-resource-header"
          >
            Create Resource
          </Typography>
        </Grid>

        <Paper className={classes.selectResourceContainer}>
          <Grid container>
            <Grid item className={classes.selectResourceItem} xs={12}>
              <Typography
                variant="subtitle2"
                className={classes.selectResourceText}
              >
                Select Resource
              </Typography>

              {enableInfoText() && (
                <div className={classes.infoDiv}>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  <Typography variant="overline" paragraph>
                    <i>
                      You do not have permission to create 'few' resources in
                      this project workspace. Please ask a project owner to
                      grant you create resource permission
                    </i>
                    ,{' '}
                    <Link
                      className={classes.hyperlink}
                      target="_blank"
                      to={getTechDocsLink('delegate-user-mgmt')}
                    >
                      learn more
                    </Link>
                    .
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {resourcesList?.map((resource: any, index: any) => (
                  <span
                    key={index}
                    className={
                      disableResourceRadioBtn(resource.id)
                        ? classes.resourceGray
                        : classes.resourceList
                    }
                  >
                    <Radio
                      checked={
                        resource.id === Number(selectedResource) ? true : false
                      }
                      onChange={handleChange}
                      value={`${resource.id}`}
                      name={`${resource.id}`}
                      id={`${resource.id}`}
                      inputProps={{ 'aria-label': resource.id }}
                      disabled={disableResourceRadioBtn(resource.id)}
                    />
                    {transformResourceName(resource.name)}
                    &nbsp;
                    {showComingSoonText(resource.id) && (
                      <span className={classes.comingSoon}>
                        {resource.id !== GITHUBEMU_DEV_TOOL_ID ? (
                          '(Coming Soon)'
                        ) : (
                          <>
                            -{' '}
                            <Link
                              to="/news?id=EMU-migration-paused"
                              target="_blank"
                              className={classes.hyperLink}
                            >
                              temporarily suspended
                            </Link>
                          </>
                        )}
                      </span>
                    )}
                  </span>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid item xs={12} className={classes.displayFormContainer}>
          {displayForm()}
        </Grid>
      </Grid>
      <CustomDialogMapper
        isDialogOpen={isDialogOpen}
        handleDialogClose={handleDialogClose}
        selectedResource={selectedResource}
        rClass={rClass}
      />
    </PageLayout>
  );
};

export const CreateResourcePage = () => {
  const { projectId } = useParams() as { projectId: string };
  const [searchParams] = useSearchParams();
  const selectedDevToolId =
    searchParams.get('selected') || MTFUJI_DEV_TOOL_ID.toString();
  return (
    <CreateResourceComponent
      projectId={projectId}
      defaultSelectedDevToolId={selectedDevToolId}
    />
  );
};
