import React from 'react';
import { Link, Table, TableColumn } from '@backstage/core-components';
import { EmptyDataMessage } from '@internal/sg-ui-kit';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { DateTime } from 'luxon';
import Tooltip from '@mui/material/Tooltip';
import { Box, Button } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { truncate } from 'lodash';
import WarningIcon from '@material-ui/icons/Warning';
import { useStyles } from './styles';

interface Props {
  contractData: any;
  projectId: any;
  loading: boolean;
  onRemoveContract: (contract: any) => Promise<void>;
}

export const ProjectContractsTable = ({
  contractData,
  loading,
  projectId,
  onRemoveContract,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const updateContractHandler = (contract: any) => {
    navigate(`/projects/${projectId}/contracts/${contract.id}/edit`);
  };

  const columnFields: Array<TableColumn<any>> = [
    {
      field: 'name',
      title: 'Contract',
      width: '25%',
      render: ({ name, contract_url }) => {
        return (
          <Link to={`${contract_url}`} target="_blank" className={classes.link}>
            {name}
          </Link>
        );
      },
    },
    {
      title: 'Description',
      width: '45%',
      render: ({ description }) => {
        return (
          <Tooltip title={description} placement="bottom">
            <span className={classes.columnText}>
              {truncate(description, {
                length: 32,
              })}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Status',
      width: '11%',
      render: ({ name, ended_on, is_mismatch }) => (
        <span className={classes.columnText}>
          {DateTime.fromISO(ended_on) > DateTime.now() ? 'Active' : 'Expired'}
          {is_mismatch && (
            <WarningIcon data-testid={name} className={classes.warning} />
          )}
        </span>
      ),
    },
    {
      field: 'action',
      title: 'Action',
      width: '15%',
      render: contract => {
        return (
          <Box>
            <Button
              id={`remove-${contract.id}`}
              variant="text"
              color="primary"
              className={classes.button}
              onClick={() => {
                onRemoveContract(contract);
              }}
            >
              Remove
            </Button>
            <Button
              id={`edit-${contract.id}`}
              variant="text"
              className={classes.button}
              onClick={() => {
                updateContractHandler(contract);
              }}
            >
              Edit
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columnFields}
        data={contractData || []}
        options={{
          actionsColumnIndex: -1,
          padding: 'dense',
          search: false,
          paging: false,
          draggable: false,
          sorting: false,
          toolbar: false,
          loadingType: 'overlay',
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          actionsCellStyle: { padding: '5px 10px' },
          rowStyle: {
            fontWeight: 400,
            fontSize: '12px',
          },
          headerStyle: {
            fontWeight: 700,
            fontSize: '12px',
            textTransform: 'uppercase',
            color: '#0E1319B2',
            letterSpacing: '0.01em',
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <EmptyDataMessage
                message={`There are no contracts assigned to this workspace. You can assign new contracts through 'Assign Contract' option.`}
                iconComponent={<InfoOutlined />}
              />
            ),
          },
        }}
        isLoading={loading}
      />
    </>
  );
};
