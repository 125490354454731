import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_KEY_MIN_LENGTH,
  RESOURCE_KEY_MAX_LENGTH,
  RESOURCE_NAME_REGEX,
} from 'usg-types';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

interface Props {
  onSubmitResource: (values: any) => Promise<void>;
  nameDirty: boolean;
  projectData: any;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  keyDirty: boolean;
  onResourceKeyClick: () => void;
  handleCreateResource: () => void;
}
export const CreateConfluenceResourceForm = ({
  onSubmitResource,
  nameDirty,
  projectData,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  keyDirty,
  onResourceKeyClick,
  handleCreateResource,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          confluence_key: projectData?.key ?? '',
          confluence_name: '',
          confluence_description: '',
        }}
        onSubmit={onSubmitResource}
      >
        {formik => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  Confluence Space
                </Typography>
                <InputLabel
                  htmlFor="confluence_key"
                  style={{
                    color:
                      (formik.values.confluence_key.length <
                        RESOURCE_KEY_MIN_LENGTH.confluence &&
                        keyDirty) ||
                      (formik.values.confluence_key.length >
                        RESOURCE_KEY_MAX_LENGTH.confluence &&
                        keyDirty)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  {t('resource.view.confluence.spaceKeyLabel')}*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="confluence_key"
                  data-testid="confluence_key"
                  name="confluence_key"
                  required
                  error={
                    (formik.values.confluence_key.length <
                      RESOURCE_KEY_MIN_LENGTH.confluence &&
                      keyDirty) ||
                    !formik.values.confluence_key.match('^[a-zA-Z0-9]*$') ||
                    (formik.values.confluence_key.length >
                      RESOURCE_KEY_MAX_LENGTH.confluence &&
                      keyDirty)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.confluence_key}
                  onClick={onResourceKeyClick}
                  fullWidth
                  helperText={t('resource.view.confluence.spaceKeyHelperText', {
                    /* @ts-ignore */
                    min: RESOURCE_KEY_MIN_LENGTH.confluence,
                    max: RESOURCE_KEY_MAX_LENGTH.confluence,
                  })}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="confluence_name"
                  style={{
                    color:
                      (formik.values.confluence_name.length <
                        RESOURCE_NAME_MIN_LENGTH.confluence &&
                        nameDirty) ||
                      (formik.values.confluence_name.length >
                        RESOURCE_NAME_MAX_LENGTH.confluence &&
                        nameDirty)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  {t('resource.view.confluence.spaceNameLabel')}*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="confluence_name"
                  data-testid="confluence_name"
                  name="confluence_name"
                  required
                  error={
                    (formik.values.confluence_name.length <
                      RESOURCE_NAME_MIN_LENGTH.confluence &&
                      nameDirty) ||
                    (!formik.values.confluence_name.match(
                      RESOURCE_NAME_REGEX,
                    ) &&
                      nameDirty) ||
                    (formik.values.confluence_key.length >
                      RESOURCE_NAME_MAX_LENGTH.confluence &&
                      nameDirty)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.confluence_name}
                  onClick={onResourceNameClick}
                  fullWidth
                  helperText={t(
                    'resource.view.confluence.spaceNameHelperText',
                    {
                      /* @ts-ignore */
                      min: RESOURCE_NAME_MIN_LENGTH.confluence,
                      max: RESOURCE_NAME_MAX_LENGTH.confluence,
                    },
                  )}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="confluence_description"
                  className={classes.inputLabelText}
                >
                  {t('resource.view.confluence.descriptionLabel')}
                </InputLabel>
                <TextField
                  type="text"
                  id="confluence_description"
                  data-testid="confluence_description"
                  name="confluence_description"
                  onChange={formik.handleChange}
                  value={formik.values.confluence_description}
                  minRows={4}
                  multiline
                  fullWidth
                  error={
                    formik.values.confluence_description.length >
                      RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
                  }
                  helperText={t(
                    'resource.view.confluence.descriptionHelperText',
                    {
                      /* @ts-ignore */
                      max: RESOURCE_DESCRIPTION_MAX_LENGTH,
                    },
                  )}
                  placeholder={t(
                    'resource.view.confluence.descriptionPlaceholder',
                  )}
                  onClick={onDescriptionClick}
                />
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-confluence"
                      data-testid="cancel-confluence"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      {t('common.form.cancelLabel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-confluence"
                      data-testid="submit-confluence"
                      disabled={
                        formik.values.confluence_name.length <
                          RESOURCE_NAME_MIN_LENGTH.confluence ||
                        formik.values.confluence_key.length <
                          RESOURCE_KEY_MIN_LENGTH.confluence ||
                        formik.values.confluence_key.length >
                          RESOURCE_KEY_MAX_LENGTH.confluence ||
                        !formik.values.confluence_key.match('^[a-zA-Z0-9]*$') ||
                        !formik.values.confluence_name.match(
                          RESOURCE_NAME_REGEX,
                        ) ||
                        formik.values.confluence_name.length >
                          RESOURCE_NAME_MAX_LENGTH.confluence ||
                        formik.values.confluence_description.length >
                          RESOURCE_DESCRIPTION_MAX_LENGTH ||
                        apiLoader
                      }
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      {t('common.form.submitLabel')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
