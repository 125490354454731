import React from 'react';

import { Grid, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { SuccessBarProps } from '../types';
import { useStyles } from './styles';

export const SuccessBar = ({
  isOpen,
  children,
  setIsSuccessBoxOpen,
}: SuccessBarProps) => {
  const classes = useStyles();

  const vertical = 'top' as const;
  const horizontal = 'center' as const;

  const handleClose = () => {
    setIsSuccessBoxOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Grid container className={classes.container}>
        <Grid item className={classes.msg}>
          {children}
        </Grid>
        <Grid item>
          <IconButton
            id="project-iconbutton-close"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Snackbar>
  );
};
