import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  color: {
    color: theme.palette.usgColors.primary.blue,
    height: '23px',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  dialog: {
    position: 'absolute',
    left: 10,
    top: 50,
  },
  resourceText: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 700,
  },
  infoIcon: {
    display: 'flex',
    paddingBottom: '20px',
    '& svg': {
      marginLeft: '5px',
      color: theme.palette.usgColors.primary.blue,
    },
  },
  resourceOpacity: {
    opacity: 0.5,
    paddingBottom: '15px !important',
    display: 'flex',
    alignItems: 'center',
  },
  accessResouceText: {
    paddingTop: '10px',
    '& label': {
      fontSize: '15px',
      paddingBottom: '5px',
    },
  },
  resourceName: {
    padding: '18px 9px 18px 0px',
    fontSize: 12,
    fontWeight: 700,
  },
  resourceTitle: {
    width: 163,
  },
  resourceGrid: {
    paddingBottom: '15px !important',
    display: 'flex',
    alignItems: 'center',
  },
  roleType: {
    width: '120px',
    pointerEvents: 'none',
    '& input': {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  editRoleType: {
    width: '120px',
    '& div>div': {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  resourceLink: {
    width: '20px',
    '&:hover': {
      color: theme.palette.usgColors.primary.blue,
      textDecoration: 'underline',
    },
  },
  addBtn: {
    margin: '16px 0px',
  },
  userChip: {
    '& div > div > div > span': {
      textTransform: 'inherit',
    },
  },
  errorUuserChip: {
    '& div > div > div > span': {
      textTransform: 'inherit',
    },
    color: theme.palette.usgColors.secondary.darkRed,
  },
  errorChip: {
    '& svg': {
      color: theme.palette.usgColors.secondary.darkRed,
      '&:hover': {
        color: theme.palette.usgColors.secondary.darkRed,
      },
    },
    textTransform: 'inherit',
    color: theme.palette.usgColors.secondary.darkRed,
    border: '1px solid #CC0000',
  },

  selectedChip: {
    '& span': {
      textTransform: 'inherit',
      marginTop: '-2px',
    },
  },
  usersTable: {
    width: '96%',
    marginLeft: '0px',
    marginBottom: '10px',
  },
  table: {
    '& th': {
      color: theme.palette.textSubtle,
      fontWeight: '700',
    },
  },
  userTableTitle: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      position: 'absolute',
      right: '43px',
    },
  },
  tableEdit: {
    width: '20%',
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  disableRow: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  openLink: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    color: theme.palette.usgColors.others.mix.blue[96],
  },
  deleteActions: {
    justifyContent: 'center',
    paddingBottom: '20px',
  },
  addUserPopup: {
    justifyContent: 'left',
    marginLeft: '15px',
    marginBottom: '15px',
  },
  emptyRecords: {
    height: '200px',
    '& td': {
      textAlign: 'center',
      fontWeight: 300,
    },
  },
  tootipForRoles: {
    '& span': {
      textTransform: 'capitalize',
      fontSize: '11px',
    },
  },
  tooltipHeading: {
    fontSize: '12px',
    paddingBottom: '8px',
  },
  deleteUserIcon: {
    '& tbody td:last-child': {
      padding: '5px 115px !important',
    },
  },
  resourcePaper: {
    padding: '0px 20px 20px',
    marginTop: '20px',
  },
  btnAlign: {
    textAlign: 'end',
  },
  addUserSection: {
    paddingBottom: '20px',
    width: '600px',
  },
  clearBtn: {
    marginRight: '20px',
  },
  azureLink: {
    color: 'blue',
  },
  emptyData: {
    padding: '80px',
    fontWeight: 900,
    fontSize: '16px',
  },
  icon: {
    width: '44px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(196, 196, 196, 0.8)',
    boxShadow: '0 0px 0px 6px rgba(196, 196, 196, 0.4)',
    margin: '6px',
    color: theme.palette.usgColors.primary.white,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '15px',
  },
  hyperLink: {
    color: theme.palette.usgColors.primary.blue,
    textDecoration: 'underline',
  },
  comingSoonContainer: {
    height: '10em',
  },
  support: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    maxWidth: '50%',
    right: '20px',
    zIndex: 1,
  },
  pageInfoIconWrapper: {
    backgroundColor: theme.palette.usgColors.primary.blue60,
    borderRadius: '100%',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: theme.palette.usgColors.secondary.blue,
      cursor: 'pointer',
    },
  },
  pageInfoIconMenu: {
    marginRight: '25px',
    marginTop: '40px',
  },
  restrictionsInfoIcon: {
    fontSize: '19px',
  },
  disableRestrictions: {
    opacity: 0.5,
    cursor: 'not-allowed',
    marginTop: '0.5px',
    '& div': {
      pointerEvents: 'none',
    },
  },
  enabledRestrictions: {
    marginTop: '0.5px',
  },
  disableSharingSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  shareSettingsInfoIcon: {
    fontSize: '19px',
    marginTop: '10px',
    marginRight: '20px',
  },
  tooltip: {
    color: theme.palette.textContrast,
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
  },
  tooltipArrow: {
    color: theme.palette.usgColors.secondary.silverGray,
  },
  hyperlink: {
    color: theme.palette.usgColors.others.mix.blue[200],
    textDecoration: 'underline',
  },
  search: {
    margin: '0 0 2em 0',
    width: '100%',
    '& .MuiFormHelperText-contained ': {
      paddingBottom: '0 !important',
    },
  },
  marginY: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  copilotText: {
    paddingTop: '20px',
    marginLeft: '0px',
    alignItems: 'flex-start',
    '& span': {
      fontSize: '15px',
      fontWeight: '700',
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.usgColors.primary.black,
    },
  },
}));
