import { useCallback, useEffect, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { projectApiRef } from '../../../../api';
import { ProjectDetails } from '../types';

const useProjectHook = (projectId: string, resourceId: string) => {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [projectData, setProjectData] = useState<ProjectDetails | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const getProjectData = useCallback(async () => {
    const idToken = await authref.getIdToken();
    const params = {
      manipulators: [
        'resources',
        `resource_${resourceId}`,
        'is_combined',
        'user_groups',
      ],
    };

    try {
      const data = await projectApi.getProjectByID(projectId, idToken, params);
      setProjectData(data);
    } catch (error) {
      errorApi.post(new Error(`${error?.message}`));
    }
    setLoading(false);
  }, [projectId, resourceId, authref, errorApi, projectApi]);

  useEffect(() => {
    if (projectId === '' || resourceId === '') {
      return;
    }
    getProjectData();
  }, [projectId, resourceId, getProjectData]);

  return { id: projectId, data: projectData, loading };
};

export default useProjectHook;
