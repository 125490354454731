import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  text: {}, // needed for textLink styling
  textLink: {
    color: theme.palette.usgColors.secondary.blue,
    display: 'inline-block',
    '&:hover': {
      '& $text': {
        textDecoration: 'underline',
      },
    },
  },
  icon: {},
  primaryLink: {
    color: theme.palette.textContrast,
    fontWeight: 700,
    '& $icon': {
      color: theme.palette.usgColors.primary.blue,
    },
    '&:hover': {
      color: theme.palette.usgColors.secondary.blue,
      '& $icon': {
        color: theme.palette.usgColors.primary.blue,
      },
    },
  },
  secondaryLink: {
    color: theme.palette.usgColors.primary.black,
    fontWeight: 700,
    '&:hover': {
      color: theme.palette.usgColors.primary.gray,
    },
  },
  tertiaryLink: {
    color: theme.palette.usgColors.secondary.darkRed,
    fontWeight: 700,
    '&:hover': {
      color: theme.palette.usgColors.primary.red,
    },
  },
  disabled: {
    color: theme.palette.usgColors.secondary.mediumGray,
    pointerEvents: 'none',
  },
}));
