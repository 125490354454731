import React from 'react';
import Button from '@mui/material/Button';
import { Grid } from '@material-ui/core';
import Typography from '@mui/material/Typography';

const GRID_ITEM_VAL = 2;

type ViewMoreLessButtonProps = {
  isViewMore: boolean;
  changeDisplayType: () => void;
};

export const ViewMoreLessButton = ({
  isViewMore,
  changeDisplayType,
}: ViewMoreLessButtonProps) => {
  return (
    <Grid item xs={GRID_ITEM_VAL}>
      <Button
        id="sg-ui-kit-button-viewmoreless"
        variant="text"
        onClick={() => changeDisplayType()}
      >
        <Typography
          variant="button"
          color="primary"
          sx={{
            fontSize: '12px',
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          {isViewMore ? 'View less' : 'View more'}
        </Typography>
      </Button>
    </Grid>
  );
};
