export type TUserGroupAttachment = {
  usergroup_id: string;
  role: string;
  usersCount: number;
};

export const emptyAttachUserGroup = {
  usergroup_id: '',
  role: '',
  usersCount: 0,
};

export const initalAttachUserGroups = [emptyAttachUserGroup];

export type UserGroupAttachmentRequest = {
  id: string;
  role: string;
};
