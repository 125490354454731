import React from 'react';
import { TextField } from '@material-ui/core';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_REGEX,
} from 'usg-types';
import { GenricResourcePropsType } from './Types';
import { useTranslation } from '../../../hooks/useTranslation';

type specificPropsType = {
  nameDirty: boolean;
  setNameDirty: React.Dispatch<React.SetStateAction<boolean>>;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
};

const ConfluenceViewEdit = (
  props: React.PropsWithChildren<GenricResourcePropsType<specificPropsType>>,
) => {
  const { eventType, resourceData, formik, specificProps, classes } = props;
  const { nameDirty, setNameDirty, descriptionDirty, onDescriptionClick } =
    specificProps;
  const { t } = useTranslation();

  const viewCase = (
    <>
      <h4 className={classes.labelHeader}>
        {t('resource.view.confluence.spaceKeyLabel')}
      </h4>
      <p id="resource-key-value" className={classes.viewTag}>
        {resourceData?.key}
      </p>
      <h4 className={classes.labelHeader}>
        {t('resource.view.confluence.spaceNameLabel')}
      </h4>
      <p id="resource-name-value" className={classes.viewTag}>
        {resourceData?.name}
      </p>
      {resourceData?.description ? (
        <>
          <h4 className={classes.labelHeader}>
            {t('resource.view.confluence.descriptionLabel')}
          </h4>
          <p id="resource-name-description" className={classes.viewTag}>
            {resourceData?.description ? resourceData.description : ''}
          </p>
        </>
      ) : null}
    </>
  );

  const editCase = (
    <>
      <h4 className={classes.labelHeader}>
        {t('resource.view.confluence.spaceKeyLabel')}
      </h4>
      <p className={classes.viewTag}>{resourceData?.key}</p>
      <h4 className={classes.labelHeader}>
        {t('resource.view.confluence.spaceNameLabel')}*
      </h4>
      <TextField
        style={{ paddingBottom: '0%' }}
        type="text"
        id="resourceName"
        name="resourceName"
        required
        error={
          (formik?.values?.resourceName?.length <
            RESOURCE_NAME_MIN_LENGTH.confluence &&
            nameDirty === true) ||
          (!formik?.values.resourceName.match(RESOURCE_NAME_REGEX) &&
            nameDirty) ||
          (formik?.values?.resourceName?.length >
            RESOURCE_NAME_MAX_LENGTH.confluence &&
            nameDirty === true)
        }
        onChange={formik.handleChange}
        value={formik.values.resourceName}
        onClick={() => setNameDirty(true)}
        fullWidth
        helperText={t('resource.view.confluence.spaceNameHelperText', {
          /** @ts-ignore */
          min: RESOURCE_NAME_MIN_LENGTH.confluence,
          max: RESOURCE_NAME_MAX_LENGTH.confluence,
        })}
      />
      <h4 className={classes.labelHeader}>
        {t('resource.view.confluence.descriptionLabel')}
      </h4>
      <TextField
        id="resource-description"
        name="resourceDescription"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.resourceDescription}
        rows={3}
        multiline
        fullWidth
        error={
          formik?.values?.resourceDescription?.length >
            RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
        }
        helperText={t('resource.view.confluence.descriptionHelperText', {
          /** @ts-ignore */
          max: RESOURCE_DESCRIPTION_MAX_LENGTH,
        })}
        placeholder={t('resource.view.confluence.descriptionPlaceholder')}
        onClick={onDescriptionClick}
      />
    </>
  );

  switch (eventType) {
    case 'edit':
      return editCase;
    default:
      return viewCase;
  }
};

export default ConfluenceViewEdit;
