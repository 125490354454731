import React from 'react';

import { Field } from 'formik';

import { Grid } from '@material-ui/core';
import Typography from '@mui/material/Typography';

import { SGEmailChipInput, SGEmailListGrid } from '@internal/sg-ui-kit';
import { EMAIL_REGEX, INVALID_EMAIL_ERROR } from 'usg-types';

import { useStyles } from './styles';

const Title = (props: any) => {
  const classes = useStyles();
  const { isEdit } = props;

  if (isEdit) {
    return <h4 className={classes.labelHeader}>Resource Manager(s)</h4>;
  }
  return <h4 className={classes.labelHeader}>Resource Managers</h4>;
};

const NonEditManagers = (props: any) => {
  const { managers } = props;

  if (managers === undefined || managers?.length === 0) {
    return (
      <Typography variant="body2" gutterBottom>
        Not Specified
      </Typography>
    );
  }

  return (
    <SGEmailListGrid
      emails={managers.map((m: { user_email: string }) => m.user_email)}
    />
  );
};

const ResourceManagersField = (props: any) => {
  const classes = useStyles();

  const { setIsAddManagerEnabled, checkUserValidity, isAddManagerEnabled } =
    props;

  const checkAddUserValidity = async (email: string) => {
    const isAddUserValid = await checkUserValidity(email);
    setIsAddManagerEnabled(isAddUserValid && isAddManagerEnabled);
    return isAddUserValid;
  };

  const handleDeleteCallBack = (isError: boolean) => {
    setIsAddManagerEnabled(!isError);
  };

  const handleBeforeAddCallBack = (isError: boolean) => {
    setIsAddManagerEnabled(!isError);
  };

  const handleLoadingCallBack = (addUserloading: boolean) => {
    setIsAddManagerEnabled(!addUserloading);
  };

  const handleBlurInputCallBack = (isError: boolean) => {
    setIsAddManagerEnabled(!isError);
  };

  return (
    <Field
      name="addResourceManagers"
      data-testid="resource-managers-field"
      className={classes.resourceManagerGrid}
      component={SGEmailChipInput}
      required
      variant="outlined"
      patternMatch={EMAIL_REGEX}
      helperText="Enter user emails to assign resource managers. Press
                  enter or space key to add email id to the list. Press
                  ‘Save’ to save the changes."
      errorText={INVALID_EMAIL_ERROR}
      checkValidity={checkAddUserValidity}
      handleDeleteCallBack={handleDeleteCallBack}
      handleBeforeAddCallBack={handleBeforeAddCallBack}
      handleLoadingCallBack={handleLoadingCallBack}
      handleBlurInputCallBack={handleBlurInputCallBack}
    />
  );
};

const EditManagers = (props: any) => {
  const classes = useStyles();

  const { setIsAddManagerEnabled, checkUserValidity, isAddManagerEnabled } =
    props;

  return (
    <Grid container className={classes.resourceManagersEditModeContainer}>
      <Grid item xs={12}>
        <ResourceManagersField
          setIsAddManagerEnabled={setIsAddManagerEnabled}
          checkUserValidity={checkUserValidity}
          isAddManagerEnabled={isAddManagerEnabled}
        />
      </Grid>
    </Grid>
  );
};

const Content = (props: any) => {
  const {
    loading,
    isEdit,
    managers,
    checkUserValidity,
    setIsAddManagerEnabled,
    isAddManagerEnabled,
  } = props;

  if (!loading && isEdit) {
    return (
      <EditManagers
        setIsAddManagerEnabled={setIsAddManagerEnabled}
        checkUserValidity={checkUserValidity}
        isAddManagerEnabled={isAddManagerEnabled}
      />
    );
  }
  return <NonEditManagers managers={managers} />;
};

export const ResourceManagersComponent = (props: any) => {
  const classes = useStyles();

  const {
    managers,
    loading,
    setIsAddManagerEnabled,
    isAddManagerEnabled,
    checkUserValidity,
    isEdit,
  } = props;

  return (
    <Grid container className={classes.resourceManagersNonEditModeContainer}>
      <Grid item xs={12} className={classes.resourceManagerHeadingContainer}>
        <Title isEdit={isEdit} />
      </Grid>
      <Grid item xs={12}>
        <Content
          loading={loading}
          isEdit={isEdit}
          isAddManagerEnabled={isAddManagerEnabled}
          checkUserValidity={checkUserValidity}
          managers={managers}
          setIsAddManagerEnabled={setIsAddManagerEnabled}
        />
      </Grid>
    </Grid>
  );
};
