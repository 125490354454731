import { useCallback, useEffect, useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { BREADCRUMBS } from 'usg-types';

const useBreadcrumbsFlag = () => {
  const [isBreadCrumbsFlagEnabled, setIsBreadCrumbsFlagEnabled] = useState<
    boolean | null
  >(null);
  const [loadingBreadcrumbsFlag, setLoadingBreadcrumbsFlag] =
    useState<boolean>(true);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  const getFeatureFlag = useCallback(async () => {
    setLoadingBreadcrumbsFlag(true);
    const isEnabled = await featureFlagsApi.getBinaryFlag(BREADCRUMBS);
    setIsBreadCrumbsFlagEnabled(isEnabled.data);
    setLoadingBreadcrumbsFlag(false);
  }, [featureFlagsApi, setIsBreadCrumbsFlagEnabled]);

  useEffect(() => {
    getFeatureFlag();
  }, [getFeatureFlag]);

  return { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag };
};

export default useBreadcrumbsFlag;
