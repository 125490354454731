import React from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const PAGE_TITLES: Record<string, string> = {
  '/': 'Stargate',
  '/search': 'Search | Stargate',
  '/projects': 'Project Workspace | Stargate',
  '/notifications': 'Notifications | Stargate',
};

interface PageTitleProps {
  customPageTitle?: string;
}

/**
 * A wrapper component to set the title dynamically.
 *
 * @param PageTitleProps
 * @returns Helmet component.
 */
export const PageTitle = ({ customPageTitle }: PageTitleProps) => {
  const location = useLocation();
  const title = PAGE_TITLES[location.pathname] || customPageTitle || 'Stargate';
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
