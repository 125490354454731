import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { DialogAddEnvironmentConfirmation } from './DialogAddEnvironmentConfirmation';
import { ENVIRONMENTS, TabLabel, TabsProps } from './types';
import { Progress } from '@backstage/core-components';
import { projectApiRef } from '../../../../../api';
import {
  useApi,
  errorApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

const MAX_ENV = 3;

export const VaultTabs = ({
  onChange,
  formik,
  setIsEdit,
  value,
  resourceData,
  projectId,
  fetchContent,
  setIsContentVisible,
  isAddEnvEnabled,
  resourceIdForCreatingNewEnv,
}: TabsProps) => {
  const [newEnv, setNewEnv] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [tabs, setTabs] = useState<TabLabel[]>([]);
  const [menus, setMenus] = useState<string[]>([]);
  const [apiLoader, setApiLoader] = useState(false);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const authref = useApi(microsoftAuthApiRef);

  useEffect(() => {
    const linkResources = resourceData.linkedResources ?? [];
    if (linkResources.length === 0) {
      return;
    }

    const environments = linkResources.map(
      (item: { environment: string }) =>
        ENVIRONMENTS.filter(i => i.name === item.environment)[0],
    );

    setTabs(environments);

    const linkedEnvironments = environments.map((i: TabLabel) => i.label);
    const missingEnvs = ENVIRONMENTS.filter(
      i => !linkedEnvironments.includes(i.label),
    ).map(i => i.label);
    setMenus(missingEnvs);
  }, [resourceData?.linkedResources]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmitCreateVault = async (newEnvi: string) => {
    setIsEdit(false);
    formik.resetForm({});

    let fine = true;
    let envToCreate = '';
    for (let i = 0; i < ENVIRONMENTS.length; i++) {
      if (ENVIRONMENTS[i].label === newEnvi) {
        envToCreate = ENVIRONMENTS[i].name;
      }
    }

    const idToken = await authref.getIdToken();
    setApiLoader(true);
    const resourceId = resourceIdForCreatingNewEnv();
    const params: any = {
      idToken,
      environments: [{ environment: envToCreate }],
    };
    try {
      const rsp: any = await projectApi.createNewProjectResourceAsPerEnv(
        Number(projectId),
        resourceId,
        params,
      );
      if (rsp && rsp.error) {
        throw new Error(rsp.error.message);
      }

      const createdEnvs = [];
      rsp.result.forEach((item: any) => {
        if (
          item.status === 'fulfilled' &&
          (item.value.status === 200 || item.value.status === 201)
        ) {
          createdEnvs.push(item.value.data.environment);
        }
      });
      if (createdEnvs.length !== 1) {
        fine = false;
        errorApi.post(
          new Error(
            `Something went wrong, resource creation for ${newEnvi} environment was not successful`,
          ),
        );
      }
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
      fine = false;
    }

    setApiLoader(false);
    if (fine) {
      localStorage.setItem('vault_environment_lastselectedtab', envToCreate);
      setIsContentVisible(true);
      await fetchContent();
      setIsContentVisible(false);
    }
  };

  const addEnv = async (newEnvi: string) => {
    setOpenDialog(false);
    await onSubmitCreateVault(newEnvi);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setNewEnv(event.currentTarget.textContent ?? '');
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const displayEnvironmentMenu = () => {
    return (
      <Menu
        id="new-environment-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menus.map((env, index) => (
          <MenuItem
            id={`project-menuitem-${env}`}
            key={index}
            onClick={handleMenuItemClick}
          >
            {env}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <Grid container sx={{ borderBottom: 2, borderBottomColor: 'grey.300' }}>
      <Grid item>
        <Tabs value={value} onChange={onChange} id="environment-tabs">
          {tabs.map((tab, index) => (
            <Tab
              id={`project-tab-${tab.label}`}
              key={index}
              label={tab.label}
              sx={{ fontWeight: 'bold' }}
            />
          ))}
        </Tabs>
      </Grid>

      <Button
        onClick={handleClick}
        disabled={tabs.length === MAX_ENV || !isAddEnvEnabled}
        id="btn-add-environment"
      >
        <AddIcon />
      </Button>
      {apiLoader ? <Progress /> : ''}
      {displayEnvironmentMenu()}
      <DialogAddEnvironmentConfirmation
        isOpen={openDialog}
        setIsOpen={setOpenDialog}
        env={newEnv}
        confirmArchive={addEnv}
        setEnv={setNewEnv}
      />
    </Grid>
  );
};
