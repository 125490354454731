import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  defaultChipInputStyles: {
    height: '38px',
    marginBottom: '12px',
    '& .MuiFormHelperText-root': {
      marginTop: 0,
    },
  },
  chip: {
    '& .MuiChip-label': {
      textTransform: 'none',
    },
  },
  invalidChip: {
    color: theme.palette.usgColors.secondary.darkRed,
    backgroundColor: theme.palette.usgColors.others.mix.red[1],
    '& .MuiChip-deleteIcon, .MuiChip-icon': {
      color: theme.palette.usgColors.secondary.darkRed,
      fontSize: '1.3em',
    },
  },
  loadingChip: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
    '& .MuiChip-deleteIcon, .MuiChip-icon': {
      color: 'rgba(0, 0, 0, 0.26)',
      fontSize: '1.3em',
    },
    '& .MuiCircularProgress-indeterminate': {
      height: '25px !important',
      width: '25px !important',
    },
  },
  errorText: {
    learnMore: {
      color: theme.palette.usgColors.secondary.darkRed,
      fontWeight: 'bold',
    },
  },
}));
