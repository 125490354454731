import { useEffect, useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { useAsyncFn } from 'react-use';
import { VAULT_REDUCED_API_CALLS } from 'usg-types';

const useVaultReducedApiCalls = () => {
  const [isVaultReducedCallEnabled, setIsVaultReducedCallEnabled] =
    useState<boolean>(false);

  const featureFlagsApi = useApi(featureFlagsApiRef);

  const [
    { loading: loadingVaultReducedCallFlag = true as boolean },
    getFeatureFlag,
  ] = useAsyncFn(async () => {
    const isEnabled = await featureFlagsApi.getBinaryFlag(
      VAULT_REDUCED_API_CALLS,
    );
    setIsVaultReducedCallEnabled(isEnabled.data);
  }, [featureFlagsApi]);

  useEffect(() => {
    getFeatureFlag();
  }, [getFeatureFlag]);

  return { isVaultReducedCallEnabled, loadingVaultReducedCallFlag };
};

export default useVaultReducedApiCalls;
