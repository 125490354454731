import React, { useState } from 'react';
import {
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { SGRowItem } from '@internal/sg-ui-kit';
import {
  commonDisableRoleSelection,
  commonHideOwnerResources,
  getTechDocsLink,
} from 'sg-utils-frontend';
import { DetachResource } from '../../DetachResource';
import { Link } from '@backstage/core-components';
import { UpdateResourceRole } from '../../UpdateResourceRole';
import { AttachUserGroupToResource } from '../../AttachUserGroupToResource';
import { useTranslation } from '../../../hooks/useTranslation';
import { TABS_USERGROUPS_TRANSLATION_PREFIX } from 'usg-types';
import { useStyles } from './styles';

export interface UserGroupsTabIProps {
  attachedUserGroups: Array<any>;
  projectId: string;
  fetchUserGroupResources: any;
  loadingFetchUserGroupResources: any;
  isAdmin: boolean;
  isOwner: boolean;
  resourceData: any;
  attachableUserGroups: Array<any>;
  isResourceArchived: boolean;
  resourceManagerCheck: boolean;
  isGithubEMU?: boolean;
}

export const UserGroupsTab = (props: UserGroupsTabIProps) => {
  const {
    projectId,
    attachedUserGroups,
    fetchUserGroupResources,
    loadingFetchUserGroupResources,
    isAdmin,
    isOwner,
    resourceData,
    attachableUserGroups,
    isResourceArchived,
    resourceManagerCheck,
    isGithubEMU = false,
  } = props;

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [showDetachResource, setShowDetachResource] = useState(false);
  const [showUpdateResource, setShowUpdateResource] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [resourcesResult, setResourcesResult] = useState({
    success: [],
    fail: [],
  });
  const [selectedUserGroup, setSelectedUserGroup] = useState({
    name: '',
    id: 0,
    role: '',
  });
  const { t } = useTranslation();
  let changeRoleActionEnabled = true;
  let detachAssignActionEnabled = true;
  const resHasRoles = resourceData?.roles.length > 0;
  if (
    (!isAdmin && !isOwner && !resourceManagerCheck) ||
    commonDisableRoleSelection(resourceData.dev_tool_id) ||
    !resHasRoles
  ) {
    changeRoleActionEnabled = false;
  }

  if (
    (!isAdmin && !isOwner && !resourceManagerCheck) ||
    (!isAdmin && commonHideOwnerResources(resourceData.dev_tool_id))
  ) {
    detachAssignActionEnabled = false;
  }

  const openSuccessMessage = (resourcesResult.success.length > 0 ||
    isUpdateSuccess) && (
    <>
      <Typography variant="body2">
        <Box fontWeight={700}>
          {t(
            `${TABS_USERGROUPS_TRANSLATION_PREFIX}.usergroupAssignmentSuccess`,
          )}{' '}
          <Link
            className={classes.hyperLink}
            to={getTechDocsLink('dev-tool-sync')}
            target="_blank"
          >
            {t('common.link.learnMoreHere')}
          </Link>
        </Box>
      </Typography>
      {resourcesResult.fail.length > 0 && (
        <Divider style={{ margin: '10px' }} />
      )}
    </>
  );

  const openFailMessage = resourcesResult.fail.length > 0 && (
    <div style={{ color: '#CC0000' }}>
      <Box mt={1} display="flex" alignItems="center">
        <span style={{ alignSelf: 'center', paddingRight: '5px' }}>
          <ErrorIcon htmlColor="#CC0000" />
        </span>
        <span>
          {t(`${TABS_USERGROUPS_TRANSLATION_PREFIX}.usergroupAssignmentError`)}
        </span>
      </Box>
      <ul>
        {resourcesResult.fail.map((res: any) => {
          return (
            <li key={`error-resource-${res.id}`}>
              {res.name} : {res.message} (code {res.status})
            </li>
          );
        })}
      </ul>
    </div>
  );

  const devtoolsRolesMapping = resourceData.roles.reduce(
    (roleMapping: any, role: any) => {
      roleMapping[role.name] = role.display_name;
      return roleMapping;
    },
    {},
  );

  const userGroupsSection = attachedUserGroups?.flatMap((ug: any) => {
    if (ug.archived) return false;
    return (
      <div key={`user_group_${ug.id}`}>
        <SGRowItem
          title={{ text: ug.name, id: `ugname-${ug.id}` }}
          secondaryText={{
            text: devtoolsRolesMapping[ug.role],
            id: `currentrole-ugid-${ug.id}`,
          }}
          linkTo={`/projects/${projectId}/usergroup/${ug.id}`}
          key={`ug_row_${ug.id}`}
          actions={[
            {
              label: t(
                `${TABS_USERGROUPS_TRANSLATION_PREFIX}.changeRoleButton`,
              ),
              onClick: () => {
                setSelectedUserGroup({
                  name: ug.name,
                  id: ug.id,
                  role: ug.role,
                });
                setShowUpdateResource(true);
              },
              props: {
                id: `btn-change-role-ugid-${ug.id}`,
              },
              visible: changeRoleActionEnabled,
            },
            {
              label: t(
                `${TABS_USERGROUPS_TRANSLATION_PREFIX}.detachGroupButton`,
              ),
              onClick: () => {
                setSelectedUserGroup({
                  name: ug.name,
                  id: ug.id,
                  role: ug.role,
                });
                setShowDetachResource(true);
              },
              visible: detachAssignActionEnabled,
              props: {
                id: `btn-detach-group-ugid-${ug.id}`,
              },
            },
          ]}
        />
      </div>
    );
  });

  if (loadingFetchUserGroupResources) {
    return (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{ flexDirection: 'column' }}
      >
        <CircularProgress /> Fetching resources
      </Grid>
    );
  }
  const handleSuccessClose = async () => {
    setOpenSuccess(false);
    setIsUpdateSuccess(false);
    setResourcesResult({
      success: [],
      fail: [],
    });
    await fetchUserGroupResources(projectId);
  };

  return (
    <div className={classes.deleteUserIcon}>
      <Grid container justifyContent="space-between">
        <Grid item xs={9}>
          {userGroupsSection}
        </Grid>
        <Grid item>
          {!isResourceArchived &&
            (isAdmin ||
              (isOwner && detachAssignActionEnabled) ||
              resourceManagerCheck) && (
              <Button
                id="projects-button-assign-user-group"
                variant="contained"
                color="default"
                onClick={() => setShow(true)}
              >
                {t(
                  `${TABS_USERGROUPS_TRANSLATION_PREFIX}.assignUserGroupsButton`,
                )}
              </Button>
            )}
        </Grid>
      </Grid>
      <AttachUserGroupToResource
        isVisible={show}
        handleClose={() => {
          setShow(false);
        }}
        projectId={projectId}
        userGroups={attachableUserGroups}
        isAdmin={isAdmin}
        resourceData={resourceData}
        handleSuccess={() => {
          setOpenSuccess(true);
          setIsUpdateSuccess(true);
        }}
        isGithubEMU={isGithubEMU}
      />
      <DetachResource
        isVisible={showDetachResource}
        handleClose={() => {
          setShowDetachResource(false);
        }}
        handleSuccess={() => {
          setOpenSuccess(true);
          setIsUpdateSuccess(true);
        }}
        projectId={projectId}
        groupId={selectedUserGroup?.id.toString()}
        resourceId={resourceData?.id}
        resourceName={resourceData?.name}
        groupData={selectedUserGroup} // groupdata should be removed on feautre flag removal
        tab="usergroups"
      />
      <UpdateResourceRole
        isVisible={showUpdateResource}
        handleClose={() => {
          setShowUpdateResource(false);
          setSelectedUserGroup({
            name: '',
            id: 0,
            role: '',
          });
        }}
        handleSuccess={() => {
          setOpenSuccess(true);
          setIsUpdateSuccess(true);
        }}
        projectId={projectId}
        resourceId={resourceData?.id}
        resourceName={resourceData?.name}
        resourceRole={selectedUserGroup?.role}
        groupData={selectedUserGroup} // groupdata should be removed on feautre flag removal
        resourceRoleOptions={resourceData?.roles}
      />
      <Dialog
        open={openSuccess}
        onClose={handleSuccessClose}
        maxWidth="xs"
        style={{ top: '5' }}
        className={classes.dialog}
      >
        <DialogContent>
          {/** we're showing both success and failed responses  */}
          {openSuccessMessage}
          {openFailMessage}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            id="btn-assign-resource-modal-close"
            size="small"
            onClick={handleSuccessClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
