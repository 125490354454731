import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { Content, PageLayoutWithSearchBar } from '@internal/sg-ui-kit';
import { Divider, Grid } from '@material-ui/core';
import { LastViewedDocuments } from './LastViewedDocuments';
import { FeaturedCardSection } from './FeaturedCardSection';
import { PORTAL_PAGES_TITLES } from 'usg-types';
import { BinaryFeatureFlagged, PageTitle } from '@internal/sg-ui-kit';

export type CustomTechDocsHomeProps = {
  groups?: Array<{
    title: React.ReactNode;
    filterPredicate: ((entity: Entity) => boolean) | string;
  }>;
};

export const CustomTechDocsHome = () => {
  return (
    <PageLayoutWithSearchBar
      title="Stargate Documentation"
      subtitle="Stargate Welcome Guide"
      headerBackgroundImg="assets/welcome_bg.webp"
    >
      <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
        <PageTitle customPageTitle="Documentation | Stargate" />
      </BinaryFeatureFlagged>
      <Content>
        <Grid container spacing={4}>
          <FeaturedCardSection />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <LastViewedDocuments />
        </Grid>
      </Content>
    </PageLayoutWithSearchBar>
  );
};
