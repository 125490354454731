import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { Progress } from '@backstage/core-components';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { usePermissions } from '@internal/plugin-projects';
import {
  BinaryFeatureFlagged,
  ContactUsLink,
  PageLayout,
  PageTitle,
  SupportButton,
  SupportTextList,
} from '@internal/sg-ui-kit';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import { getLocalStorageWithExpiry } from 'sg-utils-frontend';
import { USG_COLOR_SCHEME } from 'usg-theme';
import { projectApiRef } from '../../api';
import useBreadcrumbsFlag from '../../hooks/useBreadcrumbsFlag';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { projectPageRouteRef, rootRouteRef } from '../../routes';
import { ROOT_URL_LABEL_BREADCRUMBS } from '../ViewEditResources/ViewEditResourcePage/types';
import { OwnerPageActions } from './OwnerPageActions';
import { PORTAL_PAGES_TITLES } from 'usg-types';

const useStyles = makeStyles({
  requestAccessBtn: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  },
  cardDsc: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  supportBtn: {
    marginTop: '20px',
  },
  alert: {
    width: '853px',
    background: USG_COLOR_SCHEME.others.blue[400],
    opacity: 0.08,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    fontSize: '14px',
    fontWeight: 400,
  },
  groupText: {
    textTransform: 'uppercase',
  },
});

export const ProjectOwnerPage = () => {
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const rootLink = useRouteRef(rootRouteRef);

  const [searchParams] = useSearchParams();
  const userToInvite = searchParams.get('usertoinvite');
  const projectId = searchParams.get('id') as any;
  const navigate = useNavigate();
  const projectPageRoute = useRouteRef(projectPageRouteRef);
  const errorApi = useApi(errorApiRef);
  const [open, setOpen] = React.useState(false);
  const [filteredUsergroups, setFilteredUsergroups] = React.useState<any>([]);
  const { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag } =
    useBreadcrumbsFlag();

  const classes = useStyles();

  const [selectedGroupItems, setSelectedGroupItems] = useState<any[]>([]);
  const {
    isProjectOwner,
    isLoading: rolesCheckLoading,
    error: permissionsError,
  } = usePermissions();

  const handleChange = (e: any) => {
    if (e.target.checked) {
      setSelectedGroupItems([
        ...selectedGroupItems,
        {
          user_group_id: Number(e.target.value),
          user_email: userToInvite || '{}',
          action: 'ADD',
        },
      ]);
    } else {
      setSelectedGroupItems(
        selectedGroupItems.filter(
          choice => choice.user_group_id !== parseInt(e.target.value, 10),
        ),
      );
    }
  };

  // Back to Projects list page
  const backToLink = React.useMemo(
    () => ({
      to: rootLink(),
      label: 'Back to Projects',
    }),
    [rootLink],
  );

  // To get perticular project details based on projectId
  const [{ value: data = [] as any, loading, error }, fetchContent] =
    useAsyncFn(async () => {
      const idToken = await authref.getIdToken();
      const params = {
        manipulators: ['user_groups'],
      };
      let projectData;
      try {
        projectData = await projectApi.getProjectByID(
          projectId,
          idToken,
          params,
        );
        const code = projectData?.status;
        if (code === 403) {
          setOpen(true);
        }

        if (projectData) {
          setFilteredUsergroups(
            projectData?.user_groups?.filter(
              (x: any) =>
                !x?.deleted_on &&
                x?.sharing_properties?.source_project_id ===
                  parseInt(projectId, 10),
            ),
          );
        }
      } catch (err: any) {
        errorApi.post(new Error(`${err?.message}`));
      }
      return projectData;
    }, [projectId]);
  const language = getLocalStorageWithExpiry('locale');

  React.useEffect(
    () => {
      fetchContent();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (loading || rolesCheckLoading || loadingBreadcrumbsFlag) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  const handleClose = () => {
    navigate(projectPageRoute({ projectId: projectId }));
  };

  const handleOkButton = () => {
    navigate(projectPageRoute({ projectId: projectId }));
  };

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: data?.id,
      display: data?.name,
    },
    {
      isAbsolute: true,
      path: `?id=${projectId}&usertoinvite=${userToInvite}`,
      display: 'Request for Access',
    },
  ];

  return (
    <>
      <Grid>
        <PageLayout
          type="entity"
          title={data?.name}
          headerAdditionalControls={
            <ProjectOwners owners={data?.owners as any} />
          }
          backToLink={isBreadCrumbsFlagEnabled ? breadcrumbs : backToLink}
          children={undefined}
        />
      </Grid>
      <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
        <PageTitle
          customPageTitle={`Request for Access to ${data.name} | Stargate`}
        />
      </BinaryFeatureFlagged>
      {isProjectOwner(Number(projectId)) ? (
        <div>
          <Grid container spacing={2} style={{ paddingLeft: '2%' }}>
            <Grid item xs={5}>
              <h1>Review Access Request</h1>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={2} className={classes.supportBtn}>
              <SupportButton>
                <SupportTextList>
                  <Typography variant="body2">
                    Got any questions/suggestions? Please{' '}
                    <ContactUsLink text="Contact Us" language={language} /> and
                    we will try our best to help you out.
                  </Typography>
                </SupportTextList>
              </SupportButton>
            </Grid>
          </Grid>
          <div style={{ paddingLeft: '2%' }}>
            <p style={{}}> {userToInvite} is requesting access to Project.</p>

            <h3>Add to Group(s)*</h3>
            <FormHelperText style={{ paddingBottom: '1%' }}>
              {' '}
              Select atleast one group to proceed with approval
            </FormHelperText>
          </div>
          <div style={{ paddingLeft: '2%' }}>
            <Grid container spacing={2} style={{ paddingRight: '2%' }}>
              {filteredUsergroups?.map((group: any) => (
                <Grid item xs={4}>
                  <Card style={{ maxWidth: '400px', minHeight: '200px' }}>
                    <CardContent>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.groupText}
                        id={`user-group-card-${group?.id}`}
                      >
                        <Checkbox
                          id={`user-group-card-checkbox-${group?.id}`}
                          value={group.id}
                          onChange={handleChange}
                        />{' '}
                        {group.name}
                      </Typography>
                      <Typography className={classes.cardDsc}>
                        {group.description}
                      </Typography>
                      <br />
                      <br />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
          <OwnerPageActions
            selectedGroupItems={selectedGroupItems}
            projectId={projectId}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              You don't have permissions to approve the request.
            </DialogTitle>
            <DialogContent />
            <DialogActions>
              <Button
                id="projects-button-ok"
                variant="contained"
                className={classes.requestAccessBtn}
                onClick={handleOkButton}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Grid container spacing={2} style={{ paddingLeft: '2%' }}>
            <Grid item xs={5}>
              <h1>Project Access Request</h1>
            </Grid>
            <Alert
              severity="info"
              className={classes.alert}
              id="no-permission-alert"
            >
              <AlertTitle>Request Raised</AlertTitle>
              <div className={classes.header}>
                Hi, You have already raised an access request for this project.
                Please contact the project owner(s) for more support on your
                request.
              </div>
            </Alert>
          </Grid>
        </div>
      )}
    </>
  );
};
