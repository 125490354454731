import React, { Dispatch, SetStateAction, useState } from 'react';

import { Button, DialogContent, Typography } from '@material-ui/core';

import { WarningMessage } from '../WarningMessage/WarningMessage';
import { ResourceSelectRow } from './ResourceSelectRow';
import { emptyAttachUserGroup, TUserGroupAttachment } from './types';

import { useEffectOnce } from 'react-use';
import {
  ADD_USER_WARNING_MESSAGE,
  NEW_API_GET_USER_MEMBER_LIST,
} from 'usg-types';
import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';

import { useStyles } from './styles';

type AttachUserGroupContentProps = {
  userGroupAttachments: TUserGroupAttachment[];
  setDuplicateError: Dispatch<SetStateAction<string>>;
  setUserGroupAttachments: Dispatch<SetStateAction<TUserGroupAttachment[]>>;
  projectId: string;
  setAttachError: Dispatch<SetStateAction<string>>;
  isLoadingUserGroup: boolean;
  isGithubEMU: boolean;
  attachError: string;
  duplicateError: string;
  userGroups: any;
  resourceData: any;
};

export const AttachUserGroupContent = ({
  resourceData,
  userGroups,
  attachError,
  duplicateError,
  userGroupAttachments,
  isGithubEMU,
  isLoadingUserGroup,
  setDuplicateError,
  setUserGroupAttachments,
  projectId,
  setAttachError,
}: AttachUserGroupContentProps) => {
  const classes = useStyles();
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const [isWarningMessageFlagEnabled, setIsWarningMessageFlagEnabled] =
    useState(false);
  const [
    isGetUserMemberListNewFlagEnabled,
    setIsGetUserMemberListNewFlagEnabled,
  ] = useState(false);
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);

  useEffectOnce(() => {
    (async () => {
      const warningMessageFlagRes = await featureFlagsApi.getBinaryFlag(
        ADD_USER_WARNING_MESSAGE,
      );
      setIsWarningMessageFlagEnabled(warningMessageFlagRes.data);
    })();

    (async () => {
      const getUserMemberListNewFlagRes = await featureFlagsApi.getBinaryFlag(
        NEW_API_GET_USER_MEMBER_LIST,
      );
      setIsGetUserMemberListNewFlagEnabled(getUserMemberListNewFlagRes.data);
    })();
  });

  const addResourceHandler = () => {
    setUserGroupAttachments([...userGroupAttachments, emptyAttachUserGroup]);
    setAttachError('');
  };

  return (
    <DialogContent>
      <ResourceSelectRow
        resourceData={resourceData}
        userGroups={userGroups}
        userGroupAttachments={userGroupAttachments}
        isLoadingUserGroup={isLoadingUserGroup}
        setTotalUsersCount={setTotalUsersCount}
        setDuplicateError={setDuplicateError}
        isGetUserMemberListNewFlagEnabled={isGetUserMemberListNewFlagEnabled}
        setUserGroupAttachments={setUserGroupAttachments}
        projectId={projectId}
        setAttachError={setAttachError}
        attachError={attachError}
      />
      {userGroups?.length === 0 && (
        <div className={classes.errorMsg}>
          There are no usergroups available to be assigned.
        </div>
      )}
      <Button
        fullWidth
        variant="outlined"
        className={classes.hyperButton}
        color="default"
        onClick={addResourceHandler}
        disabled={isLoadingUserGroup}
      >
        Add user group +
      </Button>
      {(attachError || duplicateError) && (
        <Typography
          style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
        >
          Error: {attachError || duplicateError}
        </Typography>
      )}
      {isWarningMessageFlagEnabled && isGithubEMU && (
        <WarningMessage totalUsersCount={totalUsersCount} />
      )}
    </DialogContent>
  );
};
