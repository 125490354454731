import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'right',
    paddingBottom: '20px',
    marginRight: '16px',
  },
  iconContainer: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '1px',
    paddingRight: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '26px',
  },
  tooltip: {
    color: theme.palette.textContrast,
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
  },
  tooltipArrow: {
    color: theme.palette.usgColors.secondary.silverGray,
  },
}));
