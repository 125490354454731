import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { NotFoundError } from '@backstage/errors';
import { Link, Progress, Table, TableColumn } from '@backstage/core-components';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { Button, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import {
  usePermissions,
  useServiceAccountApi,
} from '@internal/plugin-projects';
import {
  BinaryFeatureFlagged,
  EmptyDataMessage,
  PageLayout,
  PageTitle,
  Tabs,
} from '@internal/sg-ui-kit';

import { getTechDocsLink, transformResourceName } from 'sg-utils-frontend';
import {
  DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
  NEW_API_GET_USER_MEMBER_LIST,
  PORTAL_PAGES_TITLES,
} from 'usg-types';

import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { projectApiRef } from '../../api';
import { DetachUserGroup } from '../../components/DetachUserGroup/DetachUserGroup';
import { ResultData } from '../../components/ViewUserGroups3/Members/types';
import WaitingForUserExport from '../../components/ViewUserGroups3/Members/WaitingForUserExport';
import { useExportUsers } from '../../hooks/useExportUsers';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { rootRouteRef } from '../../routes';
import ResourcesTab, { AttachedResourcesByDevTool } from './ResourcesTab';

import { ROOT_URL_LABEL_BREADCRUMBS } from '../../components/ViewEditResources/ViewEditResourcePage/types';
import useBreadcrumbsFlag from '../../hooks/useBreadcrumbsFlag';
import { useStyles } from './styles';

export interface AttachedUserGroupDetailComponentProps {
  projectId: string;
  groupId: string;
  isAdmin: boolean;
  isOwner: boolean;
  userLoading: boolean;
  totalUsersCount: number;
}

export const AttachedUserGroupDetailPageComponent = ({
  projectId,
  groupId,
  isAdmin,
  isOwner,
  userLoading,
  totalUsersCount,
}: AttachedUserGroupDetailComponentProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const rootLink = useRouteRef(rootRouteRef);
  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  const [attachedProjectResources, setAttachedProjectResources] =
    useState<AttachedResourcesByDevTool>({});
  const [attachableProjectResources, setAttachableProjectResources] =
    useState<AttachedResourcesByDevTool>({});
  const [projectData, setProjectData] = useState<any>();
  const [currentUserGroup, setCurrentUserGroup] = useState<any>([]);
  const [sourceProject, setSourceProject] = useState<any>();
  const [showDetachuserGroup, setShowDetachuserGroup] =
    useState<boolean>(false);
  const [isGroupArchived, setIsGroupArchived] = useState(false);
  const [sortingState] = useState({
    order: false,
    orderBy: 'username',
  } as any);
  const [tableState, setTableState] = useState({
    page: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
  });
  const [norenUUID, setNorenUUID] = useState('');
  const [devTools, setDevTools] = useState([] as any);
  const [resourceData, setResourceData] = useState([] as any);
  const [members, setMembers] = useState<ResultData>({
    data: [],
    page: 0,
    totalCount: 0,
  });
  const [
    isGetUserMemberListNewFlagEnabled,
    setIsGetUserMemberListNewFlagEnabled,
  ] = useState(false);
  const { displayExportProgressBar, handleExportUser } = useExportUsers();
  const { isBreadCrumbsFlagEnabled, loadingBreadcrumbsFlag } =
    useBreadcrumbsFlag();

  const {
    accountToken,
    isLoading: isLoadingAuthToken,
    getServiceToken,
  } = useServiceAccountApi();

  const feedColumns: Array<TableColumn<any>> = [
    {
      field: 'email',
      title: 'Email',
      width: '40%',
      render: ({ email }) => (
        <Link
          to={`/projects/${projectId}/users/${email}`}
          className={classes.hyperLink}
        >
          {email}
        </Link>
      ),
    },
    {
      field: isGetUserMemberListNewFlagEnabled ? 'display_name' : 'displayName',
      title: 'Name',
      width: '40%',
      sorting: false,
    },
  ];

  const toAttachedResourcesByDevTool = (
    group_resources: any,
    project_resource_roles: any,
  ) => {
    const groupAttachedResources: AttachedResourcesByDevTool = {};
    group_resources.forEach((resource: any) => {
      const projectResource = project_resource_roles.find(
        (pr: any) => pr.id === resource.id,
      );

      if (!!projectResource) {
        groupAttachedResources[projectResource.dev_tool_id] =
          groupAttachedResources[projectResource.dev_tool_id] || {};
        groupAttachedResources[projectResource.dev_tool_id].dev_tool_id =
          projectResource.dev_tool_id;
        groupAttachedResources[projectResource.dev_tool_id].dev_tool_name =
          transformResourceName(projectResource.development_tool_name);
        groupAttachedResources[projectResource.dev_tool_id].attachedResources =
          groupAttachedResources[projectResource.dev_tool_id]
            .attachedResources || [];
        groupAttachedResources[
          projectResource.dev_tool_id
        ].attachedResources.push({
          dev_tool_id: projectResource?.dev_tool_id,
          dev_tool_name: transformResourceName(
            projectResource?.development_tool_name,
          ),
          id: projectResource.id,
          key: projectResource.key,
          name: projectResource.name,
          url: projectResource?.url,
          environment: projectResource?.environment,
          role: resource?.role,
          config: resource?.config,
          generated_resource_key: projectResource?.generated_resource_key,
        });
      }
    });

    setAttachedProjectResources(groupAttachedResources);
  };

  const toProjectAttachableResources = (
    group_resources: any,
    project_resource_roles: any,
  ) => {
    const projectAttachableResources: AttachedResourcesByDevTool = {};

    project_resource_roles.forEach((resource: any) => {
      if (!resource?.deleted_on) {
        const projectResource = group_resources.find(
          (pr: any) => pr.id === resource.id,
        );
        if (!projectResource) {
          projectAttachableResources[resource.dev_tool_id] =
            projectAttachableResources[resource.dev_tool_id] || {};
          projectAttachableResources[resource.dev_tool_id].dev_tool_id =
            resource.dev_tool_id;
          projectAttachableResources[resource.dev_tool_id].dev_tool_name =
            transformResourceName(resource.development_tool_name);
          projectAttachableResources[resource.dev_tool_id].attachedResources =
            projectAttachableResources[resource.dev_tool_id]
              .attachedResources || [];
          projectAttachableResources[
            resource.dev_tool_id
          ].attachedResources.push({
            dev_tool_id: resource?.dev_tool_id,
            dev_tool_name: transformResourceName(
              projectResource?.development_tool_name,
            ),
            id: resource.id,
            key: resource.key,
            name: resource.name,
            url: resource?.url,
            environment: resource?.environment,
            role: resource?.role,
            config: resource?.config,
            generated_resource_key: resource?.generated_resource_key,
          });
        }
      }
    });

    setAttachableProjectResources(projectAttachableResources);
  };

  const fetchUsersFromApi = (query: any): Promise<any> => {
    return new Promise(async (resolve, _reject) => {
      if (!query || !norenUUID) {
        return;
      }
      const page = query?.page + 1;
      const pageSize = query?.pageSize;
      const startIndex = query?.page === 0 ? 0 : (page - 1) * query?.pageSize;
      setTableState({ ...tableState, pageSize, page });
      try {
        let getUserMemberList;

        if (isGetUserMemberListNewFlagEnabled) {
          const idToken = await authRef.getIdToken();
          getUserMemberList = await projectApi.getUserMemberListNew(
            projectId,
            currentUserGroup?.name,
            idToken,
            pageSize,
            startIndex,
            sortingState.orderBy,
            sortingState.order,
          );
        } else {
          getUserMemberList = await projectApi.getUserMemberList(
            norenUUID,
            pageSize,
            startIndex,
            sortingState.orderBy,
            sortingState.order,
          );
        }
        if (getUserMemberList && getUserMemberList.error) {
          throw new NotFoundError(getUserMemberList.error.message);
        }

        if (getUserMemberList) {
          resolve({
            data: getUserMemberList.members,
            page: query?.page,
            totalCount: getUserMemberList.total_results,
          });
        } else {
          errorApi.post(new Error(`Failed to retrieve the user data`));
          resolve({
            data: [],
            page: query?.page,
            totalCount: 0,
          });
        }
      } catch (err: any) {
        errorApi.post(
          new Error(err?.message || 'Failed to retrieve the user data'),
        );
        resolve({
          data: [],
          page: query?.page,
          totalCount: 0,
        });
      }
    });
  };

  const getUserListData = async (query: any): Promise<ResultData> => {
    const result = await fetchUsersFromApi(query);
    setMembers(result);
    return result;
  };

  const [{ loading: dtLoading = false }, fetchDevTools] =
    useAsyncFn(async () => {
      try {
        const idToken = await authRef.getIdToken();
        const responseDevTool: any = await projectApi.getDevelopmentToolsData(
          idToken,
        );

        if (responseDevTool) {
          setDevTools(responseDevTool?.development_tools);
        }
      } catch (errorMsg) {
        errorApi.post(new Error(`${errorMsg?.message}`));
        navigate(`/projects/${projectId}`);
      }
    }, []);

  const fetchAndProcessProject = async (idToken: string) => {
    let resProjectData: any;
    let userGroup: any;
    try {
      resProjectData = await projectApi.getProjectByID(projectId, idToken, {
        manipulators: ['user_groups', 'resources'],
      });
      userGroup = resProjectData?.user_groups?.find(
        (ug: any) =>
          ug?.id.toString() === groupId.toString() && !ug?.deleted_on,
      );

      if (!userGroup) {
        navigate(`/projects/${projectId}`);
      }

      const srcProjectId = userGroup?.sharing_properties?.source_project_id;
      try {
        const tags = await projectApi.getUserGroupTags(
          srcProjectId,
          groupId,
          accountToken.token,
          'platform-limit-tool',
        );
        if (tags?.error) {
          if (tags?.error?.code === 401) {
            await getServiceToken();
          } else {
            errorApi.post(new Error(`Gettings tags: ${tags?.error?.message}`));
          }
        }
        if (tags?.data?.tags && Object.keys(tags?.data?.tags).length > 0) {
          userGroup = { ...userGroup, tags: tags?.data?.tags };
        }
      } catch (err) {
        errorApi.post(new Error(`Gettings tags: ${err?.message}`));
      }

      setCurrentUserGroup(userGroup);
      toProjectAttachableResources(
        userGroup?.accessible_resources_roles,
        resProjectData?.resources,
      );
      toAttachedResourcesByDevTool(
        userGroup?.accessible_resources_roles,
        resProjectData?.resources,
      );
    } catch (err: any) {
      errorApi.post(new Error(`${err?.message}`));
      navigate(`/projects/${projectId}`);
    }
    return { userGroup, resProjectData };
  };

  // Fetch Project
  const [{ loading: pLoading = false }, fetchProject] = useAsyncFn(async () => {
    const idToken = await authRef.getIdToken();
    const { userGroup, resProjectData } = await fetchAndProcessProject(idToken);

    setProjectData(resProjectData);
    setResourceData(resProjectData?.resources);

    if (!userGroup) return;

    const groupParams = { idToken };
    const groupRes: any = await projectApi.getGroupByIDFromProject(
      groupId.toString(),
      projectId,
      groupParams,
    );
    const groupNorenData = groupRes?.data;

    if (groupNorenData?.deleted_on) {
      setIsGroupArchived(true);
    }
    if (groupNorenData?.noren_uuid) {
      setNorenUUID(groupNorenData?.noren_uuid);
    }
    if (userGroup.noren_uuid) {
      setNorenUUID(userGroup.noren_uuid);
    }

    if (userGroup.sharing_properties?.source_project_id !== projectId) {
      try {
        const res: any = await projectApi.getProjectByID(
          userGroup.sharing_properties?.source_project_id,
          idToken,
          {
            manipulators: ['user_groups', 'resources'],
          },
        );
        setSourceProject(res);
      } catch (err: any) {
        if (err?.message !== 'Access not allowed.') {
          errorApi.post(
            new Error(`Failed retrieving source project. ${err?.message}`),
          );
        }
        return;
      }
    }
  }, [accountToken.token]);

  // Fetch User Group Resources
  const [{ loading: ugResourcesLoading = false }, fetchUserGroupResources] =
    useAsyncFn(async () => {
      const idToken = await authRef.getIdToken();
      await fetchAndProcessProject(idToken);
    }, []);

  useEffect(
    () => {
      const fetchInitialData = async () => {
        fetchDevTools();
        if (!isLoadingAuthToken) fetchProject();
      };
      fetchInitialData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountToken, isLoadingAuthToken],
  );

  useEffectOnce(() => {
    (async () => {
      const getUserMemberListNewFlagRes = await featureFlagsApi.getBinaryFlag(
        NEW_API_GET_USER_MEMBER_LIST,
      );
      setIsGetUserMemberListNewFlagEnabled(getUserMemberListNewFlagRes.data);
    })();
  });

  // Back to Projects list page
  const backToTarget = projectId
    ? `/projects/${projectId}?tab=user-groups`
    : rootLink();

  const backToLink = useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backToTarget],
  );

  const getExportUserTooltip = (): string => {
    if (norenUUID === '') {
      return 'User group being created / has been archived, you cannot export users. Check back later.';
    }

    return 'Export Users';
  };

  const renderUsersTable = () => {
    return (
      <div>
        {pLoading ? <Progress /> : ''}
        <Table
          columns={feedColumns}
          options={{
            paging: true,
            search: false,
            sorting: false,
            actionsColumnIndex: -1,
            padding: 'dense',
            pageSize: tableState.pageSize,
            pageSizeOptions: DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
            emptyRowsWhenPaging: false,
            actionsCellStyle: { padding: '5px 10px' },
          }}
          data={norenUUID.length === 0 ? [] : query => getUserListData(query)}
          title="All Users"
          localization={{
            body: {
              emptyDataSourceMessage: (
                <EmptyDataMessage
                  message={
                    norenUUID.length > 0
                      ? 'This user group is empty.'
                      : 'User Group is being attached...'
                  }
                />
              ),
            },
          }}
          actions={[
            {
              icon: () => (
                <FileDownloadOutlinedIcon
                  id="btn-exportuser-from-group"
                  color={members.totalCount > 0 ? 'primary' : 'inherit'}
                />
              ),
              tooltip: getExportUserTooltip(),
              isFreeAction: true,
              onClick: () =>
                handleExportUser(
                  members,
                  currentUserGroup?.name,
                  getUserListData,
                  isGetUserMemberListNewFlagEnabled,
                ),
              disabled: norenUUID === '' || members.totalCount === 0,
            },
          ]}
        />
        <WaitingForUserExport
          displayExportProgressBar={displayExportProgressBar}
        />
      </div>
    );
  };

  const MainSection = () => {
    return (
      <>
        <Typography
          variant="h4"
          display="inline"
          className={classes.resourceText}
        >
          {currentUserGroup?.name?.toUpperCase()}
        </Typography>
        <Typography
          variant="h4"
          display="inline"
          style={{ marginLeft: 10, fontWeight: 'normal' }}
          className={classes.resourceText}
        >
          (Attached group)
        </Typography>
        <div style={{ paddingTop: '12px' }}>
          {sourceProject?.name ? (
            <Link
              className={classes.resourceTextLink}
              to={`/projects/${
                currentUserGroup?.sharing_properties?.source_project_id ||
                projectId
              }`}
            >
              {sourceProject?.name}
            </Link>
          ) : (
            <div className={classes.unauthorizedProjectDiv}>
              <i>
                Unauthorized Project (
                {currentUserGroup?.sharing_properties?.source_project_id ||
                  projectId}
                )
              </i>
              <Tooltip
                interactive
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.tooltipArrow,
                }}
                title={
                  <div style={{ textAlign: 'center' }}>
                    You do not have permission to view this project,{' '}
                    <Link
                      className={classes.hyperlink}
                      target="_blank"
                      to={getTechDocsLink('project-access')}
                    >
                      learn more here
                    </Link>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoIcon color="primary" className={classes.projectInfoIcon} />
              </Tooltip>
            </div>
          )}
        </div>
        <p style={{ marginTop: 15 }}>{currentUserGroup?.description}</p>
        <p style={{ marginTop: 15 }}>
          This group is an external group, you are only allowed to manage the
          resource access to this project on this screen.
        </p>

        {(isAdmin || isOwner) && (
          <Button
            variant="outlined"
            style={{ float: 'right', marginTop: '10px' }}
            id="detach-user-group-button"
            onClick={() => {
              setShowDetachuserGroup(true);
            }}
            disabled={ugResourcesLoading}
          >
            DETACH USER GROUP
          </Button>
        )}
      </>
    );
  };

  const tabs = [
    {
      label: 'ASSIGNED RESOURCES',
      value: 'assigned_resources',
      content: (
        <ResourcesTab
          fetchUserGroupResources={fetchUserGroupResources}
          attachedResourcesByDevTool={attachedProjectResources}
          groupData={currentUserGroup}
          projectId={projectId}
          groupId={groupId}
          attachableProjectResources={attachableProjectResources}
          devTools={devTools}
          loadingFetchUserGroupResources={ugResourcesLoading}
          isAdmin={isAdmin}
          isOwner={isOwner}
          resourceData={resourceData}
          isGroupArchived={isGroupArchived}
          totalUsersCount={totalUsersCount}
        />
      ),
    },
    {
      label: 'MEMBERS',
      value: 'members',
      content: <div>{renderUsersTable()}</div>,
    },
  ];

  if (
    dtLoading ||
    pLoading ||
    userLoading ||
    isLoadingAuthToken ||
    loadingBreadcrumbsFlag
  ) {
    return <Progress />;
  }

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${projectData?.id}?tab=user-groups`,
      display: projectData?.name,
    },
    {
      path: `usergroup/${groupId}`,
      display: currentUserGroup?.name,
    },
  ];

  return (
    <>
      <PageLayout
        type="entity"
        title={projectData?.name || ''}
        headerAdditionalControls={
          <ProjectOwners owners={projectData?.owners || null} />
        }
        backToLink={isBreadCrumbsFlagEnabled ? breadcrumbs : backToLink}
        children={undefined}
      />
      <BinaryFeatureFlagged withFlag={PORTAL_PAGES_TITLES}>
        <PageTitle
          customPageTitle={`${currentUserGroup?.name} User Group | ${projectData?.name} | Stargate`}
        />
      </BinaryFeatureFlagged>
      <div className={classes.container} style={{ marginTop: '18px' }}>
        <MainSection />
        <Tabs tabs={tabs} />
      </div>

      <DetachUserGroup
        navigate={navigate}
        isVisible={showDetachuserGroup}
        handleClose={() => setShowDetachuserGroup(false)}
        projectId={projectId}
        groupId={groupId}
        groupName={currentUserGroup?.name}
      />
    </>
  );
};

export const AttachedUserGroupDetailPage = ({
  totalUsersCount,
}: {
  totalUsersCount?: number;
}) => {
  const { projectId, groupId } = useParams() as {
    projectId: string;
    groupId: string;
  };
  const navigate = useNavigate();
  const errorApi = useApi(errorApiRef);
  const {
    isAdmin,
    isProjectOwner,
    isProjectMember,
    isLoading: isLoadingPermissions,
    error: permissionsError,
  } = usePermissions();

  const isOwner = isProjectOwner(parseInt(projectId, 10));
  if (!isAdmin && !isProjectMember(parseInt(projectId, 10)) && !isOwner) {
    errorApi.post(new Error(`User is not a member of the project.`));
    navigate('/projects');
  }

  if (permissionsError) {
    errorApi.post(new Error(`${permissionsError?.message}`));
    navigate('/projects');
  }

  if (isLoadingPermissions) {
    return <Progress />;
  }

  return (
    <AttachedUserGroupDetailPageComponent
      projectId={projectId}
      groupId={groupId}
      isAdmin={isAdmin}
      isOwner={isOwner}
      userLoading={isLoadingPermissions}
      totalUsersCount={totalUsersCount || 0}
    />
  );
};
