import React from 'react';
import {
  Paper,
  Grid,
  InputLabel,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
} from 'usg-types';
import { ContactUsLink } from '@internal/sg-ui-kit';
import {
  transformResourceName,
  getLocalStorageWithExpiry,
  getTechDocsLink,
} from 'sg-utils-frontend';
import { Link } from '@backstage/core-components';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

interface Props {
  onSubmitCreateArtifactorySaaS: (values: any) => Promise<void>;
  isArtifactoryNameDirty: boolean;
  onArtifactoryNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  isAdmin: boolean;
  handleCreateResource: () => void;
}

export const packageType = [
  'alpine',
  'cargo',
  'composer',
  'bower',
  'chef',
  'cocoapods',
  'conan',
  'cran',
  'debian',
  'docker',
  'helm',
  'gems',
  'gitlfs',
  'go',
  'gradle',
  'ivy',
  'maven',
  'npm',
  'nuget',
  'opkg',
  'pub',
  'puppet',
  'pypi',
  'rpm',
  'sbt',
  'swift',
  'terraform',
  'vagrant',
  'yum',
  'generic',
];

export const CreateArtifactorySaaSResourceForm = ({
  onSubmitCreateArtifactorySaaS,
  isArtifactoryNameDirty,
  onArtifactoryNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  isAdmin,
  handleCreateResource,
}: Props) => {
  const language = getLocalStorageWithExpiry('locale');
  const classes = useStyles();
  const sites = ['jp', 'us'];
  const resourceName = transformResourceName('artifactorysaas');
  const { t } = useTranslation();

  const memoisedRClass = React.useMemo(() => {
    return isAdmin
      ? ['local', 'remote', 'virtual', 'federated']
      : ['local', 'federated'];
  }, [isAdmin]);

  const handleRadioButtonChange = (event: any, formik: any) => {
    formik.setFieldValue('sites', [event.target.value]);
  };

  const handleCheckBoxChange = (event: any, formik: any) => {
    if (event.target.checked) {
      formik.setFieldValue('sites', [
        ...formik.values.sites,
        event.target.value,
      ]);
    } else {
      formik.setFieldValue(
        'sites',
        formik.values.sites.filter((v: any) => v !== event.target.value),
      );
    }
  };

  const RClassHelpFieldSection = () => {
    return (
      <>
        <Typography className={classes.artifactoryOwnerAlertText}>
          {`Virtual and Remote repositories can not be created
            automatically.`}
          <br />
          {`Please `}
          <ContactUsLink
            text="contact us to raise a ticket"
            language={language}
            target="_blank"
          />
          {` to our Service Desk for these repositories instead.`}
        </Typography>
        <br />
      </>
    );
  };

  const RClassField = ({ formik }: { formik: any }) => {
    return (
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor="r_class">R Class</InputLabel>
        <Select
          id="r_class"
          name="r_class"
          data-testid="r_class_select"
          value={formik.values.r_class}
          input={<OutlinedInput label="Tag" />}
          onChange={e => {
            e.preventDefault();
            formik.handleChange(e);
          }}
          style={{ textTransform: 'capitalize' }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {memoisedRClass.map((value: any) => {
            return (
              <MenuItem
                value={value}
                style={{ textTransform: 'capitalize' }}
                key={value}
              >
                {value}
              </MenuItem>
            );
          })}
        </Select>
        {formik.values.r_class === 'local' && (
          <Typography className={classes.artifactoryOwnerAlertText}>
            {t('resource.create.artifactorysaas.rClassLocalNote')}{' '}
            <Link
              className={classes.hyperLink}
              target="_blank"
              to={getTechDocsLink('artifactory-saas-standard')}
            >
              {t('common.link.learnMore')}.
            </Link>
          </Typography>
        )}
        {formik.values.r_class === 'federated' && (
          <>
            <Typography className={classes.artifactoryOwnerAlertText}>
              {t('resource.create.artifactorysaas.rClassFederateNote')}{' '}
              <Link
                className={classes.hyperLink}
                target="_blank"
                to={getTechDocsLink('artifactory-saas-standard')}
              >
                {t('common.link.learnMore')}.
              </Link>
            </Typography>
          </>
        )}
      </FormControl>
    );
  };

  const displaySitesForAdmin = (formik: any) => {
    return (
      isAdmin &&
      (formik.values.r_class === 'remote' ||
        formik.values.r_class === 'virtual')
    );
  };

  const placeHolderText = (formik: any) => {
    switch (formik?.values?.r_class) {
      case 'local':
        return 'Example : <region>-<artifactory-project-name>-<tech>-<env>-local';
      case 'federated':
        return 'Example : <region>-<artifactory-project-name>-<tech>-<env>-local';
      case 'remote':
        return 'Example : <region>-<artifactory-project-name>-<tech>-remote';
      case 'virtual':
        return 'Example : <region>-<artifactory-project-name>-<tech>-<env>';
      default:
        return '';
    }
  };

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          artifactory_saas_repo_name: '',
          artifactory_saas_description: '',
          package_type: '',
          r_class: 'local',
          sites: [sites[0]],
        }}
        onSubmit={onSubmitCreateArtifactorySaaS}
      >
        {formik => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  {resourceName} Repo
                </Typography>
                <p>
                  {t('resource.create.artifactorysaas.note')}{' '}
                  <Link
                    className={classes.hyperLink}
                    to={getTechDocsLink('vpn')}
                    target="_blank"
                  >
                    {t('common.link.learnMore')}
                  </Link>
                </p>
                <br />
                <InputLabel
                  style={{ marginBottom: '8px' }}
                  className={classes.inputLabelText}
                >
                  {t('resource.view.artifactorysaas.configLabel')}*
                </InputLabel>
                {isAdmin && <RClassHelpFieldSection />}
                <div style={{ paddingLeft: '24px' }}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="package_type">
                      {t('resource.view.artifactorysaas.packageTypeLabel')}*
                    </InputLabel>
                    <Select
                      id="package_type"
                      name="package_type"
                      data-testid="package_type_select"
                      value={formik.values.package_type}
                      input={<OutlinedInput label="Tag" />}
                      onChange={formik.handleChange}
                      displayEmpty
                      style={{ textTransform: 'capitalize' }}
                    >
                      <MenuItem value="" disabled>
                        {t('common.form.selectLabel')}
                      </MenuItem>
                      {packageType.map(value => {
                        return (
                          <MenuItem
                            value={value}
                            style={{ textTransform: 'capitalize' }}
                            key={value}
                          >
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <RClassField formik={formik} />
                  {/* Sites */}
                  {formik.values.r_class === 'local' && (
                    <>
                      <FormControl>
                        <InputLabel htmlFor="sites">
                          {t('resource.view.artifactorysaas.sites')}
                        </InputLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={formik?.values?.sites[0]}
                          onChange={event => {
                            handleRadioButtonChange(event, formik);
                          }}
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          {sites.map(element => {
                            return (
                              <FormControlLabel
                                value={element}
                                control={
                                  <Radio
                                    id={`projects-createresource-artifactorysaas-site-${element}`}
                                  />
                                }
                                label={element.toUpperCase()}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                      <br />
                    </>
                  )}
                  {displaySitesForAdmin(formik) && (
                    <FormControl component="fieldset">
                      <InputLabel htmlFor="sites">
                        {t('resource.view.artifactorysaas.sites')}
                      </InputLabel>
                      <FormGroup
                        aria-label="position"
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        {sites.map(element => {
                          return (
                            <FormControlLabel
                              value={element}
                              onChange={event => {
                                handleCheckBoxChange(event, formik);
                              }}
                              control={
                                <Checkbox
                                  id={`projects-createresource-artifactorysaas-site-${element}`}
                                  checked={formik.values.sites.includes(
                                    element,
                                  )}
                                />
                              }
                              label={element.toUpperCase()}
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  )}
                </div>
                <br />
                <InputLabel
                  htmlFor="artifactory_saas_repo_name"
                  className={classes.inputLabelText}
                >
                  {t('resource.view.artifactorysaas.repoNameLabel')}*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="artifactory_saas_repo_name"
                  name="artifactory_saas_repo_name"
                  data-testid="artifactory_saas_repo_name"
                  required
                  error={
                    (formik?.values?.artifactory_saas_repo_name?.length <
                      RESOURCE_NAME_MIN_LENGTH.artifactory &&
                      isArtifactoryNameDirty) ||
                    (formik?.values?.artifactory_saas_repo_name?.length >
                      RESOURCE_NAME_MAX_LENGTH.artifactory &&
                      isArtifactoryNameDirty) ||
                    (formik?.values?.artifactory_saas_repo_name?.length > 0 &&
                      !formik.values.artifactory_saas_repo_name.match(
                        SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                      ))
                  }
                  onChange={formik.handleChange}
                  value={formik.values.artifactory_saas_repo_name}
                  onClick={onArtifactoryNameClick}
                  fullWidth
                  placeholder={placeHolderText(formik)}
                  helperText={t(
                    'resource.view.artifactorysaas.repoNameHelperText',
                    {
                      /* @ts-ignore */
                      min: RESOURCE_NAME_MIN_LENGTH.artifactory,
                      max: RESOURCE_NAME_MAX_LENGTH.artifactory,
                    },
                  )}
                />
                <br />
                <br />
                <InputLabel
                  htmlFor="artifactory_saas_description"
                  className={classes.inputLabelText}
                >
                  {t('resource.view.artifactorysaas.descriptionLabel')}
                </InputLabel>
                <TextField
                  type="text"
                  id="artifactory_saas_description"
                  name="artifactory_saas_description"
                  onChange={formik.handleChange}
                  value={formik.values.artifactory_saas_description}
                  minRows={4}
                  multiline
                  fullWidth
                  error={
                    formik.values.artifactory_saas_description.length >
                      RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
                  }
                  helperText={t(
                    'resource.view.artifactorysaas.descriptionHelperText',
                    {
                      /* @ts-ignore */
                      max: RESOURCE_DESCRIPTION_MAX_LENGTH,
                    },
                  )}
                  placeholder={t(
                    'resource.view.artifactorysaas.descriptionPlaceholder',
                  )}
                  onClick={onDescriptionClick}
                />
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-artifactory-saas"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      {t('common.form.cancelLabel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-artifactory-saas"
                      data-testid="submit-artifactory-saas"
                      disabled={
                        formik.values.artifactory_saas_repo_name.length <
                          RESOURCE_NAME_MIN_LENGTH.artifactory ||
                        formik.values.artifactory_saas_repo_name.length >
                          RESOURCE_NAME_MAX_LENGTH.artifactory ||
                        (formik.values.artifactory_saas_repo_name.length > 0 &&
                          !formik.values.artifactory_saas_repo_name.match(
                            SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                          )) ||
                        apiLoader ||
                        formik.values.package_type === '' ||
                        formik.values.r_class === '' ||
                        formik.values.artifactory_saas_description.length >
                          RESOURCE_DESCRIPTION_MAX_LENGTH ||
                        formik.values.sites.length === 0
                      }
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      {t('common.form.submitLabel')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
